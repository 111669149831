<template>
  <vs-popup
    class="holamundo"
    title="Edit Plan Of Action"
    :active.sync="open"
    style="z-index:53002"
  >
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">Target 1:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <v-select
          searchable
          clearable
          label="text"
          :options="options"
          v-model="target1"
          style="width:300px; z-index:35000; position:fixed"
        />
        <!-- <vs-input class="inputx" placeholder="AUD" v-model="AUD" /> -->
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">Target 2:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="FAR" v-model="FAR" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="options"
          v-model="target2"
          style="width:300px; z-index:30000; position:fixed"
        />
      </vs-col>
    </vs-row>

    <vs-row vs-w="12" style="margin-top: 2%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        style="width: 70%;"
      >
        <vs-button color="dark" type="gradient" @click="addDetails()"
          >UPDATE</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import axios from "axios";
// import moment from "moment";
import constants from "../../../constants.json";
export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      target1: "",
      target2: "",
      id: "",
      open: false,
      data: [],
      options: [
        "NA",
        "Drop-Out",
        "Cleared",
        "Undecided",
        "Exam Given - Result Awaited",
        "Re-Attempt",
        "IMA Registration Done",
        "W1-2022",
        "W2-2022",
        "W3-2022",
        "W1-2023",
        "W2-2023",
        "W3-2023",
        "W1-2024",
        "W2-2024",
        "W3-2024",
        "W1-2025",
        "W2-2025",
        "W3-2025"
      ]
    };
  },
  mounted() {
    EventBus.$on("open-edit-cma-POA", payload => {
      console.log("pao", payload);
      this.open = true;
      this.target1 = payload.cma.part_1_exam_target;
      this.target2 = payload.cma.part_2_exam_target;
      this.id = payload.cma.id;
    });
  },
  methods: {
    addDetails() {
      this.open = false;
      let url = `${constants.SERVER_API}addCMAPlanOfAction`;
      let obj = {
        cma_id: this.id,
        target1: this.target1,
        target2: this.target2
      };
      // console.log("edit poa", obj);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("edit", response.data.cma);
          // this.$store.commit("UPDATE_CPA_DATA", [response.data.cpa]);
          this.$emit("poa-updated", response.data.cma);
          //   EventBus.$emit("reload-bigpopup-data");
          if (this.$route.path === "/queue") {
            // EventBus.$emit("update-queue-addition");
            EventBus.$emit("update-activelead-data");
          } else {
            EventBus.$emit("reload-bigpopup-data");
          }
          this.$vs.notify({
            title: "POA Updated",
            text: "POA is successfully Updated",
            color: "success"
          });
          // if (response.data.status === `success`) {
          //   let poa = {
          //     aud: response.data.cpa.aud,
          //     far: response.data.cpa.far,
          //     bec: response.data.cpa.bec,
          //     reg: response.data.cpa.reg,
          //     evaluation: response.data.cpa.evaluation,
          //     nts: response.data.cpa.nts
          //   };
          //   this.$store.commit("UPDATE_CPA_DATA", [
          //     this.AUD,
          //     this.FAR,
          //     this.BEC,
          //     this.REG,
          //     this.evaluation,
          //     this.nts,
          //     poa
          //   ]);
          //   this.open = false;
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: response.data.message,
          //     color: "danger",
          //     time: 10000
          //   });
          // }
          // if (response.data.cpa.id === this.id) {
          //   this.$store.commit("UPDATE_CPA_DATA", [response.data.cpa]);
          //   // this.companyeditfunc();
          //   this.$vs.notify({
          //     title: "Success",
          //     text: "POA details successfully updated",
          //     color: "success"
          //   });
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "An Error occured while editing the POA of this lead",
          //     color: "danger"
          //   });
          // }
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style></style>
