<template>
  <div
    style="width: 100%"
    class="chat__contact flex items-center px-2 py-3"
    :class="{ 'bg-primary-gradient text-white shadow-lg': activeIndication }"
  >
    <div class="contact__avatar mr-3"></div>
    <div
      class="contact__container w-full flex items-center justify-between overflow-hidden"
    >
      <div class="contact__info flex flex-col truncate w-5/6">
        <p :class="{ 'text-white': activeIndication }">
          {{ CardInfo.person_name }}
        </p>
        <!-- <h6 class="font-semibold" :class="{ 'text-white': activeIndication }">
          {{ CardInfo.person_name }}
        </h6>
        <h6 class="truncate" :class="{ 'text-white': activeIndication }">
          Ting Tong Complex
        </h6> -->
      </div>

      <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
        <vs-chip :color="activeIndication ? '#ffffff' : 'success'">
          <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.level
          }}</b>
        </vs-chip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndication: {
      type: Boolean,
      required: true
    },
    CardInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // console.log(this.CardInfo);
  }
};
</script>
