<template>
  <div class="chat__header">
    <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">
      <h6>{{ activeUser.person_name }}</h6>
      <vs-spacer></vs-spacer>
      <vs-button
        v-if="callPermission === 'Pause'"
        @click="changeCallPermission('Call')"
        color="dark"
        type="filled"
        style="margin-left: 1%"
        >Start</vs-button
      >
      <vs-button
        v-else
        @click="changeCallPermission('Pause')"
        color="warning"
        type="filled"
        style="margin-left: 1%"
        >Pause</vs-button
      >
      <!-- <feather-icon icon="StarIcon" class="cursor-pointer"></feather-icon> -->
    </vs-navbar>
  </div>
</template>

<script>
export default {
  props: {
    activeUser: {
      type: Object,
      required: true
    },
    callPermission: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    changeCallPermission(event) {
      this.$emit("changeCallPermission", event);
    }
  }
};
</script>
