<template>
  <div id="component-chat-log">
    <div
      class="flex items-center app-search-container border border-l-0 border-r-0 border-t-0 border-solid border-grey-light"
    >
      <vs-navbar class="p-4 flex navbar-custom" color="white" type="flat">
        <b>{{ title }}</b>
        <vs-spacer></vs-spacer>
        <vs-button
          v-if="activeUser.given_type === 'untracked_calls'"
          @click="rescheduleCall"
          color="danger"
          type="border"
          style="margin-left: 1%;margin-right: 2%;"
          >Reschedule</vs-button
        >
        <vs-button
          v-if="callPermission === 'Pause'"
          @click="changeCallPermission('Call')"
          color="dark"
          type="filled"
          style="margin-left: 1%;;margin-right: 2%;"
          >Start</vs-button
        >
        <vs-button
          v-else
          @click="changeCallPermission('Pause')"
          color="warning"
          type="filled"
          style="margin-left: 1%;;margin-right: 2%;"
          >Pause</vs-button
        >
      </vs-navbar>
    </div>
    <!-- Missed Calls -->
    <div v-if="activeUser.given_type === 'missed_calls'" class="center">
      <div>
        <MissedCallCard :userdata="activeUser"></MissedCallCard>
      </div>
    </div>
    <!-- Ends Here -->

    <!-- Untracked Calls -->
    <div v-if="activeUser.given_type === 'untracked_calls'" class="center">
      <div>
        <UntrackedCard :userdata="activeUser"></UntrackedCard>
      </div>
    </div>
    <!-- Ends Here -->

    <!-- Net Enquiries -->
    <div v-if="activeUser.given_type === 'ne_visits' || activeUser.given_type === 'net_enquiry' || activeUser.given_type === 'ne_website_google' || activeUser.given_type === 'ne_website_google_without_superbot' || activeUser.given_type === 'ne_superbot' || activeUser.given_type === 'ne_other_sources'" class="center">
      <div>
        <NetEnquiries
          v-if="activeUser.net_enquiry_type !== 'chat'"
          :userdata="activeUser"
        />
        <NetEnquiriesChat
          v-if="activeUser.net_enquiry_type === 'chat'"
          :userdata="activeUser"
        />
      </div>
    </div>
    <!-- Ends Here -->

    <!-- This sections is matched candidates -->
    <vs-row
      style="margin-top: 1%;"
      v-if="
        activeUser.given_type === 'mhps_superbot' ||
        activeUser.given_type === 'mhps_without_superbot' ||
        activeUser.given_type === 'm3_plus_today' ||
        activeUser.given_type === 'm3_plus_delays' ||
        activeUser.given_type === 'm5_todays' ||
        activeUser.given_type === 'm5_delays' ||
        activeUser.given_type === 'm3_todays' ||
        activeUser.given_type === 'm3_delays' ||
        activeUser.given_type === 'u3_plus_plus_today' ||
        activeUser.given_type === 'm6_today' ||
        activeUser.given_type === 'm3_plus_plus_today' ||
        activeUser.given_type === 'u3_plus_plus_delays' ||
        activeUser.given_type === 'm6_delays' ||
        activeUser.given_type === 'm7_onboarding_today' ||
        activeUser.given_type === 'm7_onboarding_delays' ||
        activeUser.given_type === 'm7_hash_today' ||
        activeUser.given_type === 'm7_hash_delays' ||
        activeUser.given_type === 'm3_plus_plus_delays' ||
        activeUser.given_type === 'u6_today' ||
        activeUser.given_type === 'u6_delays' ||
        activeUser.given_type === 'u5_today' ||
        activeUser.given_type === 'u5_delays' ||
        activeUser.given_type === 'u3_plus_today' ||
        activeUser.given_type === 'u3_plus_delays' ||
        activeUser.given_type === 'u3_today' ||
        activeUser.given_type === 'u3_delays' ||
        activeUser.given_type === 'u2_today' ||
        activeUser.given_type === 'u2_delays' ||
        activeUser.given_type === 'blank_u' ||
        activeUser.given_type === 'mhps' ||
        activeUser.given_type === 'lead_visits' ||
          activeUser.given_type === 'today' ||
          activeUser.given_type === 'delays' ||
          activeUser.given_type === 'waiting' ||
          activeUser.given_type === 'untapped' ||
          activeUser.given_type === 'flag' ||
          activeUser.given_type === 'sr_queue_priority'
      "
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <vs-row>
          <card1 :studentDetails="activeUser" />
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <card3
          :payload="activeUser.mwb"
          :completeData="activeUser"
          :selected="sectionselected"
        />
      </vs-col>
    </vs-row>
    <!-- Ends Here -->
    <vs-popup
      class="holamundo"
      title="Reason for pausing:"
      :active.sync="popupActivo"
    >
      <vs-row>
        <vs-col>
          <vs-list>
            <vs-list-item
              v-for="(reason, index) in pasueReasons"
              :key="index"
              :title="reason"
            >
              <vs-radio v-model="pauseReason" :vs-value="reason"></vs-radio>
            </vs-list-item>
          </vs-list>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <div class="centerx">
            <vs-button
              ref="loadableButton"
              id="button-with-loading"
              class="vs-con-loading__container"
              @click="submitPauseReason"
              type="relief"
              vslor="primary"
              vs-align="center"
              >Submit Pause Reason</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import Card1 from "../pagesComponents/studentCardDetails1Queue.vue";
import Card3 from "../pagesComponents/latestEngagementsQueue.vue";
import axios from "axios";
import constants from "../../../constants.json";
import eventbus from "../eventbus";
import MissedCallCard from "../pagesComponents/DashboardMissed.vue";
import MissedCallUntracked from "../pagesComponents/DashboardMissedUntracked.vue";
import UntrackedCard from "../pagesComponents/DashboardUntracked.vue";
import NetEnquiries from "../pagesComponents/DashboardNetEnquiries.vue";
import NetEnquiriesChat from "../pagesComponents/DashboardNetEnquiriesChat.vue";
import moment from "moment";
// import Card1cma from "../pagesComponents/StudentReportCmaCard1.vue";
// import Card2cma from "../pagesComponents/StudentReportCmaCard2.vue";
// import Card1cpa from "../EWB/CPA/Candidates/Details_Popup/Card1.vue";
// import Card2cpa from "../EWB/CPA/Candidates/Details_Popup/Card2.vue";
export default {
  props: {
    activeUser: {
      type: Object,
      required: true,
    },
    callPermission: {
      type: String,
      required: true,
    },
  },
  components: {
    Card1,
    Card3,
    MissedCallCard,
    MissedCallUntracked,
    UntrackedCard,
    NetEnquiries,
    NetEnquiriesChat,
    // Card1cma,
    // Card2cma,
    // Card1cpa,
    // Card2cpa
  },
  data() {
    return {
      sectionselected: "All",
      popupActivo: false,
      pasueReasons: [
        "Visitors",
        "Bank Visit",
        "Enrollment",
        "Clearing MHPs",
        "Internal Training",
        "Internal Call",
        "Review",
        "Random Call",
        "Coffee Break",
        "Lunch Break",
        "Restroom Break",
        "Leaving for the Day",
      ],
      pauseReason: "",
      personalDetails: "",
      title: "",
    };
  },
  mounted() {
    if (this.activeUser.given_type === "missed_calls") {
      if (this.activeUser.contact_type === "B2C") {
        this.title = ` ${this.activeUser.phone_number}  - ${
          this.activeUser.person_name
        } - ${this.timeStampconverWithTime(this.activeUser.time / 1000)}`;
      } else {
        this.title = `${
          this.activeUser.phone_number
        } - ${this.timeStampconverWithTime(this.activeUser.time / 1000)}`;
      }
    } else if (this.activeUser.given_type === "untracked_calls") {
      this.title = `${
        this.activeUser.phone_number
      } - ${this.timeStampconverWithTime(this.activeUser.time)}`;
    } else if (this.activeUser.given_type === "ne_visits" || this.activeUser.given_type === "net_enquiry" || this.activeUser.given_type === "ne_website_google" || this.activeUser.given_type === "ne_other_sources") {
      this.title = `${this.activeUser.name} - ${this.activeUser.enquiry_date}`;
    }else if(this.activeUser.given_type === "u6_today" || this.activeUser.given_type === "u6_delays" || this.activeUser.given_type === "u5_today" || this.activeUser.given_type === "u5_delays" || this.activeUser.given_type === "u3_today" || this.activeUser.given_type === "u3_delays" || this.activeUser.given_type === "u2_today" || this.activeUser.given_type === "u2_delays" || this.activeUser.given_type === "mhps" || this.activeUser.given_type === "mhps_superbot" || this.activeUser.given_type === "mhps_without_superbot" || this.activeUser.given_type === "lead_visits" ||  this.activeUser.given_type === "flag" || this.activeUser.given_type === "waiting" || this.activeUser.given_type === "blank_u" || this.activeUser.given_type === "sr_queue_priority"){
      if(localStorage.getItem("team") == "SR"){
        this.title = `${this.activeUser.mwb.identity} - ${
        this.activeUser.mwb.person_name
        } - ${this.activeUser.mwb.level} - ${this.activeUser.mwb.iiml_level} - ${this.unixTimestampConvert(
          this.activeUser.mwb.last_call
        )}`;
      }else{
      this.title = `${this.activeUser.mwb.identity} - ${
        this.activeUser.mwb.person_name
      } - ${this.activeUser.mwb.iiml_level} - ${this.unixTimestampConvert(
        this.activeUser.mwb.last_call
      )}`;
      }
    }else if(this.activeUser.given_type === "u3_plus_today" || this.activeUser.given_type === "u3_plus_delays" || this.activeUser.given_type === "u3_plus_plus_today" || this.activeUser.given_type === "u3_plus_plus_delays"  ){
      if(localStorage.getItem("team") == "SR"){
        this.title = `${this.activeUser.mwb.identity} - ${
        this.activeUser.mwb.person_name
        } - ${this.activeUser.mwb.level} - ${this.activeUser.mwb.iiml_level} - ${this.unixTimestampConvert(
          this.activeUser.mwb.last_call
        )}`;
      }else{
      this.title = `${this.activeUser.mwb.identity} - ${
        this.activeUser.mwb.person_name
      } - ${this.activeUser.mwb.level} - ${this.activeUser.mwb.iiml_level} - ${this.unixTimestampConvert(
        this.activeUser.mwb.last_call
      )}`;
      }
    }
    else {
      this.title = `${this.activeUser.mwb.identity} - ${
        this.activeUser.mwb.person_name
      } - ${this.activeUser.mwb.level} - ${this.activeUser.mwb.iiml_level} - ${this.unixTimestampConvert(
        this.activeUser.mwb.last_call
      )}`;
    }
    // console.log(this.activeUser);
    // this.personalDetails = this.activeUser
    // eventbus.$on('reload-queue-popup', () => {
    // })
    // console.log(this.activeUser);
    // if (this.activeUser.level == "M7") {
    //   if (this.activeUser.courses.indexOf("CPA") != -1) {
    //     this.openCPAPopup(this.activeUser, true);
    //     return;
    //   } else if (this.activeUser.courses.indexOf("CMA") != -1) {
    //     this.openCMAPopup(this.activeUser, true);
    //     return;
    //   }
    // }
  },
  methods: {
    rescheduleCall() {
      console.log(this.activeUser);
      // return;
      let obj = {
        last_ten_digits: this.activeUser.last_ten_digits,
      };
      axios
        .post(`${constants.SERVER_API}callNotConnecting`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            eventbus.$emit("update-queue");
            // this.$vs.loading.close();
            // this.$vs.loading.close(this.$refs.loadableButton.$el);
          }
        })
        .catch((error) => {
          this.handleError(error);
          // this.$vs.loading.close();
          // this.$vs.loading.close(this.$refs.loadableButton.$el);
        });
    },
    timeStampconverWithTime(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YY h:mm");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    changeCallPermission(event) {
      if (event === "Pause") {
        this.popupActivo = true;
      } else {
        const obj = {
          state: "Start",
          reason: "",
        };
        this.sendQueueStatus(obj);
        this.$emit("changeCallPermission", event);
      }

      // this.$emit("changeCallPermission", event);
    },

    submitPauseReason() {
      if (this.pauseReason === "") {
        this.$vs.notify({
          title: "Invalid Input",
          text: "Please select a reason for pausing.",
          color: "danger",
        });
        return;
      }
      // this.$vs.loading();
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: this.$refs.loadableButton.$el,
        scale: 0.45,
      });
      const obj = {
        state: "Pause",
        reason: this.pauseReason,
      };
      this.sendQueueStatus(obj);
      this.$emit("changeCallPermission", obj.state);
      this.popupActivo = false;
    },

    sendQueueStatus(stateObject) {
      axios
        .post(`${constants.SERVER_API}saveAndNextStatus`, stateObject, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data === "Success") {
            // this.$vs.loading.close();
            this.$vs.loading.close(this.$refs.loadableButton.$el);
          }
        })
        .catch((error) => {
          this.handleError(error);
          // this.$vs.loading.close();
          this.$vs.loading.close(this.$refs.loadableButton.$el);
        });
    },
  },
};
</script>

<style>
.center {
  width: 70%;
  display: block;
  margin: auto;
  margin-top: 10%;
  position: relative;
}
</style>
