<template>
  <div class="personna-container mb-5">
    <div class="m-6">
      <!-- <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="11"
        >
          <p class="personna_heading">Personna Details</p>
        </vs-col>
      </vs-row> -->
       <vs-row class="mt-5">
        <vs-col
          class="details_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2.9"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Pathway Profiling</p>
        </vs-col>
      </vs-row>
      <div class="persona_details_contant" style="min-height:107px;">
        <vs-row  class="my-5">
           <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="5.5"
            vs-offset="0.3"
            style="padding: 0px"
          >
          <div style="width: 100%;">
             <p class="sub_heading mb-1" style="color: #1900af">Are you interested in working in the US for 3 years?</p>
            <v-select
            style="
              width: 100%;
              border: 1px solid #1900af;
              border-radius: 6px;
            "
            class="pathway_select"
            v-model="select_pathway_value"
            @input="changePathway"
            :options="pathway_value_option"
          ></v-select>
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="5.5"
            vs-offset="0.3"
            style="padding: 0px"
          >
          <div style="width: 100%;">
             <p class="sub_heading mb-1" style="color: #1900af">Are you looking at job change in India right now ?</p>
            <v-select :disabled="pathwayValue"
            style="width: 100%;
              border: 1px solid #1900af;
              border-radius: 6px;
            "
            class="pathway_select"
            v-model="selected_placement_assistance"
            :options="placement_assistance_options"
          ></v-select>
          </div>
          </vs-col>
        </vs-row>
        <vs-row  class="my-5">
           <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="5.5"
            vs-offset="0.3"
            style="padding: 0px"
          >
          <div style="width: 100%;">
              <p class="sub_heading" style="color: #1900af">
                Graduation Year <span v-if="this.select_pathway_value != '' && this.select_pathway_value != null">*</span>
              </p>
              <v-select style="width: 100%;
              border: 1px solid #1900af;
              border-radius: 6px;
            " class="pathway_select" v-model="select_graduation_year" :options="graduation_year_option"></v-select>
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="5.5"
            vs-offset="0.3"
            style="padding: 0px"
          >
          <div style="width: 100%;">
             <p class="sub_heading " style="color: rgba(95, 19, 155, 0.8)">
            Current Location <span v-if="this.select_pathway_value != '' && this.select_pathway_value != null">*</span>
          </p>
             <vue-google-autocomplete
            class="current_location"
            ref="address1"
            id="map1"
            placeholder=""
            v-on:placechanged="getAddressDetails"
            types="(regions)"
            style="
              position: relative;
              z-index: 500;
              width: 100%;
              height: 38px;
              padding-inline-start:7px;
              border: 1px solid rgba(95, 19, 155, 0.8);
              border-radius: 6px;
              color: rgba(95, 19, 155, 0.8);
            "
            v-model="current_location"
          >
          </vue-google-autocomplete>
          </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="mt-5">
        <vs-col
          class="details_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3.4"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Work Ex Profiling</p>
        </vs-col>
      </vs-row>
      <div class="persona_details_contant" style=" display: flex;height:105.45px;">
        <vs-row vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
          <!-- <div style="width: 100%;"> -->
             <!-- <p class="sub_heading mb-1" style="color: #820e0e">Work Ex Profiling</p>
             <v-select
            style="
              z-index: 2000;
              width: 100%;
              border: 1px solid #820e02;
              border-radius: 6px;
              padding: 0px; height: 40px
            "
            :disabled="true"
            class="personaa_select"
            v-model="selected_persona"
            :options="persona_option"
          ></v-select>
          </div> -->
          <div style="width: 100%" class="responsive_input_box">
              <p class="sub_heading mb-1" style="color: #820e0e">Work Ex Profiling</p>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
                class="work_contant"
              >
                <p class="sub_heading" style="color: #820e0e;">{{ selected_persona }}</p>
                <!-- <p>
                  <vs-icon
                    color="rgba(0, 0, 0, .25)"
                    class="mr-2"
                    icon="expand_more"
                  ></vs-icon>
                </p> -->
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
            <div style="width: 100%;" class="responsive_input_box">
             <p class="sub_heading mb-1" style="color: #370873">Company Name <span v-if="this.select_pathway_value != 'Yes' && this.select_pathway_value != '' && this.select_pathway_value != null">*</span></p>
             <vs-input class="company_input" v-model="company" />
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px 1.5% 0px 0px"
          >
             <div style="width: 100%;">
             <p class="sub_heading mb-1" style="color: #3e4f0d">
            No of Years of Work Experience <span v-if="this.select_pathway_value != 'Yes' && this.select_pathway_value != '' && this.select_pathway_value != null">*</span>
          </p>
             <vs-input class="experience_input" v-model="experience" @input="restrictInput"/>
          </div>
          </vs-col>
        </vs-row>
      </div>



      <vs-row class="mt-5">
        <vs-col
          class="details_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4.8"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Professional Qualification Profiling</p>
        </vs-col>
      </vs-row>
      <div class="persona_details_contant" style="min-height:107px">
        <div class="my-5">
          <vs-row vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="5.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
          <div style="width: 100%;">
             <p class="sub_heading mb-1" style="color: #084b71">
            Indian Professional Qualification <span v-if="this.select_pathway_value != 'Yes' && this.select_pathway_value != '' && this.select_pathway_value != null">*</span>
          </p>
            <treeselect
            placeholder=" "
            class="pg_select"
            v-model="selected_indian_prof"
            :multiple="true"
            openDirection="bottom"
            :options="indian_professional_qualifications"
            style="
              border: 1px solid #084b71;
              border-radius: 6px;
              min-height: 38px;
              color: #084b71;
            "
          />
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="5.7"
            vs-offset="0.2"
            style="padding: 0px 1.5% 0px 0px"
          >
            <div style="width: 100%;">
             <p class="sub_heading mb-1" style="color: #3E4F0D">
            Global Professional Qualification <span v-if="this.select_pathway_value != 'Yes' && this.select_pathway_value != '' && this.select_pathway_value != null">*</span>
          </p>
             <treeselect
            placeholder=" "
            class="pg_select"
            v-model="selected_global_prof"
            :multiple="true"
            openDirection="bottom"
            :options="global_professional_qualifications"
            style="
              z-index: 2000;
              border: 1px solid #084b71;
              border-radius: 6px;
              min-height: 38px;
              color: #3E4F0D;
            "
          />
          </div>
          </vs-col>
        </vs-row>

        </div>
      </div>

      <vs-row class="mt-5">
        <vs-col
          class="details_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Others</p>
        </vs-col>
      </vs-row>
      <div class="persona_details_contant" style="min-height:107px">
        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="mt-6 mb-5">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
          <div style="width: 100%;" >
             <p class="sub_heading" style="color: rgba(182, 0, 110, 0.8)">
            UG Graduate Qualification <span v-if="this.select_pathway_value != 'Yes' && this.select_pathway_value != '' && this.select_pathway_value != null">*</span>
          </p>
            <treeselect
            placeholder=""
            v-model="selected_ug"
            :multiple="true"
            :options="ug_options"
            class="ug_select"
            openDirection="bottom"
            style="
              z-index: 1800;
              border: 1px solid rgba(182, 0, 110, 0.8);
              border-radius: 5px;
              color: rgba(182, 0, 110, 0.8);
            "
          />
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.4"
            style="padding: 0px 1.5% 0px 0px"
          >
            <div style="width: 100%;">
             <p class="sub_heading" style="color: #217874">PG Qualification</p>
             <treeselect
            placeholder=" "
            class="pg_select"
            v-model="selected_pg"
            :multiple="true"
            :options="pg_options"
            openDirection="bottom"
            style="
              z-index: 1800;
              border: 1px solid #217874;
              border-radius: 6px;
              min-height: 38px;
              color: #217874;
            "
          />
          </div>
          </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px 1.5% 0px 0px"
          >
            <div style="width: 100%;" class="responsive_input_box">
             <p class="sub_heading " style="color: rgba(95, 19, 155, 0.8)">
            Current Location <span v-if="this.select_pathway_value == 'Yes'">*</span>
          </p>
             <vue-google-autocomplete
            class="current_location"
            ref="address1"
            id="map1"
            placeholder=""
            v-on:placechanged="getAddressDetails"
            types="(regions)"
            style="
              position: relative;
              z-index: 1000;
              width: 100%;
              height: 38px;
              padding-inline-start:7px;
              border: 1px solid rgba(95, 19, 155, 0.8);
              border-radius: 6px;
              color: rgba(95, 19, 155, 0.8);
            "
            v-model="current_location"
          >
          </vue-google-autocomplete>
          </div>
          </vs-col> -->
        </vs-row>
      </div>
      <!-- <vs-row class="mt-6 mb-4">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button @click="updatePersonDetails" color="#0044BA">
            Update
          </vs-button>
        </vs-col>
      </vs-row> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Treeselect from "@riophae/vue-treeselect";
import EventBus from "../eventbus";
export default {
  props: ["persona_details","mwb_id"],
  mounted() {
    this.persona_data = this.persona_details;
    EventBus.$off("make-pathway-empty")
    EventBus.$off("persona-candidate-queue-updated-data")

    this.getAllQualifications();
    this.getCandidatePersonaDetails();
    this.emitData();
    EventBus.$emit("path-way-select-queue", this.select_pathway);

    EventBus.$on('changed-pathway-value', (pathway_value) => {
        this.select_pathway_value = pathway_value;
      }),
    EventBus.$on("persona-candidate-queue-updated-data", (data) => {
      console.log("GOT DATA FROM persona-candidate-updated-data after persona details are updated")
      this.persona_data = data;
      this.getCandidatePersonaDetails();
    })

    EventBus.$on("make-pathway-empty", () => {
        this.select_pathway = ""
    })
    this.getYears();
  },
  components: {
    "vue-google-autocomplete": VueGoogleAutocomplete,
    "v-select": vSelect,
    Treeselect,
  },
  watch: {
    select_pathway_value(val){
      if(val == 'Yes'){
        this.pathwayValue = true;
        this.placement_assistance_options = [
          "No",
          "Maybe"
        ]
      } else {
        this.pathwayValue = false;
        this.placement_assistance_options = [
          "Yes",
          "No",
          "Maybe"
        ]
      }
    },
    persona_details: function (val) {
      this.persona_data = val;

      this.existing_persona_data = {
        mwb_id: this.mwb_id,
        company: this.persona_data.company,
        location: this.persona_data.location,
        pathway: this.select_pathway,
        pg_qualification: this.persona_data.pg_qualification,
        indian_professional_qualification: this.persona_data.indian_professional_qualification,
        global_professional_qualification:this.persona_data.global_professional_qualification,
        ug_qualification: this.persona_data.ug_qualification,
        years_of_experience: this.persona_data.years_of_experience,
      }

    },
    selected_placement_assistance(){
      this.emitData();
    },
    select_graduation_year() {
      this.emitData();
    },
    company(){
      this.emitData();
      this.getdeterminePersonaRealtime(this.experience);
    },
    current_location:function(){
       this.emitData();
    },
    selected_pg:function(){
       this.emitData();
        this.getdeterminePersonaRealtime(this.experience);
    },
    selected_indian_prof:function(){
       this.emitData();
    },
    selected_global_prof:function(){
       this.emitData();
    },
    selected_ug:function(){
       this.emitData();
        this.getdeterminePersonaRealtime(this.experience);
    },
    experience:function(val){
       this.emitData();
       if(val.toString().length != 0){
        this.getdeterminePersonaRealtime(val);
       }
    },
    // select_pathway_value(val){
    //   var level = "U2";
    //   if(val == "No"){
    //     this.select_pathway = "Pathway 1 - India";
    //     level = "U0"
    //   }else if(val == "Yes"){
    //     this.select_pathway = "Pathway 2 - US";
    //     level = "U3"
    //   }else if (val == "Maybe"){
    //     this.select_pathway =  "Pathway 3 - Canada";
    //     level= "U2"
    //   }else if(val == "Yes but graduating in 2025 or after"){
    //     this.select_pathway = "Pathway 2 - US";
    //     level = "U1+"
    //   }else{
    //     return level;
    //   }
    //   EventBus.$emit("changed-macc-level", level);
    // },
    select_pathway(val) {
      console.log(val);
      EventBus.$emit("path-way-select-queue", val);
      this.emitData();
    },
    selected_persona(val){
      console.log("Emitting persona changed",val);
      EventBus.$emit("persona-changed-queue", val);
    }
  },
  data() {
    return {
      pathwayValue: false,
      select_graduation_year: "",
      graduation_year_option: [],
      selected_placement_assistance: "",
      placement_assistance_options:[
        "Yes",
        "No",
        "Maybe"
      ],
      // persona_details :{},
      selected_persona: "",
      show_details: false,
      select_pathway_value:"",
      persona_data: {},
      persona_option: [
        "No work ex - College Student",
        "No work ex - Graduate",
        "Working Professional",
        "Prior work-ex - but currently not working",
      ],
      company: "",
      experience: "",
      current_location: "",
      select_pathway: "",
      // pathway_option: [
      //   "Pathway 1 - India",
      //   "Pathway 2 - US",
      //   "Pathway 3 - Canada",
      //   "Pathway 4 - US or Canada",
      //   "Pathway 5 - India/US/Canada",
      // ],
      pathway_value_option:[
        "Yes",
        "Yes but graduating in 2025 or after",
        "No",
        "Maybe",
      ],
      ug_options: [],
      pg_options: [],
      indian_professional_qualifications: [],
      global_professional_qualifications: [],
      selected_indian_prof: [],
      selected_global_prof: [],
      selected_ug: [],
      selected_pg: [],
      emit_pathway: false,
      existing_persona_data : {}
    };
  },
  methods: {
    restrictInput() {
      this.experience = this.experience.replace(/[^0-9.]/g, '');
    },
    getYears(){
      const currentYear = new Date().getFullYear();
      for (let year = currentYear+3; year >= 1950; year--) {
        this.graduation_year_option.push(year);
      }
    },
    changePathway(val){
      var level = "U2";
      if(val == "No"){
        this.select_pathway = "Pathway 1 - India";
        level = "U0"
      }else if(val == "Yes"){
        this.select_pathway = "Pathway 2 - US";
        level = "U3"
      }else if (val == "Maybe"){
        this.select_pathway =  "Pathway 3 - Canada";
        level= "U2"
      }else if(val == "Yes but graduating in 2025 or after"){
        this.select_pathway = "Pathway 2 - US";
        level = "U1+"
      }else{
        return level;
      }
      EventBus.$emit("changed-macc-level", level);
    },
    getdeterminePersonaRealtime(experience){
      this.experience = experience;
      let selected_pg = "";
      if(this.selected_pg.length != 0){
        selected_pg = this.selected_pg.join();
      }else{
        selected_pg = 'NA'
      }
      let selected_ug = "";
      if(this.selected_ug.length != 0){
        selected_ug = this.selected_ug.join();
      }else{
        selected_ug = 'NA'
      }
        let company_value = false;
        let onWorkExCollageStudent = false;
        let onWorkExGraduate = false;
        let workingProfessional = false;
        let exWorkingProfessional = false;
        let NA = false;
        if (this.company.startsWith('EX-') || this.company.startsWith('ex-') || this.company.startsWith('Ex-') || this.company.startsWith('eX-')) {
          company_value = true;
        }
        if(this.experience == 0 && selected_pg == 'NA' && selected_ug == 'NA' ){
          onWorkExCollageStudent = true;
        }else if(this.experience == 0 && (selected_pg != 'NA' || selected_ug != 'NA')){
          onWorkExGraduate = true;
        }else if(this.experience >= 1 && !company_value && (company_value != "NA" || company_value != '' || company_value != null)){
          workingProfessional = true;
        }else if(this.experience >= 1 && company_value && (company_value != "NA" || company_value != '' || company_value != null ||selected_pg == 'NA' || selected_pg == 'NA')){
          exWorkingProfessional = true;
        }else{
          NA = true;
        }

        if (onWorkExCollageStudent) {
            this.selected_persona = "No work ex - College Student";
        }
        if (onWorkExGraduate) {
            this.selected_persona = "No work ex - Graduate";
        }

        if (workingProfessional) {
            this.selected_persona = "Working Professional";
        }

        if (exWorkingProfessional) {
            this.selected_persona = "Prior work-ex - but currently not working";
        }
        if (NA) {
            this.selected_persona = "NA (Not Available) Non-define Data";
        }
    },
    emitData() {
      let params = {
        mwb_id: this.mwb_id,
        company: this.company,
        location: this.current_location,
        pathway: this.select_pathway,
        pathway_value : this.select_pathway_value,
        // persona: this.selected_persona,
        placement_assistance: this.selected_placement_assistance,
        pg_qualification: this.selected_pg.join(),
        indian_professional_qualification: this.selected_indian_prof.join(),
        global_professional_qualification: this.selected_global_prof.join(),
        ug_qualification: this.selected_ug.join(),
        years_of_experience: this.experience,
        graduation_year: '',
      }
      if(this.select_graduation_year == null){
        params.graduation_year = '';
      }else{
        params.graduation_year = this.select_graduation_year;
      }
      // console.log("EMITING UPDATED Data--",params)
      EventBus.$emit("check-persona-queue-data",params);
    },
    getCandidatePersonaDetails() {
      console.log("PERSONA", this.persona_data)
      this.selected_ug = [];
      this.selected_pg = [];
      this.selected_ug = [];
      this.selected_pg = [];
      this.current_location = "";
      this.company = "";
      this.select_pathway = "";
      this.selected_indian_prof = [];
      this.selected_global_prof = [];
      this.selected_persona = "";
      this.company = "";
      this.experience = "";

      if (Object.keys(this.persona_data).length > 3) {
        if (this.persona_data.ug_qualification != "NA") {
          this.selected_ug = this.persona_data.ug_qualification.split(",");
        }

        if (this.persona_data.pg_qualification != "NA") {
          this.selected_pg = this.persona_data.pg_qualification.split(",");
        }

        if (this.persona_data.years_of_experience != "NA" || this.persona_data.years_of_experience != null ) {
          this.experience = this.persona_data.years_of_experience
        }

        if (this.persona_data.pathway_value != "NA") {
          this.select_pathway_value = this.persona_data.pathway_value
        }

        if (
          this.persona_data.indian_professional_qualification != "NA"
        ) {
          this.selected_indian_prof = this.persona_data.indian_professional_qualification.split(',');
        }

        if (
          this.persona_data.global_professional_qualification != "NA"
        ) {
          this.selected_global_prof = this.persona_data.global_professional_qualification.split(',');

        }

        if (this.persona_data.persona != "NA" && this.persona_data.persona != 'NA (Not Available) Non-define Data') {
          this.selected_persona = this.persona_data.persona
        }

        if (this.persona_data.company != "NA") {
          this.company = this.persona_data.company;
        }

         if (this.persona_data.placement_assistance != "NA") {
         this.selected_placement_assistance = this.persona_data.placement_assistance;
        }

        this.current_location = this.persona_data.location;
        if (this.persona_data.graduation_year != "NA" || this.persona_data.graduation_year != null) {
          this.select_graduation_year = this.persona_data.graduation_year
        }
      } else {

        if (this.persona_data.company != "NA") {
          this.company = this.persona_data.company;
        }

        this.current_location = this.persona_data.location;
         if (this.persona_data.years_of_experience == "NA" || this.persona_data.years_of_experience == null || this.persona_data.years_of_experience == "") {
           this.experience = null;
        }else
        {
          this.experience = this.persona_data.years_of_experience
        }
        if (this.persona_data.graduation_year == "NA" || this.persona_data.graduation_year == null || this.persona_data.graduation_year == "") {
          this.select_graduation_year = null;
        } else {
          this.select_graduation_year = this.persona_data.graduation_year
        }

      }
    },
    getAllQualifications() {
      this.$vs.loading();
      let url = `https://mfcomms.2x2.ninja/api/getAllQualifications`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Qualification", response.data);
          // this.ug_options = response.data.UG;
          // this.pg_options = response.data.PG;
          // this.indian_professional_qualifications = response.data.PROF;
          for (let i = 0; i < response.data.ug.length; i++) {
            const element = response.data.ug[i];
            const obj = {
              id: element,
              label: element,
            };
            this.ug_options.push(obj);
          }
          for (let i = 0; i < response.data.pg.length; i++) {
            const element = response.data.pg[i];
            const obj = {
              id: element,
              label: element,
            };
            this.pg_options.push(obj);
          }
          for (
            let i = 0;
            i < response.data.indian_professional_qualification.length;
            i++
          ) {
            const element = response.data.indian_professional_qualification[i];
            const obj = {
              id: element,
              label: element,
            };
            this.indian_professional_qualifications.push(obj);
          }

          for (
            let i = 0;
            i < response.data.global_professional_qualification.length;
            i++
          ) {
            const element = response.data.global_professional_qualification[i];
            const obj = {
              id: element,
              label: element,
            };
            this.global_professional_qualifications.push(obj);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    getAddressDetails: function (addressData) {
      this.current_location =
        addressData.locality +
        "," +
        addressData.administrative_area_level_1 +
        "," +
        addressData.country;
      console.log("address dataaaa", this.current_location);
    },
    updatePersonDetails() {
      let exp_val = Number(this.experience);
      if (isNaN(exp_val)) {
        this.$vs.notify({
          text: "Experience Cannot Be String",
          color: "danger",
        });
      } else if(this.current_location == null || this.current_location == ""){
        this.$vs.notify({
          text: "Location cannot be empty",
          color: "danger",
        });
      }else {
        this.$vs.loading();
        let obj = {
          mwb_id: this.mwb_id,
          company: this.company,
          location: this.current_location,
          pathway: this.select_pathway,
          pathway_value: this.select_pathway_value,
          // persona: this.selected_persona,
          pg_qualification: this.selected_pg.join(),
          indian_professional_qualification: this.selected_indian_prof.join(),
          global_professional_qualification: this.selected_global_prof.join(),
          ug_qualification: this.selected_ug.join(),
          years_of_experience: this.experience,
        };
        console.log("obj", obj);
        let url = `https://mfcomms.2x2.ninja/api/updateCandidatePersonaDetails`;
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("Personna Data", response.data);
            if (response.data.status == "success") {
              this.$vs.notify({
                title: "Success",
                text: "Persona Details Updated Successfully",
                color: "success",
              });
              // console.log("API AT EMIT",this.existing_persona_data ,obj)

            } else {
              this.$vs.notify({
                title: "Error",
                text: "Something went wrong",
                color: "danger",
              });
            }
            this.$vs.loading.close();
            EventBus.$emit("check-persona-queue-data",obj);

            EventBus.$emit("update-persona-queue-data")

            // this.getCandidatePersonaDetails();
          })
          .catch((error) => {
            this.handleError(error);
            this.getCandidatePersonaDetails();
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>

<style>
.personna-container {
  background: rgba(166, 185, 254, 0.15);
  border: 1px solid rgba(131, 158, 254, 0.7);
  border-radius: 15px;
  margin-left: 5px;
}

.personna_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-left: 3px;
  color: #000000;
}

.persona-details#wp {
  border: 1px solid #820e0e;
  border-radius: 10px;
}

.company_input {
  border: 1px solid #370873;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  color: #370873;
  /* padding-left: 5px; */
}

.experience_input {
  border: 1px solid #3e4f0d;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  color: #3e4f0d;
  /* margin-left: 5px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.sub_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-left:3px;
}

.persona_data {
  font-family: "Montserrat";
  height: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 35px;
  text-align: center;
}

.personaa_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #0044bf;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.qualification_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #084b71;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.pathway_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #1900af;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.ug_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: rgba(182, 0, 110, 0.8);
  margin-left: 7px;
}

.pg_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #217874;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.company_input.vs-con-input.vs-input--input.normal {
  padding-left: 14px !important;
}

.experience_input.vs-con-input.vs-input--input.normal {
  padding-left: 14px !important;
}
.details_heading {
  min-height: 35.93px;
  background: #ffffff;
  border: 1px solid rgba(131, 158, 254, 0.25);
  border-radius: 10px 10px 0px 0px;
}
.details_sub_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align:center;
  line-height: 15px;
  color: #000000;
}
.persona_details_contant {

  background: #ffffff;
  border: 1px solid rgba(131, 158, 254, 0.25);
  border-radius: 15px;
}
.work_contant {
  min-height: 38px;
  padding: 3px 0px 3px 0px;
  border: 1px solid  #820e0e;
  border-radius: 6px;
}
@media screen and (min-width: 1100px) and (max-width: 1710px)  {
 .responsive_input_box{
  margin-block-start: 11%;
 }
}
</style>
