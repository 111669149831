<template>
  <div>
    <div class="boxed">
      <poa-section :studentDetails="studentDetails"></poa-section>
    </div>
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="10"
        >
          <p class="heading">STUDENT REFERENCE</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="dark"
            type="gradient"
            icon="add"
            @click="add_student_referrance"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Can-ID</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Candidate Name</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Reference Date</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Current Status</b>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Bonus</b>
        </vs-col>-->
      </vs-row>
      <div v-for="users in studentDetails.student_reference" :key="users.index">
        <!-- <vs-row
          v-for="fetch in studentDetails.engagements"
          :key="fetch.key"
          style="height: fit-content"
        >-->
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_identity }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_person_name }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ convertTimestampToDate(users.reference_given_date) }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">{{ users.bonus_status }}</b>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
              <vs-checkbox v-model="student_bonus" :vs-value="users.student_name"></vs-checkbox>
          </vs-col>-->
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import POA_Section from "../queue/queuePoa.vue";
import EventBus from "../eventbus";
export default {
  components: {
    "poa-section": POA_Section
  },
  props: {
    studentDetails: {
      type: Object,
      required: true
    }
  },
  methods: {
    add_student_referrance() {
      console.log("emitted");
      EventBus.$emit("open-add-student-referrance", this.studentDetails);
      // this.EmitEvent('open-add-student-referrance', this.studentDetails.id)
    }
  }
};
</script>

<style></style>
