<template>
    <vs-card style="height: 66vh; overflow-y: scroll">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" style="height: 100px">
                <vs-navbar text-color="black" active-text-color="white" class="myNavbar" v-model="indexActive">
                    <!-- <div slot="title">
            <vs-navbar-title>{{ navTitleName }}</vs-navbar-title>
          </div>-->

                    <vs-navbar-item index="0">
                        <a href="#" @click="navSettings('All')">ALL</a>
                    </vs-navbar-item>
                    <vs-navbar-item index="1">
                        <a href="#" @click="navSettings('Call')">CALL</a>
                    </vs-navbar-item>
                    <vs-navbar-item index="2">
            <a href="#" @click="navSettings('Visit')">VISIT</a>
          </vs-navbar-item>
          <!-- <vs-navbar-item index="3">
            <a href="#" @click="navSettings('Email')">EMAIL</a>
          </vs-navbar-item>
          <vs-navbar-item index="4">
            <a href="#" @click="navSettings('Whatsapp')">WHATSAPP</a>
          </vs-navbar-item> -->
                </vs-navbar>
            </vs-col>
            <div v-show="allpage === true" style="width: 100%">
                <vs-row style="margin-left: 2%">
                    <div v-for="(badge, index) in arr" :key="index">
                        <vs-avatar v-show="badge.value !== 0" size="32px" color="danger" badge-color="black"
                            :badge="badge.value" :text="badge.name" />
                    </div>
                </vs-row>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
                        <vs-card>
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                                    <vs-icon style="margin-right: 1%" :icon="fetchIcon(payload.type)"></vs-icon>
                                    <b style="margin-right: 2%">{{
                                        payload.engagement_added_on
                                    }}</b>
                                    - {{ payload.engagement_details }}
                                </vs-col>
                            </vs-row>
                        </vs-card>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 2%">
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
                        <b>- {{ payload.added_by_name }}</b>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col>
                        <vs-card>
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                                    <span class="contact_buttons_span" @click="navSettings('Call')">
                                        <vs-icon style="font-size: 25px;
                                                    width: 50px !important;
                                                    height: 50px !important;
                                                    padding-top: 12px;
                                                " icon="local_phone" color="white" bg="#d76c2f" round></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                                    <!-- <span
                    class="contact_buttons_span"
                    @click="navSettings('Visit')"
                  >-->
                                    <span class="contact_buttons_span">
                                        <vs-icon style="
                                                    font-size: 25px;
                                                    width: 50px !important;
                                                    height: 50px !important;
                                                    padding-top: 12px;
                                                " icon="place" color="white" bg="#d76c2f" round></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                                    <!-- <span
                    class="contact_buttons_span"
                    @click="navSettings('Email')"
                  >-->
                                    <span class="contact_buttons_span">
                                        <vs-icon style="
                                                    font-size: 25px;
                                                    width: 50px !important;
                                                    height: 50px !important;
                                                    padding-top: 12px;
                                                " icon="mail" color="white" bg="#d76c2f" round></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                                    <!-- <span
                    class="contact_buttons_span"
                    @click="navSettings('Whatsapp')"
                  >-->
                                    <span class="contact_buttons_span">
                                        <vs-icon style="
                                                    font-size: 25px;
                                                    width: 50px !important;
                                                    height: 50px !important;
                                                    padding-top: 12px;
                                                " icon="message" color="white" bg="#d76c2f" round></vs-icon>
                                    </span>
                                </vs-col>
                            </vs-row>
                        </vs-card>

                        <vs-card style="background: #dc6161" v-if="payload.mhp_flag === 1">
                            <div>
                                <vs-row style="margin-top: 3%; margin-bottom: 3%">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                        style="font-size: 20px; color: white">
                                        <b>MISSED HOT POCKET</b>
                                        <!-- <vs-icon
                      style="margin-left:2%"
                      size="medium"
                      icon="notification_important"
                      color="white"
                    ></vs-icon>-->
                                    </vs-col>
                                </vs-row>
                                <vs-divider color="rgb(255, 255, 255)" />
                                <vs-row v-if="show_mhp_source" style="margin-top: 3%; margin-bottom: 3%">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                        style="font-size: 15px; color: white">
                                        <b>Type:</b>
                                        <b style="margin-left: 2%">{{
                                            payload.mhp_type
                                        }}{{ completeData.mhp_details }}</b>
                                    </vs-col>
                                </vs-row>
                                <vs-row class="gaps">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                        style="font-size: 15px; padding-left: 5px; color: white">
                                        <b>Date:</b>
                                        <b style="margin-left: 2%">{{
                                            convertTimestampToDate(
                                                payload.mhp_date
                                            )
                                        }}</b>
                                    </vs-col>
                                </vs-row>
                            </div>
                        </vs-card>

                        <vs-collapse open-hover type="border" v-if="
                            payload.given_type === 'net_enquiry' &&
                            payload.net_enquiry.net_enquiry_type === 'chat'
                        ">
                            <vs-collapse-item>
                                <div slot="header">Show Chat</div>
                                <chatInterface :details="payload.net_enquiry"></chatInterface>
                            </vs-collapse-item>
                        </vs-collapse>

                        <vs-card v-if="
                            payload.given_type === 'net_enquiry' &&
                            payload.net_enquiry.net_enquiry_type !== 'chat'
                        ">
                            <div>
                                <vs-row style="margin-top: 3%; margin-bottom: 3%">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                        style="font-size: 20px">
                                        <b>NET ENQUIRY</b>
                                    </vs-col>
                                </vs-row>
                                <vs-divider color="dark" />
                                <vs-row style="margin-top: 3%; margin-bottom: 3%">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                                        style="font-size: 15px">
                                        <b>DETAILS:</b>
                                        <b style="margin-left: 2%">
                                            {{ payload.net_enquiry.details }}
                                        </b>
                                    </vs-col>
                                </vs-row>
                            </div>
                        </vs-card>
                    </vs-col>
                </vs-row>
            </div>
            <div v-show="emailpage === true" style="width: 100%">
                <vs-col style="margin-bottom: 3%" vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
                    <vs-button color="danger" type="gradient" icon="email" @click="emailform">Send Email</vs-button>
                </vs-col>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        <h6 style="color: gray">Engagement:</h6>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                        <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                    </vs-col>
                </vs-row>
                <vs-divider class="my-6"></vs-divider>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                        <h6 style="color: gray">Level:</h6>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                        <div>
                            <!-- <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptions"
                :reduce="text => text.text"
                label="text"
              ></v-select>-->
                            <!-- <vs-select class="w-full" v-model="levels">
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in labelOptions" />
                            </vs-select> -->
                            <v-select autocomplete class="invc-input" v-model="levels"
                                style="border: 1px solid #0044ba; border-radius: 5px;width:250px;" :options="labelOptions"
                                :reduce="(option) => option.id">
                            </v-select>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                        <h6 style="color: gray">Course :</h6>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="CPA">CPA</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="CPA-AA">CPA-AA</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="USP">USP</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="CMA">CMA</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="CFA">CFA</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="FRM">FRM</vs-checkbox>
                    </vs-col>
                    <!-- <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="MAcc">MAcc</vs-checkbox>
                    </vs-col> -->
                    <!-- <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CISA"
                            >CISA</vs-checkbox
                        >
                    </vs-col> -->
                    <!-- <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="EA"
                            >EA</vs-checkbox
                        >
                    </vs-col> -->
                    <!-- <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CIA"
                            >CIA</vs-checkbox
                        >
                    </vs-col> -->
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="IIML-FA">IIML-FA</vs-checkbox>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="IIML-FT">IIML-FT</vs-checkbox>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="IIML-SF">IIML-SF</vs-checkbox>
                    </vs-col>
                    <!-- <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="RPO">RPO</vs-checkbox>
                    </vs-col> -->
                    <!-- <vs-col
            v-if="checkIfRoleis('exed')"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="IIML-DA"
              >IIML-DA</vs-checkbox
            >
          </vs-col>
          <vs-col
            v-if="checkIfRoleis('exed')"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="IIML-HR"
              >IIML-HR</vs-checkbox
            >
          </vs-col>
          <vs-col
            v-if="checkIfRoleis('exed')"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="IIML-PM"
              >IIML-PM</vs-checkbox
            >
          </vs-col>-->
                    <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="DA"
              >DA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="RPA"
              >RPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="FOF(O)"
              >FOF(O)</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="WCBA"
              >WCBA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="NO-BA"
              >NO-BA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="MAYBE"
              >MAYBE</vs-checkbox
            >
          </vs-col>-->
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="IIMI-BA">IIMI-BA</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="XLRI-HR">XLRI-HR</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="XLRI-SH">XLRI-SH</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="AICPA">AICPA</vs-checkbox>
                    </vs-col>
                    <vs-col v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')" vs-type="flex" vs-justify="flex-start"
                        vs-align="flex-start" vs-w="2">
                        <vs-checkbox color="dark" v-model="course" vs-value="IIMI-AA">IIMI-AA</vs-checkbox>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        <h6 style="color: gray">Next follow up date:</h6>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                        <datepicker placeholder="Select Date" :format="customFormatter" v-model="dates"></datepicker>
                    </vs-col>
                </vs-row>
                <!-- <vs-row class="gaps" style="margin-top: 2%">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <h6 style="color: gray;">Send Intro Email,SMS and Whatsapp Invite:</h6>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="lex-start" vs-align="center" vs-w="4">
            <v-select
              :filterable="false"
              :options="send_intro_options"
              placeholder="Select a course"
              v-model="sendIntrocourse"
              style="width:180px"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <vs-button id="send_whatsapp_message_btn" color="dark" type="relief">Send</vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gaps" style="margin-top: 2%">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <h6 style="color: gray;">Webinar:</h6>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <v-select
              :filterable="false"
              :options="webinar_options"
              placeholder="Select a course"
              v-model="webinarIntro"
              style="width:180px"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start " vs-align="center" vs-w="4">
            <vs-button color="dark" type="relief">Send</vs-button>
          </vs-col>
        </vs-row>-->
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
                        <vs-button color="danger" type="gradient" icon="done_outline" @click="API_post_add_engagement">
                            ADD</vs-button>
                    </vs-col>
                </vs-row>
            </div>

            <div v-if="visitpage === true" style="width: 100%">
                <VisitSection :payload="completeData"></VisitSection>
            </div>
            <div v-show="callpage === true" style="width: 100%">
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                        <vs-card>
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                                    <p>
                                        <strong>PREVIOUS ENGAGEMENT -</strong>
                                        {{ payload.engagement_details }}
                                    </p>
                                </vs-col>
                            </vs-row>
                        </vs-card>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                        <p style="color: gray">Connection Status:</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="6">
                        <!-- <v-select style="border-radius: 5px; border: 1px solid #0044ba;" placeholder="Status"
                            class="w-full invc-input" v-model="select1" :options="options1"
                            :reduce="(option) => option.text" label="text" :disabled="schedule_status"></v-select> -->
                        <!-- <vs-select class="w-full" label="status" v-model="select1">
                            <vs-select-item :key="index" :value="item.text" :text="item.text"
                                v-for="(item, index) in options1" />
                        </vs-select> -->
                        <vs-select class="w-full" v-model="select1" style="border: 1px solid #0044ba; border-radius: 5px" :disabled="schedule_status">
                            <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item, index) in options1" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center">
                        <!-- <vs-button v-if="show === true" ref="loadableButton" id="button-with-loading" color="#0044ba"
                            class="vs-con-loading__container gap connection_btn" @click="googleMeetButton()">Google Invite</vs-button> -->
                    </vs-col>
                </vs-row>
                <div v-if="personna_data == true && connected_status.includes(select1)" class="mb-5">
                    <vs-divider class="my-6"></vs-divider>
                    <queue-persona-details :persona_details="persona_details" :mwb_id="payload.id"></queue-persona-details>
                    <!-- <personna-details :persona_details="persona_details" :mwb_id="payload.id"></personna-details> -->
                </div>
                <div v-if="!connected_status.includes(select1) && personna_data == true" class="mb-5">
                    <vs-divider class="my-6"></vs-divider>
                    <!-- <personna-hide-details :persona_details="persona_details"></personna-hide-details> -->
                    <queue-persona-hide-details :persona_details="persona_details"></queue-persona-hide-details>
                    <vs-divider class="my-6"></vs-divider>
                </div>
                <div v-if="show === true">
                    <vs-divider class="my-6"></vs-divider>
                    <!-- <div v-if="connected === true">
                        <vs-row style="margin-bottom: 3%">
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                                <p style="color: gray">Response:</p>
                            </vs-col>
                            <vs-col vs-justify="center" vs-align="flex-start" vs-w="4">
                                <vs-radio color="dark" v-model="responseSelection" vs-value="sameResponse">
                                    <p>SAME RESPONSE</p>
                                </vs-radio>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                                <vs-radio color="dark" id="new" v-model="responseSelection" vs-value="new">
                                    <p>NEW</p>
                                </vs-radio>
                            </vs-col>
                        </vs-row>
                    </div> -->

                    <!-- <vs-row class="gaps" style="margin-top: 2%" v-if="getrole('exed2')">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
              <vs-button
                v-if="
                  webinarCourse === 'IIML-BA' || webinarCourse === 'IITR-BF'
                "
                color="dark"
                type="relief"
                @click="sendZoomInvite"
              >Send {{ webinarCourse }} Zoom Invite</vs-button>
              <vs-button
                v-if="
                  webinarCourse === 'IIML-PM' || webinarCourse === 'IIML-HR'
                "
                color="dark"
                type="relief"
                @click="sendZoomInvite"
              >Send {{ webinarCourse }} Wiley Zoom Invite</vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="lex-start" vs-align="center" vs-w="6">
              <v-select
                :filterable="false"
                :options="['IIML-BA', 'IITR-BF', 'IIML-PM', 'IIML-HR']"
                placeholder="Webinar Course"
                v-model="webinarCourse"
                style="width:150px"
              />
            </vs-col>
          </vs-row>
          <vs-row class="gaps" style="margin-top: 2%" v-if="getrole('cm1')">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
              <vs-button
                color="dark"
                type="relief"
                @click="sendZoomInvite"
              >Send {{ webinarCourse }} zoom invite</vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="lex-start" vs-align="center" vs-w="6">
              <v-select
                :filterable="false"
                :options="['CPA', 'CMA', 'IIML-FA']"
                placeholder="Webinar Course"
                v-model="webinarCourse"
                style="width:150px"
              />
            </vs-col>
          </vs-row>-->

                    <!-- <vs-row class="gaps" v-if="getrole('exed2')" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-button
                color="dark"
                type="relief"
                @click="sendZoomInviteIIMLPM()"
                >Send {{webinarCourse}} Zoom Invite</vs-button
              >
              <vs-button
                color="dark"
                type="relief"
                @click="sendZoomInviteIIMLPM()"
                >Send {{webinarCourse}} Wiley Zoom Invite</vs-button
              >
            </vs-col>
          </vs-row>
          <vs-row class="gaps" v-if="getrole('cm1')" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
            >
              <vs-button
                color="dark"
                type="relief"
                @click="sendZoomInvite('CPA')"
                >Send {{webinarCourse}} zoom invite</vs-button
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
            </vs-col>
          </vs-row>-->
                    <!-- <vs-row v-else class="gaps" style="margin-top: 2%">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"> -->
                    <!-- <vs-button
                color="dark"
                type="relief"
                @click="sendZoomInvite('IIML-BA')"
                >Send zoom invite</vs-button
              >-->
                    <!-- </vs-col>
                    </vs-row> -->

                    <vs-divider class="my-6"></vs-divider>
                    <!-- <vs-row style="margin-bottom: 3%" v-if="checkIfRoleis('cm1')">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <p style="color: gray">Escalation:</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                            <vs-checkbox color="dark" v-model="escalation_boolean"></vs-checkbox>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <vs-select v-if="escalation_boolean === false" disabled placeholder
                                style="margin-top: 4%; margin: 10px" class="w-full" v-model="escalation_acads_member">
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in escaltionOptions" />
                            </vs-select>
                            <vs-select v-if="escalation_boolean === true" placeholder style="margin-top: 4%; margin: 10px"
                                class="w-full" v-model="escalation_acads_member">
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in escaltionOptions" />
                            </vs-select>
                        </vs-col>
                    </vs-row> -->
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <p style="color: gray">Level:</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                            <div v-if="connected === true || exceptionOption">
                                <!-- <vs-select autocomplete class="w-full" v-model="levels">
                                    <vs-select-item :key="index" :value="item.value" :text="item.text"
                                        v-for="(item, index) in labelOptions" />
                                </vs-select> -->
                                <v-select :disabled="!(connected === true || exceptionOption)" autocomplete
                                    class="invc-input" v-model="levels"
                                    style="border: 1px solid #0044ba; border-radius: 5px;width:250px;"
                                    :options="labelOptions" :reduce="(option) => option.id">
                                </v-select>
                            </div>
                            <div v-else>
                                <!-- <vs-select disabled class="w-full" v-model="levels">
                                    <vs-select-item :key="index" :value="item.value" :text="item.text"
                                        v-for="(item, index) in labelOptions" />
                                </vs-select> -->
                                <v-select autocomplete class="invc-input" v-model="levels"
                                    style="border: 1px solid #0044ba; border-radius: 5px;width:250px;"
                                    :options="labelOptions" :reduce="(option) => option.id" :disabled="selected_connection_status">
                                </v-select>
                            </div>


                        </vs-col>
                    </vs-row>
                    <!-- <vs-row style="margin-bottom: 3%" v-if="hide_select">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <p style="color: gray">Exed Level:</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                             <vs-select autocomplete style="width:100%;" v-model="exed_level">

                                <vs-select-item value text />
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in exed_levels" />
                            </vs-select>
                            <v-select autocomplete class="invc-input" v-model="exed_level"
                                style="border: 1px solid #0044ba; border-radius: 5px;width:250px;" :options="exed_levels"
                                :reduce="(option) => option.id">
                            </v-select>
                        </vs-col>
                    </vs-row> -->
                    <!-- <vs-row style="margin-bottom: 3%" >
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <h6 style="color: gray">MAcc Levels:</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4.5">
                             <v-select autocomplete class=" invc-input" v-model="selected_macc_level"
                                style="width:100%;" :options="macc_levels"
                                :reduce="(option) => option.id">
                            </v-select>
                             <vs-select class="w-full" label="MAcc Levels" v-model="selected_macc_level">
                                <vs-select-item :key="index" :value="item.id" :text="item.label"
                                    v-for="(item, index) in macc_levels" />
                            </vs-select>
                        </vs-col>
                    </vs-row> -->
                    <vs-row style="margin-bottom: 3%" v-if="false">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <p style="color: gray">RPO Levels:</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                            <div v-if="iimlConnected === true">
                                <vs-select autocomplete class="w-full" v-model="iimlLevel">
                                    <vs-select-item value text />
                                    <vs-select-item :key="index" :value="item.value" :text="item.text"
                                        v-for="(item, index) in iimlLevels" />
                                    <!-- <vs-select-item
                                        disabled
                                        value="Lead-DB"
                                        text="Lead-DB"
                                    />
                                    <vs-select-item
                                        disabled
                                        value="Lead-IR"
                                        text="Lead-IR"
                                    /> -->
                                </vs-select>
                            </div>
                            <div v-else>
                                <vs-select autocomplete class="w-full" v-model="iimlLevel" disabled
                                    v-if="this.getrole('exed2')">
                                    <vs-select-item value text />
                                    <vs-select-item :key="index" :value="item.value" :text="item.text"
                                        v-for="(item, index) in iimlLevels" />
                                    <!-- <vs-select-item
                                disabled
                                value="Lead-DB"
                                text="Lead-DB"
                            />
                            <vs-select-item
                                disabled
                                value="Lead-IR"
                                text="Lead-IR"
                            /> -->
                                </vs-select>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                            <p style="color: gray">USP Levels:</p>
                        </vs-col>
                        <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="4.5">
                            <!-- <vs-select class="w-full" v-model="selected_macc_level">
                                <vs-select-item :key="index" :value="item.id" :text="item.label"
                                    v-for="(item, index) in macc_levels" />
                            </vs-select> -->
                            <v-select autocomplete class=" invc-input" v-model="selected_macc_level"
                                style=" width:250px;  border-radius: 5px; border: 1px solid #0044ba;" :options="macc_levels"
                                :reduce="(option) => option.id" :disabled="selected_connection_status">
                            </v-select>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <p style="color: gray">Course :</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="CPA"
                                style="margin-right: 1%">CPA</vs-checkbox>
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="AICPA"
                                style="margin-right: 1%">AICPA</vs-checkbox>
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4')
                            " :disabled="disable" color="dark" v-model="course" vs-value="CPA-AA"
                                style="margin-right: 1%">CPA-AA</vs-checkbox>
                                <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4')
                            " :disabled="disable" color="dark" v-model="course" vs-value="USP"
                                style="margin-right: 1%">USP</vs-checkbox>
                            <!-- <vs-checkbox
                                v-if="
                                    checkIfRoleis('cm1') ||
                                    checkIfRoleis('ac1') ||
                                    checkIfRoleis('cm4')
                                "
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="CISA"
                                style="margin-right: 1%"
                                >CISA</vs-checkbox
                            > -->
                            <!-- <vs-checkbox
                                v-if="
                                    checkIfRoleis('cm1') ||
                                    checkIfRoleis('ac1') ||
                                    checkIfRoleis('cm4')
                                "
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="EA"
                                style="margin-right: 1%"
                                >EA</vs-checkbox
                            > -->
                            <!-- <vs-checkbox
                                v-if="
                                    checkIfRoleis('cm1') ||
                                    checkIfRoleis('ac1') ||
                                    checkIfRoleis('cm4')
                                "
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="CIA"
                                style="margin-right: 1%"
                                >CIA</vs-checkbox
                            > -->


                            <!-- <vs-checkbox
                                v-if="
                                    checkIfRoleis('cm1') ||
                                    checkIfRoleis('ac1') ||
                                    checkIfRoleis('cm4')
                                "
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-FA"
                                style="margin-right: 1%"
                                >IIML-FA</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="
                                    checkIfRoleis('cm1') ||
                                    checkIfRoleis('ac1') ||
                                    checkIfRoleis('cm4')
                                "
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-SF"
                                style="margin-right: 1%"
                                >IIML-SF</vs-checkbox
                            > -->

                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="4" vs-w="8">
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="CMA"
                                style="margin-right: 1%">CMA</vs-checkbox>
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="CFA"
                                style="margin-right: 1%">CFA</vs-checkbox>
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="FRM"
                                style="margin-right: 1%">FRM</vs-checkbox>
                            <!-- <vs-checkbox v-if="
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="MAcc"
                                style="margin-right: 1%">MAcc</vs-checkbox>

                                <vs-checkbox v-if="
                                checkIfRoleis('cm1')
                            " disabled color="dark" v-model="course" vs-value="MAcc"
                                style="margin-right: 1%">MAcc</vs-checkbox> -->

                            <!-- <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " disabled color="dark" v-model="course" vs-value="MAcc"
                                style="margin-right: 1%">MAcc</vs-checkbox> -->
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">

                            <!-- <vs-checkbox
                            v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4')
                            "
                            :disabled="disable"
                            color="dark"
                            v-model="course"
                            vs-value="RPO"
                            style="margin-right: 1%"
                        >
                            RPO
                        </vs-checkbox> -->
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="IIMI-BA"
                                style="margin-right: 1%">
                                IIMI-BA
                            </vs-checkbox>
                             <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="IIMI-AA"
                                style="margin-right: 1%">
                                IIMI-AA
                            </vs-checkbox>
                            <!-- <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="XLRI-HR"
                                style="margin-right: 1%">
                                XLRI-HR
                            </vs-checkbox>
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') || checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="XLRI-SH"
                                style="margin-right: 1%">
                                XLRI-SH
                            </vs-checkbox> -->
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4')
                            " :disabled="disable" color="dark" v-model="course" vs-value="IIML-SF"
                                style="margin-right: 1%">IIML-SF</vs-checkbox>
                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') ||
                                checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="IIML-FT"
                                style="margin-right: 1%">IIML-FT</vs-checkbox>
                            <!-- <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="RPO"
                        style="margin-right: 1%"
                        >RPO</vs-checkbox
                    > -->
                            <!-- <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="XLRI-HR"
                        style="margin-right: 1%"
                        >XLRI-HR</vs-checkbox
                    > -->
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">

                            <vs-checkbox v-if="
                                checkIfRoleis('cm1') ||
                                checkIfRoleis('ac1') ||
                                checkIfRoleis('cm4') ||
                                checkIfRoleis('exed2')
                            " :disabled="disable" color="dark" v-model="course" vs-value="IIMI-BA"
                                style="margin-right: 1%">IIMI-BA</vs-checkbox>
                        </vs-col>
                    </vs-row>
                    <vs-row v-if="show_cfa_dropdown === true" style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <h6 style="color: gray">Select CFA Course levels:</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                            <vs-select class="w-full" style="width: 100%" v-model="selected_cfa_level">
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in cfa_levels" />
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <vs-row v-if="show_frm_dropdown === true" style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <h6 style="color: gray">Select FRM Course parts:</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                            <vs-select class="w-full" style="width: 100%" v-model="selected_frm_parts">
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in frm_parts" />
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <!-- <vs-row class="gaps" style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="flex-start"
                            vs-w="12"
                        >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-BA"
                                style="margin-right: 1%"
                                >IIML-BA</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-PM"
                                style="margin-right: 1%"
                                >IIML-PM</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-HR"
                                style="margin-right: 1%"
                                >IIML-HR</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IITR-BF"
                                style="margin-right: 1%"
                                >IITR-BF</vs-checkbox
                            >
                        </vs-col>
                    </vs-row> -->
                    <!-- <vs-row class="gaps" style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="flex-start"
                            vs-w="12"
                        >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IITR-DB"
                                style="margin-right: 1%"
                                >IITR-DB</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IITM-AA"
                                style="margin-right: 1%"
                                >IITM-AA</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIMK-CX"
                                style="margin-right: 1%"
                                >IIMK-CX</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IITM-FS"
                                style="margin-right: 1%"
                                >IITM-FS</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IITR-CC"
                                style="margin-right: 1%"
                                >IITR-CC</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIMK-FT"
                                style="margin-right: 1%"
                                >IIMK-FT</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-AB"
                                style="margin-right: 1%"
                                >IIML-AB</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-SH"
                                style="margin-right: 1%"
                                >IIML-SH</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IITJ-DE"
                                style="margin-right: 1%"
                                >IITJ-DE</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="XLRI-HR"
                                style="margin-right: 1%"
                                >XLRI-HR</vs-checkbox
                            >
                            <vs-checkbox
                                v-if="checkIfRoleis('exed2')"
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="XLRI-DM"
                                style="margin-right: 1%"
                                >XLRI-DM</vs-checkbox
                            >
                        </vs-col>
                    </vs-row> -->
                    <!-- <vs-row style="margin-bottom: 4%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <p style="color: gray">Select NFD Level :</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="6">
                            <vs-radio :class="{'mradio': isActive}" v-model="type_of_nfd" vs-value="m_nfd" vs-name='radioo' color="#0044ba" class="myRadioBtn">
                                <p class="schedule_checkbox">M Level</p>
                            </vs-radio>
                            &nbsp;
                             <vs-radio v-model="type_of_nfd" vs-value="u_nfd" vs-name='radioo' color="#0044ba" class="myRadioBtn">
                                <p class="schedule_checkbox">U Level</p>
                            </vs-radio> -->
                        <!-- </vs-col>
                    </vs-row> -->
                    <vs-row v-if="show_schedule_call" class="mt-10" style="margin-bottom: 6%">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                        <vs-button @click="schedule_call_popup = true" icon="call" color="#DAF8E6" style=" color: #35ad67;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            ">Schedule Call</vs-button>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <p style="color: gray">Next follow up date: </p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <!-- <datepicker :disabledDates="disabledDates" placeholder="Select Date" :format="customFormatter"
                                v-model="dates"></datepicker> -->
                            <p class="status2">{{ getSelectedDate() }}</p>
                        </vs-col>
                        <vs-col v-if="getSubTeam('IITM-AA') || getSubTeam('IITR-CC')" vs-w="4" vs-type="flex"
                            vs-justify="flex-start" vs-align="center">
                            <vs-button @click="openWileyPopup()" type="gradient" color="dark"><small>Send Wiley
                                    Application</small></vs-button>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                            <p style="color: gray">Engagement:</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                            <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                        </vs-col>
                    </vs-row>

                    <div class="insid-container mt-6">
                        <div class="m-6">
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            <p class="status2">Miles US Pathway - US Visa Orientation Session :</p>
                            </vs-col>
                        </vs-row>
                        <AcceleratorSessionPopup :mwb="completeData.mwb" :email_data="completeData.emails"></AcceleratorSessionPopup>
                        </div>
                    </div>

                    <save-engagement :mwb_courses="mwb_courses" :macc_level="selected_macc_level" :payload="payload" :selected_courses="course" :phone_number="phone_number"
                        :connection_status="connection_status" :person_name="completeData.mwb.person_name"
                        :connected_value="select1" @add-engagement="addEngagement" :save_loading="save_loading"
                        :pathway="pathway" :persona="persona" :save_and_whatsapp_loading="save_and_whatsapp_loading"
                        :all_loading="all_loading" :meetbutton="onlygooglemeetbutton" :whatsapp_button_status="stopLoading">
                    </save-engagement>
                </div>
            </div>
            <!-- <div v-show="callpage === true" style="padding-bottom: 300px; margin-bottom:2%"></div> -->
            <div v-show="whatsapp_page === true" style="width: 100%">
                <vs-col style="margin-bottom: 3%" vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
                    <vs-button color="danger" type="gradient" icon="email">Send Whatsapp</vs-button>
                </vs-col>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        <h6 style="color: gray">Engagement:</h6>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                        <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                    </vs-col>
                </vs-row>
                <vs-divider class="my-6"></vs-divider>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                        <h6 style="color: gray">Level:</h6>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                        <div>
                            <!-- <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptions"
                :reduce="text => text.value"
                label="text"
              ></v-select>-->
                            <!-- <vs-select class="w-full" v-model="levels">
                                <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item, index) in labelOptions" />
                            </vs-select> -->
                            <v-select autocomplete class="invc-input" v-model="levels"
                                style="border: 1px solid #0044ba; border-radius: 5px;width:250px;" :options="labelOptions"
                                :reduce="(option) => option.id">
                            </v-select>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        <p style="color: gray">Next follow up date:</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        <datepicker placeholder="Select Date" :format="customFormatter" v-model="dates"></datepicker>
                    </vs-col>
                    <vs-col v-if="getSubTeam('IITM-AA') || getSubTeam('IITR-CC')" vs-w="4" vs-type="flex"
                        vs-justify="flex-start" vs-align="center">
                        <vs-button @click="openWileyPopup()" type="gradient" color="dark"><small>Send Wiley
                                Application</small></vs-button>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
                        <vs-button color="danger" type="gradient" icon="done_outline" @click="API_post_add_engagement">
                            ADD</vs-button>
                    </vs-col>
                </vs-row>
            </div>
        </vs-row>
        <vs-popup class="holamundo" title="Are you sure you want to send application ?" :active.sync="popupOpen">
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <p>Please confirm that you have taken <b>{{ this.WlieyPersonName }}</b> 's consent to registering
                        him/her to submit application for <b>{{ this.SelectedCOurse }}</b> with these contact details
                        <b>{{ this.PrimaryMobile }}</b> and <b>{{ this.PrimaryEmail }}</b>
                    </p>
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                    <vs-button color="danger" type="border" @click="popupOpen = false">Cancel</vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <vs-button color="success" type="border" @click="sendWileyApplication()">Yes</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
        <!--Schedule Call Popup -->
    <vs-popup class="holamundo schedule_popup" title="Schedule Call" :active.sync="schedule_call_popup"
      style="border-radius: 15px !important">
      <vs-row>
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="grid_view"></vs-icon> &nbsp;
            <span class="schedule_title">&nbsp; Type of next call : </span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vs-radio v-model="type_of_next_call" vs-value="phone" color="#0044ba" class="myRadioBtn">
            <p class="schedule_checkbox">Phone call</p>
          </vs-radio>
          &nbsp;
          <vs-radio v-model="type_of_next_call" vs-value="video" color="#0044ba" class="myRadioBtn">
            <p class="schedule_checkbox">Video call</p>
          </vs-radio>
          &nbsp;
          <vs-radio v-model="type_of_next_call" vs-value="office" color="#0044ba" class="myRadioBtn">
            <p class="schedule_checkbox">Visit</p>
          </vs-radio>
        </vs-col>
      </vs-row>
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="calendar_today"></vs-icon>&nbsp;
            <span class="schedule_title">&nbsp; Select Date :</span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <!-- <datepicker placeholder="DD-MM-YYYY" :format="customFormatter" v-model="schedule_date"
                  style="position: fixed; z-index:2000;">
                </datepicker> -->
          <datepicker v-if="!getrole('exed2')" :disabledDates="disabledDates" placeholder="Select Date"
            :format="customFormatter" v-model="dates" style="
              position: fixed;
              z-index: 2000;
              border: 1px solid #0044ba;
              border-radius: 5px;
              width: 205px;
            "></datepicker>
          <datepicker v-else placeholder="Select Date" :format="customFormatter" v-model="dates" style="
              position: fixed;
              z-index: 2000;
              border: 1px solid #0044ba;
              border-radius: 5px;
              width: 205px;
            "></datepicker>
        </vs-col>
      </vs-row>
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="schedule"></vs-icon>&nbsp;
            <span class="schedule_title">&nbsp; Select Time :</span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vue-timepicker v-if="type_of_next_call == 'phone'" :minute-range="[0, 6, [10, 30], 42, 50]" :minute-interval="10" format="HH:mm"
            v-model="schedule_time" class="myTimePicker" style="
              position: fixed;
              z-index: 1500;
              border: 1px solid #0044ba;
              border-radius: 3px;
              width: 205px;
            ">
          </vue-timepicker>
          <vue-timepicker v-else :minute-range="[0, 6, [10, 30], 42, 50]" :minute-interval="30" format="HH:mm"
            v-model="schedule_time" class="myTimePicker" style="
              position: fixed;
              z-index: 1500;
              border: 1px solid #0044ba;
              border-radius: 3px;
              width: 205px;
            ">
          </vue-timepicker>
        </vs-col>
      </vs-row>

      <div style="display: flex; justify-content: center; margin-top: 2%">
        <vs-button color="#0044ba" @click="scheduleCall">Okay</vs-button>
      </div>
    </vs-popup>
    </vs-card>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import chatInterface from "./DashboardChatInterface";
// import PersonnaHideDetails from "../../components/BigPopup/EngagementSection/PersonnaHideDetails.vue";
// import PersonnaDetails from "../../components/BigPopup/EngagementSection/PersonnaDetails.vue";
import QueuePersonaHideDetails from "./QueuePersonaHideDetails.vue"
import QueuePersonaDetails from "./QueuePersonaDetails.vue"
import AcceleratorSessionPopup from "../BigPopup/EngagementSection/AcceleratorSessionPopup.vue";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import EventBus from "../eventbus.js";
import moment from "moment";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker";
import SaveEngagement from "../BigPopup/EngagementSection/SaveEngagement";
import VisitSection from "../BigPopup/EngagementSection/VisitSection.vue";
import ApiServices from "../../ApiServices";
export default {
    props: {
        payload: {
            required: true,
        },
        selected: {
            required: true,
        },
        completeData: {
            required: true,
        },
    },
    mounted() {
        let login_team = localStorage.getItem("team")
            if(login_team == 'CM'){
            this.show_mhp_source = false;
        }
        this.schedule_status = false
        EventBus.$on('change-connection-status-queue', (status) => {
            this.navSettings('Call');
            console.log("statuss", status);
            this.select1 = status;
            this.type_of_next_call = 'office'
            this.schedule_status = true
            console.log("completeDataa", this.completeData);
            this.upcoming_visits_date = this.completeData.upcoming_visits[0].scheduled_date - 86400;
            this.dates = moment.unix(this.upcoming_visits_date).format("DD-MMM-YYYY");
        })
        if(this.completeData.upcoming_visits.length != 0){
            this.visit_found = 1;
        }else{
            this.visit_found = 0;
            this.options1.unshift({ text: "Connected / Discussed", value: 1 },)
        }
        console.log("completeDatacc",this.completeData);
        this.mwb_courses = this.payload.courses.split(",");
        if (this.payload.enrollments != undefined && this.payload.enrollments.length > 0) {
        this.payload.enrollments.forEach(enrollment => {
            if (enrollment.enrollment_loan_status == 1) {
                this.loan_status = true
            }
        });
        }
        EventBus.$off("path-way-select-queue")
        // EventBus.$off("update-persona-queue-data")
        // EventBus.$off("check-persona-queue-data")

        this.getCandidatePersonaDetails();

        EventBus.$on('update-persona-queue-data', () => {
            // console.log("LISTENING TO update-persona-queue-data")
            this.getPersonnaUpdatedData();

        })

        EventBus.$on('path-way-select-queue', (pathway) => {
            this.pathway = pathway;

            // this.determineMaccLevel();

        })

        EventBus.$on("check-persona-queue-data", (new_data) => {
            // console.log("API ON EMIT--", new_data)
            this.checkPersonaData(new_data);

        })
        EventBus.$on('changed-macc-level', (macc_level) => {
        this.selected_macc_level = macc_level;
      }),

        EventBus.$on('persona-changed-queue', (persona) => {
            // console.log("Received persona on emit", persona)

            this.persona = persona
        })


        if (localStorage.getItem('sub_team') == 'CM') {
            this.hide_select = false
        }
        this.levels = this.payload.level;
        if (this.payload.iiml_level != '' && this.payload.iiml_level !== null) {
            if (this.macc_levels.find((item) => item.id === this.payload.iiml_level)) {
                this.selected_macc_level = this.payload.iiml_level;
            }
            if (this.exed_levels.find((item) => item.id === this.payload.iiml_level)) {
                this.exed_level = this.payload.iiml_level;
            }
        }
        if (this.getrole("cm1")) {
            this.webinarCourse = "CPA";
        } else if (this.getrole("exed2")) {
            this.webinarCourse = "IIML-BA";
        }
        if (
            this.payload.level === "M4-" ||
            this.payload.level === "M4" ||
            this.payload.level === "M5" ||
            this.payload.level === "M6" ||
            this.payload.level === "L4-" ||
            this.payload.level === "L4" ||
            this.payload.level === "L5" ||
            this.payload.level === "L6" ||
            this.payload.level === "M7"
            // this.payload.level === "M7-" ||
            // this.payload.level === "M8" ||
            // this.payload.level === "M8-" ||
            // this.payload.level === "M9" ||
            // this.payload.level === "M9-" ||
            // this.payload.level === "M10"
        ) {
            // this.labelOptions = this.labelOptionsvisit;
            if (this.getTeam("SR") && this.getSubTeam("SR")) {
                this.labelOptions = [
                    { label: "", id: "" },
                    { label: "M11 - Received CPA license", id: "M11" },
                    { label: "M11# - Yet to recieve License renewal advice from Miles", id: "M11#" },
                    { label: "M12 - Received License Renewal Advice", id: "M12" },
                    { label: "M10 - Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                    { label: "M10# - Received license advice from Miles; License application WIP", id: "M10#" },
                    { label: "M9 - Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                    { label: "M9- - Drop-out (after clearing an exam) (once every 3 months)", id: "M9-", },
                    { label: "M9+1 - Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                    { label: "M9+2 - Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                    { label: "M9+3 - New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                    { label: "M8 - Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
                    { label: "M8- - Drop-out (after evaluation) (once every 3 months)", id: "M8-", },
                    { label: "M8#1 - Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                    { label: "M8#1- - Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                    { label: "M8#1+ - Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                    { label: "M8#2 - Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                    { label: "M8+1 - Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                    { label: "M8+2 - Applied for NTS; Yet to receive NTS", id: "M8+2" },
                    { label: "M8+3 - Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                    { label: "M8+4 - Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                    { label: "M8+5 - Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                    { label: "M8+6 - Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                    { label: "M7 - INT", id: "M7-INT"},
                    { label: "M7-IR", id: "M7-IR"},
                    { label: "M7- - Drop-out (30 days)", id: "M7-" },
                    { label: "M7 - Enrolled", id: "M7" },
                    { label: "M6 - Visited & Ready to Enroll", id: "M6" },
                    { label: "M5 - Visited & Positive", id: "M5" },
                    { label: "M4 - Visited but Postponed", id: "M4" },
                    { label: "M4- - Visited but not interested", id: "M4-" },
                    { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                    { label: "M3+ - Called & Coming", id: "M3+" },
                    { label: "M3 - Called & Positive", id: "M3" },
                    { label: "M2 - Did not Visit & Postponed", id: "M2" },
                    { label: "M1 - Did not Visit & not intersted", id: "M1" },
                ];
            } else {
                if (
                    this.payload.level === "M4-" ||
                    this.payload.level === "M4" ||
                    this.payload.level === "M5" ||
                    this.payload.level === "M6" ||
                    this.payload.level === "L4-" ||
                    this.payload.level === "L4" ||
                    this.payload.level === "L5" ||
                    this.payload.level === "L6" ||
                    this.payload.level === "M7" ||
                    this.payload.level === "N/A" ||
                    this.payload.level === "DND"
                ) {
                    this.connected = true;
                    this.labelOptions = [
                        { label: "", id: "" },
                        { label: "M7 : Enrolled", id: "M7" },
                        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                        { label: "M5 : Visited & Positive", id: "M5" },
                        { label: "M4 : Visited but Postponed", id: "M4" },
                        { label: "M4- : Visited but not interested", id: "M4-" },
                        { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                        { label: "M3+ : Called & Coming", id: "M3+" },
                        { label: "M3 : Called & Positive", id: "M3" },
                        { label: "M2 : Did not Visit & Postponed", id: "M2" },
                        { label: "M1 : Did not Visit & not intersted", id: "M1" },
                    ];
                } else {
                    this.connected = false;
                    this.exceptionOption = false;
                    this.labelOptions = this.labelOptionsvisit;
                    console.log("fdfsdfsdfsd", this.labelOptions);
                }
                // this.labelOptions = [
                //     { text: "", value: "" },
                //     { text: "M7 - Enrolled", value: "M7" },
                //     { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                //     { text: "M5 - Visited & Positive", value: "M5" },
                //     { text: "M4 - Visited but Postponed", value: "M4" },
                //     { text: "M4- - Visited but not interested", value: "M4-" },
                //     { text: "M3+ - Called & Coming", value: "M3+" },
                //     { text: "M3 - Called & Positive", value: "M3" },
                //     { text: "M2 - Did not Visit & Postponed", value: "M2" },
                //     { text: "M1 - Did not Visit & not intersted", value: "M1" },
                // ];
            }
        }
        if (this.getTeam('SR')) {
            if (this.payload.level.indexOf("M10") !== -1) {
                this.connected = false;
            }
        }
        if (!this.getTeam('SR')) {
            if (this.payload.level.indexOf("M7") !== -1) {
                this.connected = false;
            }
        }
        this.course = [];
        this.course = this.payload.courses.split(",");
        this.wileyCourseArray = this.payload.courses.split(",");
        this.wileyCourseArray.forEach((wileyCourse) => {
            console.log("wiley_course", wileyCourse)
            if (wileyCourse === 'IITM-FS') {
                this.wiley_course = "IITM-FS";
            }

        });
        this.iimlLevel = this.payload.iiml_level;
        if (this.getSubTeam('IIML-FT') || this.getSubTeam('IIMI-BA') || this.getSubTeam('XLRI-HR') || this.getSubTeam('XLRI-SH') || this.getSubTeam('IIMI-AA')) {
            this.exed_level = this.payload.iiml_level;
        }
        this.navSettings(this.selected);




        this.generateEngagementInfoBadges();
        this.whatsapp_information = this.completeData.whats_app_messages;
        this.generateWhatsAppMessage();
        EventBus.$on("netEnquiryDetails", (payload) => {
            this.netEnquiryDetails = payload;
            console.log("updated");
        });
        let user_id = localStorage.getItem('user_id')
        if (user_id == 2613 || user_id == 2474) {
            this.collection_team = true
        }
    },
    components: {
    Datepicker,
    "v-select": vSelect,
    VueTimepicker,
    chatInterface,
    SaveEngagement,
    // PersonnaHideDetails,
    QueuePersonaHideDetails,
    QueuePersonaDetails,
    VisitSection,
    AcceleratorSessionPopup
},
    watch: {
        type_of_next_call(){
            this.schedule_time = {
                HH: null,
                mm: null,
            }
        },
        textAre(){
            this.generateEngagementText(this.select1);
        },
        selected_macc_level: function (value) {
            console.log("find Macc level", value);
            this.isActive = false
            this.type_of_nfd = 'u_nfd';
            // this.selectedULevel(value);
            this.freezeMAccLevels();
        },
        selected_cfa_level(val) {
            for (let i = 0; i < this.cfa_levels.length; i++) {
                const element = this.cfa_levels[i].value;
                if (this.course.indexOf(element) != -1) {
                    this.course.splice(this.course.indexOf(element), 1);
                }
            }
            if (this.course.indexOf(val) == -1) {
                this.course.push(val);
            }
        },
        selected_frm_parts(val) {
            for (let i = 0; i < this.frm_parts.length; i++) {
                const element = this.frm_parts[i].value;
                if (this.course.indexOf(element) != -1) {
                    this.course.splice(this.course.indexOf(element), 1);
                }
            }
            if (this.course.indexOf(val) == -1) {
                this.course.push(val);
            }
        },
        sendIntrocourse(value) {
            console.log(this.whatsapp_information);
            this.whatsapp_information.forEach((message_template) => {
                if (message_template.course === value) {
                    let custom_wam = message_template.message;
                    custom_wam = custom_wam.replace(
                        "{{candidate_name}}",
                        this.completeData.mwb.person_name
                    );
                    console.log("custom_wam", custom_wam);
                    this.whatsapp_message = custom_wam;
                }
            });
        },
        levels: function (value) {
            if((value == 'M1' || value == 'M2') && (this.selected_macc_level == 'U3' || this.selected_macc_level == 'U3+' || this.selected_macc_level == 'U3++')){
                this.$vs.notify({
                    title: "Not Allowed",
                    text: `You can't select the ${value} level when ${this.selected_macc_level} is chosen`,
                    color: "danger",
                });
                this.levels = this.payload.level;
            }else{
                this.isActive = true
                this.type_of_nfd = 'm_nfd';
                this.selectedMLevel(value);
            }
        },

        select1: function (value) {
            if (this.payload.iiml_level != '' || this.payload.iiml_level != null ) {
                if (this.macc_levels.find((item) => item.id === this.payload.iiml_level)) {
                    this.selected_macc_level = this.payload.iiml_level;
                }
                if (this.exed_levels.find((item) => item.id === this.payload.iiml_level)) {
                    this.exed_level = this.payload.iiml_level;
                }
                }
                if(this.payload.iiml_level == '' || this.payload.iiml_level == null){
                    this.selected_macc_level = '';
            }
            this.levels = this.payload.level;

            this.changeMLevelBasedOnULevel(this.payload.iiml_level);
            console.log(value);
            this.exceptionOption = false;
            let valuetext = value;
            if (valuetext === "Connected / Discussed" || valuetext === "Visit Confirmed" || valuetext === "Visit Completed" || valuetext === "Visit Rescheduled") {
                this.show_schedule_call = true;
                this.selected_connection_status = false;
                this.disable = false;
                this.iimlConnected = true;
                if (this.getTeam('SR')) {
                    if (this.payload.level.indexOf("M10") !== -1) {
                        this.connected = false;
                    } else {
                        this.connected = true;
                    }
                } else {
                    if (this.payload.level.indexOf("M7") !== -1) {
                        this.connected = false;
                    }
                    else if (this.payload.level.indexOf("M8") !== -1) {
                        this.connected = false;
                    }
                    else if (this.payload.level.indexOf("M9") !== -1) {
                        this.connected = false;
                    }
                    else {
                        this.connected = true;
                    }
                }
                this.connection_status = true;
                this.responseSelection = "new";
                this.textAre = "";
                this.show = true;
            }else if (valuetext === "Connected / Not Interested") {
                this.show_schedule_call = true;
                this.selected_connection_status = false;
                this.disable = false;
                this.iimlConnected = true;
                if (this.getTeam('SR')) {
                    if (this.payload.level.indexOf("M10") !== -1) {
                        this.connected = false;
                    } else {
                        this.connected = true;
                    }
                } else {
                    if (this.payload.level.indexOf("M7") !== -1) {
                        this.connected = false;
                    }
                    else if (this.payload.level.indexOf("M8") !== -1) {
                        this.connected = false;
                    }
                    else if (this.payload.level.indexOf("M9") !== -1) {
                        this.connected = false;
                    }
                    else {
                        this.connected = true;
                    }
                }
                this.connection_status = true;
                this.responseSelection = "new";
                this.textAre = "";
                this.show = true;
            }
            else if (valuetext === "") {
                this.show = false;
            } else if (valuetext === "Connected / Never call back") {
                this.show_schedule_call = true;
                this.selected_connection_status = false;
                if (this.getrole('exed2')) {
                    this.connection_status = true;
                    this.textAre = valuetext;
                    this.disable = false;
                    this.connected = false;
                    this.iimlConnected = false;
                    this.show = true;
                } else {
                    this.connection_status = true;
                    this.textAre = valuetext;
                    this.disable = true;
                    this.connected = false;
                    this.iimlConnected = false;
                    this.show = true;
                }
            }else if(valuetext === 'Communication barrier' || valuetext === 'Not Educated' || valuetext === 'Invalid Number/Wrong number' || valuetext === 'Not Educated'){
                this.dates = "";
                this.show_schedule_call = false;
                this.selected_connection_status = false;
                this.selected_macc_level = 'U0';
                if(this.payload.level > 'M6'){
                    this.levels = this.payload.level
                }else{this.levels = "M1";}
                    if (this.getrole('exed2')) {
                        this.disable = false;
                        this.connected = false;
                        this.iimlConnected = false;
                        this.textAre = value +', ';
                        this.generateEngagementText(valuetext);
                        this.show = true;
                    } else {
                        this.disable = true;
                        this.connected = false;
                        this.iimlConnected = false;
                        this.textAre = value +', ';
                        this.generateEngagementText(valuetext);
                        this.show = true;
                    }
            }else if(valuetext === 'Connected / Busy' || valuetext === 'Connected / Wrong Number'){
                this.show_schedule_call = true;
                this.selected_connection_status = false;
                    if (this.getrole('exed2')) {
                        this.disable = false;
                        this.connected = false;
                        this.iimlConnected = false;
                        this.textAre = value +', ';
                        this.generateEngagementText(valuetext);
                        this.show = true;
                    } else {
                        this.disable = true;
                        this.connected = false;
                        this.iimlConnected = false;
                        this.textAre = value +', ';
                        this.generateEngagementText(valuetext);
                        this.show = true;
                    }
            }
             else {
                this.show_schedule_call = true;
                this.selected_connection_status = true;
                if (this.getrole('exed2')) {
                    this.disable = false;
                    this.connected = false;
                    this.iimlConnected = false;
                    this.textAre = value +', ';
                    this.generateEngagementText(valuetext);
                    this.show = true;
                } else {
                    this.disable = true;
                    this.connected = false;
                    this.iimlConnected = false;
                    this.textAre = value +', ';
                    this.generateEngagementText(valuetext);
                    this.show = true;
                }
            }
            let exceptions = [
                "Not Lifting",
                "Connected / Never call back",
                "Connected / Wrong Number",
                "Switched Off",
                "Connected / Not Interested",
                "Communication barrier",
                "Not Educated",
            ];
            let exceptionOptions = {
                M11: [{ text: "M11 : Received CPA license", value: "M11" }],
                "M11#": [{ text: "M11# : Yet to recieve License renewal advice from Miles", value: "M11#" }],
                M12: [{ text: "M12 : Received License Renewal Advice", value: "M12" }],
                M10: [{ text: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", value: "M10" }],
                "M10#": [{ text: "M10# : Received license advice from Miles; License application WIP", value: "M10#" }],
                M9: [{ text: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", value: "M9" }],
                "M9-": [{ text: "M9- : Drop-out (after clearing an exam) (once every 3 months)", value: "M9-"}],
                "M9+1": [{ text: "M9+1 : Received NTS rescheduling advice from Miles for other exams", value: "M9+1" }],
                "M9+2": [{ text: "M9+2 : Received New NTS; Yet to receive approval by GP", value: "M9+2" }],
                "M9+3": [{ text: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", value: "M9+3" }],
                M8: [{ text: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", value: "M8" }],
                "M8-": [{text: "M8- : Drop-out (after evaluation) (once every 3 months)", value: "M8-"}],
                "M8#1": [{ text: "M8#1 : Enrolled:Yet to recive evaluation advice", value: "M8#1" }],
                "M8#1-": [{ text: "M8#1- : Incomplete Documents; Unable to release evaluation advice", value: "M8#1-" }],
                "M8#1+": [{ text: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", value: "M8#1+" }],
                "M8#2": [{ text: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", value: "M8#2" }],
                "M8+1": [{ text: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", value: "M8+1" }],
                "M8+2": [{ text: "M8+2 : Applied for NTS; Yet to receive NTS", value: "M8+2" }],
                "M8+3": [{ text: "M8+3 : Received NTS; Yet to receive exam scheduling advice", value: "M8+3" }],
                "M8+4": [{ text: "M8+4 : Received exam scheduling advice; Yet to schedule exam", value: "M8+4" }],
                "M8+5": [{ text: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", value: "M8+5" }],
                "M8+6": [{ text: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", value: "M8+6" }],
                M7: [{ text: "M7 : Enrolled", value: "M7" }],
                "M7-": [{ text: "M7- : Drop-out (30 days)", value: "M7-" }],
                M6: [
                    { text: "M6 : Visited & Ready to Enroll", value: "M6" },
                    { text: "M5 : Visited & Positive", value: "M5" },
                ],
                M5: [
                    { text: "M5 : Visited & Positive", value: "M5" },
                    { text: "M4 : Visited but Postponed", value: "M4" },
                ],
                M4: [
                    { text: "M4 : Visited but Postponed", value: "M4" },
                    { text: "M4- : Visited but not interested", value: "M4-" },
                ],
                "M4-": [
                    { text: "M4- : Visited but not interested", value: "M4-" },
                ],
                "M3+": [
                    { text: "M3++ : Ready to enroll - Not visited", value: "M3++" },
                    { text: "M3+ : Called & Coming", value: "M3+" },
                    { text: "M3 : Called & Positive", value: "M3" },
                ],
                M3: [
                    { text: "M3 : Called & Positive", value: "M3" },
                    { text: "M2 : Did not Visit & Postponed", value: "M2" },
                ],
                M2: [
                    { text: "M2 : Did not Visit & Postponed", value: "M2" },
                    { text: "M1 : Did not Visit & not intersted", value: "M1" },
                ],
                M1: [
                    { text: "M1 : Did not Visit & not intersted", value: "M1" },
                ],
            };
            console.log("exceptions", exceptions.indexOf(value))
            if (exceptions.indexOf(value) !== -1) {
                if (this.payload.level === "" || this.payload.level === null) {
                    this.exceptionOption = false;
                    console.log("exceptions", exceptionOptions.M3[0].text)
                    if (!this.getrole('exed2')) {
                        this.levels = "M2";
                    }
                    if (this.getrole('exed2')) {
                        this.exed_level = "Net Enquiry";
                        // this.iimlLevel = "Net Enquiry";
                    }

                } else if (this.payload.level === "M3" || this.payload.level === "M3+" || this.payload.level === "M3++" ||this.payload.level === "M2" || this.payload.level === "M1") {
                    this.labelOptions = [
                        { label: "", id: "" },
                        { label: "M7 : Enrolled", id: "M7" },
                        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                        { label: "M5 : Visited & Positive", id: "M5" },
                        { label: "M4 : Visited but Postponed", id: "M4" },
                        { label: "M4- : Visited but not interested", id: "M4-" },
                        { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                        { label: "M3+ : Called & Coming", id: "M3+" },
                        { label: "M3 : Called & Positive", id: "M3" },
                        { label: "M2 : Did not Visit & Postponed", id: "M2" },
                        { label: "M1 : Did not Visit & not intersted", id: "M1" },
                    ];
                } else {
                    if (
                        this.payload.level === "M4-" ||
                        this.payload.level === "M4" ||
                        this.payload.level === "M5" ||
                        this.payload.level === "M6" ||
                        this.payload.level === "L4-" ||
                        this.payload.level === "L4" ||
                        this.payload.level === "L5" ||
                        this.payload.level === "L6" ||
                        this.payload.level === "M7" ||
                        this.payload.level === "N/A" ||
                        this.payload.level === "DND"
                    ) {
                        this.connected = true;
                        this.labelOptions = [
                            { label: "", id: "" },
                            { label: "M7 : Enrolled", id: "M7" },
                            { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                            { label: "M5 : Visited & Positive", id: "M5" },
                            { label: "M4 : Visited but Postponed", id: "M4" },
                            { label: "M4- : Visited but not interested", id: "M4-" },
                            { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                            { label: "M3+ : Called & Coming", id: "M3+" },
                            { label: "M3 : Called & Positive", id: "M3" },
                            { label: "M2 : Did not Visit & Postponed", id: "M2" },
                            { label: "M1 : Did not Visit & not intersted", id: "M1" },
                        ];
                    } else {
                        this.connected = false;
                        this.exceptionOption = false;
                        this.labelOptions = this.labelOptionsvisit;
                        console.log("labelOptions", this.labelOptions);
                    }
                    // this.exceptionOption = true;

                    // this.labelOptions = exceptionOptions[this.payload.level];
                    // this.labelOptions = this.labelOptionsvisit;
                }
                // this.exceptionOption = true;
                // this.labelOptions = exceptionOptions[this.payload.level];
            } else {
                if (
                    this.payload.level === "M4-" ||
                    this.payload.level === "M4" ||
                    this.payload.level === "M5" ||
                    this.payload.level === "M6" ||
                    this.payload.level === "L4-" ||
                    this.payload.level === "L4" ||
                    this.payload.level === "L5" ||
                    this.payload.level === "L6" ||
                    this.payload.level === "M7"
                    // this.payload.level === "M7-" ||
                    // this.payload.level === "M8" ||
                    // this.payload.level === "M8-" ||
                    // this.payload.level === "M9" ||
                    // this.payload.level === "M9-" ||
                    // this.payload.level === "M10"
                ) {
                    // this.labelOptions = this.labelOptionsvisit;
                    if (this.getTeam("SR") && this.getSubTeam("SR")) {
                        this.labelOptions = [
                            { label: "", id: "" },
                            { label: "M11 : Received CPA license", id: "M11" },
                            { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                            { label: "M12 : Received License Renewal Advice", id: "M12" },
                            { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                            { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                            { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                            { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-" },
                            { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                            { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                            { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                            { label: "M8 : Evaluation (30 days)", id: "M8" },
                            { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-",},
                            { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                            { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                            { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                            { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                            { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                            { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                            { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                            { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                            { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                            { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                            { label: "M7 - INT", id: "M7-INT"},
                            { label: "M7-IR", id: "M7-IR"},
                            { label: "M7- : Drop-out (30 days)", id: "M7-" },
                            { label: "M7 : Enrolled", id: "M7" },
                            { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                            { label: "M5 : Visited & Positive", id: "M5" },
                            { label: "M4 : Visited but Postponed", id: "M4" },
                            { label: "M4- : Visited but not interested", id: "M4-" },
                            { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                            { label: "M3+ : Called & Coming", id: "M3+" },
                            { label: "M3 : Called & Positive", id: "M3" },
                            { label: "M2 : Did not Visit & Postponed", id: "M2" },
                            { label: "M1 : Did not Visit & not intersted", id: "M1"},
                        ];
                    } else {
                        // this.labelOptions = this.labelOptionsvisit;
                        if (this.payload.level === "" || this.payload.level === null) {
                            this.exceptionOption = false;
                            console.log("exceptions", exceptionOptions.M3[0].text)
                            if (!this.getrole('exed2')) {
                                this.levels = "M2";
                            }
                            if (this.getrole('exed2')) {
                                this.exed_level = "Net Enquiry";
                                // this.iimlLevel = "Net Enquiry";
                            }
                        } else if (this.payload.level === "M3" || this.payload.level === "M3+" || this.payload.level === "M3++" || this.payload.level === "M2" || this.payload.level === "M1") {
                            this.labelOptions = [
                                { label: "", id: "" },
                                { label: "M7 : Enrolled", id: "M7" },
                                { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                                { label: "M5 : Visited & Positive", id: "M5" },
                                { label: "M4 : Visited but Postponed", id: "M4" },
                                { label: "M4- : Visited but not interested", id: "M4-" },
                                { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                                { label: "M3+ : Called & Coming", id: "M3+" },
                                { label: "M3 : Called & Positive", id: "M3" },
                                { label: "M2 : Did not Visit & Postponed", id: "M2" },
                                { label: "M1 : Did not Visit & not intersted", id: "M1" },
                            ];
                        } else {
                            if (
                                this.payload.level === "M4-" ||
                                this.payload.level === "M4" ||
                                this.payload.level === "M5" ||
                                this.payload.level === "M6" ||
                                this.payload.level === "L4-" ||
                                this.payload.level === "L4" ||
                                this.payload.level === "L5" ||
                                this.payload.level === "L6" ||
                                this.payload.level === "M7" ||
                                this.payload.level === "N/A" ||
                                this.payload.level === "DND"
                            ) {
                                this.connected = true;
                                this.labelOptions = [
                                    { label: "", id: "" },
                                    { label: "M7 : Enrolled", id: "M7" },
                                    { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                                    { label: "M5 : Visited & Positive", id: "M5" },
                                    { label: "M4 : Visited but Postponed", id: "M4" },
                                    { label: "M4- : Visited but not interested", id: "M4-" },
                                    { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                                    { label: "M3+ : Called & Coming", id: "M3+" },
                                    { label: "M3 : Called & Positive", id: "M3" },
                                    { label: "M2 : Did not Visit & Postponed", id: "M2" },
                                    { label: "M1 : Did not Visit & not intersted", id: "M1" },
                                ];
                            } else {
                                this.connected = false;
                                this.exceptionOption = false;
                                this.labelOptions = this.labelOptionsvisit;
                                console.log("fdfsdfsdfsd", this.labelOptions);
                            }
                            // this.exceptionOption = true;

                            // this.labelOptions = exceptionOptions[this.payload.level];
                            // this.labelOptions = this.labelOptionsvisit;
                        }
                        // this.labelOptions = [
                        //     { text: "", value: "" },
                        //     { text: "M7 - Enrolled", value: "M7" },
                        //     { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                        //     { text: "M5 - Visited & Positive", value: "M5" },
                        //     { text: "M4 - Visited but Postponed", value: "M4" },
                        //     { text: "M4- - Visited but not interested", value: "M4-" },
                        //     { text: "M3+ - Called & Coming", value: "M3+" },
                        //     { text: "M3 - Called & Positive", value: "M3" },
                        //     {
                        //         text: "M2 - Did not Visit & Postponed",
                        //         value: "M2",
                        //     },
                        //     {
                        //         text: "M1 - Did not Visit & not intersted",
                        //         value: "M1",
                        //     },
                        // ];
                    }
                } else {
                    if (this.getTeam("SR") && this.getSubTeam("SR")) {
                        this.labelOptions = [
                            { label: "", id: "" },
                            { label: "M11 : Received CPA license", id: "M11" },
                            { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                            { label: "M12 : Received License Renewal Advice", id: "M12" },
                            { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                            { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                            { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                            { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-" },
                            { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                            { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                            { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                            { label: "M8 : Evaluation (30 days)", id: "M8" },
                            { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-" },
                            { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                            { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                            { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                            { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                            { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                            { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                            { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                            { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                            { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                            { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                            { label: "M7 - INT", id: "M7-INT"},
                            { label: "M7-IR", id: "M7-IR"},
                            { label: "M7- : Drop-out (30 days)", id: "M7-" },
                            { label: "M7 : Enrolled", id: "M7" },
                            { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                            { label: "M5 : Visited & Positive", id: "M5" },
                            { label: "M4 : Visited but Postponed", id: "M4" },
                            { label: "M4- : Visited but not interested", id: "M4-" },
                            { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                            { label: "M3+ : Called & Coming", id: "M3+" },
                            { label: "M3 : Called & Positive", id: "M3" },
                            { label: "M2 : Did not Visit & Postponed", id: "M2" },
                            { label: "M1 : Did not Visit & not intersted", id: "M1" },
                        ];
                    } else {
                        // this.labelOptions = this.labelOptionsvisit;
                        if (this.payload.level === "" || this.payload.level === null) {
                            this.exceptionOption = false;
                            console.log("exceptions", exceptionOptions.M3[0].text)
                            if (!this.getrole('exed2')) {
                                this.levels = "M2";
                            }
                            if (this.getrole('exed2')) {
                                // this.iimlLevel = "Net Enquiry";
                                this.exed_level = "Net Enquiry";
                            }
                        } else if (this.payload.level === "M3" || this.payload.level === "M3+" || this.payload.level === "M3++"|| this.payload.level === "M2" || this.payload.level === "M1") {
                            this.labelOptions = [
                                { label: "", id: "" },
                                { label: "M7 : Enrolled", id: "M7" },
                                { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                                { label: "M5 : Visited & Positive", id: "M5" },
                                { label: "M4 : Visited but Postponed", id: "M4" },
                                { label: "M4- : Visited but not interested", id: "M4-" },
                                { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                                { label: "M3+ : Called & Coming", id: "M3+" },
                                { label: "M3 : Called & Positive", id: "M3" },
                                { label: "M2 : Did not Visit & Postponed", id: "M2" },
                                { label: "M1 : Did not Visit & not intersted", id: "M1" },
                            ];
                        } else {
                            if (
                                this.payload.level === "M4-" ||
                                this.payload.level === "M4" ||
                                this.payload.level === "M5" ||
                                this.payload.level === "M6" ||
                                this.payload.level === "L4-" ||
                                this.payload.level === "L4" ||
                                this.payload.level === "L5" ||
                                this.payload.level === "L6" ||
                                this.payload.level === "M7" ||
                                this.payload.level === "N/A" ||
                                this.payload.level === "DND"
                            ) {
                                this.connected = true;
                                this.labelOptions = [
                                    { label: "", id: "" },
                                    { label: "M7 : Enrolled", id: "M7" },
                                    { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                                    { label: "M5 : Visited & Positive", id: "M5" },
                                    { label: "M4 : Visited but Postponed", id: "M4" },
                                    { label: "M4- : Visited but not interested", id: "M4-" },
                                    { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                                    { label: "M3+ : Called & Coming", id: "M3+" },
                                    { label: "M3 : Called & Positive", id: "M3" },
                                    { label: "M2 : Did not Visit & Postponed", id: "M2" },
                                    { label: "M1 : Did not Visit & not intersted", id: "M1" },
                                ];
                            } else {
                                this.connected = false;
                                this.exceptionOption = false;
                                this.labelOptions = this.labelOptionsvisit;
                            }
                            // this.exceptionOption = true;

                            // this.labelOptions = exceptionOptions[this.payload.level];
                            // this.labelOptions = this.labelOptionsvisit;
                        }
                        // this.labelOptions = [
                        //     { text: "", value: "" },
                        //     { text: "M7 - Enrolled", value: "M7" },
                        //     { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                        //     { text: "M5 - Visited & Positive", value: "M5" },
                        //     { text: "M4 - Visited but Postponed", value: "M4" },
                        //     { text: "M4- - Visited but not interested", value: "M4-" },
                        //     { text: "M3+ - Called & Coming", value: "M3+" },
                        //     { text: "M3 - Called & Positive", value: "M3" },
                        //     {
                        //         text: "M2 - Did not Visit & Postponed",
                        //         value: "M2",
                        //     },
                        //     {
                        //         text: "M1 - Did not Visit & not intersted",
                        //         value: "M1",
                        //     },
                        // ];
                    }
                }
            }
            if (this.course.includes("IIML-FT") && !this.course.includes("IIML-SF")) {
                this.M6LevelOptions();
                console.log("condition", 1, -2)
            } else if (!this.course.includes("IIML-FT") && this.course.includes("IIML-SF")) {
                this.M6LevelOptions();
                console.log("condition", -1, 2)
            } else if (this.course.includes("IIML-FT") && this.course.includes("IIML-SF")) {
                this.M6LevelOptions();
                console.log("condition", 1, 2)
            } else if (!this.course.includes("IIML-FT") && !this.course.includes("IIML-SF")) {
                this.levelOptions();
                console.log("condition", -1, -2)
            }

        },
        // exed_level: function (value) {
        //     if (this.getrole("exed2")) {
        //         console.log("value", value)
        //         let limit = 180;
        //         let suggested = 180;
        //         const nfds = {
        //             "IIML-FA-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIML-SF-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIML-FT-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIML-BA-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             // "RPO-M7": {
        //             //     limit: 7,
        //             //     suggested: 7,
        //             // },
        //             "IIML-PM-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIML-HR-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IITR-BF-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IITR-DB-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IITM-AA-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIMK-CX-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IITM-FS-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IITR-CC-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIMK-FT-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIML-AB-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIML-SH-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IITJ-DE-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "XLRI-HR-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "XLRI-SH-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "IIMI-BA-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //              "IIMI-AA-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "XLRI-DM-M7": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Cold": {
        //                 limit: 0,
        //                 suggested: 0,
        //             },
        //             "Exam-Pass-Dropout": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Application Received": {
        //                 limit: 5,
        //                 suggested: 2,
        //             },
        //             "Application Approved": {
        //                 limit: 5,
        //                 suggested: 2,
        //             },
        //             "Enrolled": {
        //                 limit: 365,
        //                 suggested: 7,
        //             },
        //             "Final payment Received": {
        //                 limit: 5,
        //                 suggested: 2,
        //             },
        //             "NA": {
        //                 limit: 0,
        //                 suggested: 0,
        //             },
        //             "Exam-Pass": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Very Hot": {
        //                 limit: 3,
        //                 suggested: 2,
        //             },
        //             Warm: {
        //                 limit: 3,
        //                 suggested: 1,
        //             },
        //             "Hot": {
        //                 limit: 3,
        //                 suggested: 2,
        //             },
        //             "Exam-Fail": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Net Enquiry": {
        //                 limit: 1,
        //                 suggested: 1,
        //             },
        //             "Busy/NL": {
        //                 limit: 5,
        //                 suggested: 2,
        //             },
        //             "Enquiry": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             // "CM-Yet-To-Connect": {
        //             //     limit: 7,
        //             //     suggested: 7,
        //             // },
        //             "Exam-Fail-Dropout": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Exam-Noshow-Dropout": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Lead-DB": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //             "Lead-IR": {
        //                 limit: 7,
        //                 suggested: 7,
        //             },
        //         };
        //         console.log("limit", nfds[value])
        //         limit = nfds[value].limit;

        //         suggested = nfds[value].suggested;
        //         this.disabledDates.from = new Date(
        //             new Date().getFullYear(),
        //             new Date().getMonth(),
        //             new Date().getDate() + limit
        //         );
        //         this.disabledDates.to = new Date(
        //             new Date().getFullYear(),
        //             new Date().getMonth(),
        //             new Date().getDate()
        //         );
        //         this.dates = new Date(
        //             new Date(
        //                 new Date().getFullYear(),
        //                 new Date().getMonth(),
        //                 new Date().getDate() + suggested
        //             )
        //         );
        //         if (value === "NA") {
        //             this.showDatepicker = false;
        //             this.dates = null;
        //         } else {
        //             this.showDatepicker = true;
        //         }
        //         if (this.getSubTeam("IIML-FT") || this.getSubTeam('XLRI-HR') || this.getSubTeam('IIMI-BA') || this.getSubTeam('XLRI-SH') || this.getSubTeam('IIMI-AA')) {
        //             if (value == "Cold" || value == 'Enrolled') {
        //                 this.dates = null;
        //             }
        //         }
        //     }

        // },
        responseSelection: function (val) {
            switch (val) {
                case "sameResponse":
                    this.textAre = this.payload.engagement_details;
                    break;
                case "noResponse":
                    this.textAre = "Not Reachable";
                    break;
                case "new":
                    this.textAre = "";
                    break;
            }
        },
        selected_template(value) {
            console.log("selected tamplate", value);
            this.whatsapp_message = value.message;
        },
        course: function (val) {
            if (val.includes('CFA')) {
                this.show_cfa_dropdown = true;
            }
            if (!val.includes('CFA')) {
                this.show_cfa_dropdown = false;
                for (let i = 0; i < this.course.length; i++) {
                    const element = this.course[i];
                    for (let i = 0; i < this.cfa_levels.length; i++) {
                        const level = this.cfa_levels[i].value;
                        if (level === element) {
                            this.course.splice(this.course.indexOf(element), 1);
                        }
                    }
                }
            }
            if (val.includes('FRM')) {
                this.show_frm_dropdown = true;
            }
            if (!val.includes('FRM')) {
                this.show_frm_dropdown = false;
                for (let i = 0; i < this.course.length; i++) {
                    const element = this.course[i];
                    for (let i = 0; i < this.frm_parts.length; i++) {
                        const level = this.frm_parts[i].value;
                        if (level === element) {
                            this.course.splice(this.course.indexOf(element), 1);
                        }
                    }
                }
            }
            if (val.includes("IIML-FT") && !val.includes("IIML-SF")) {
                this.M6LevelOptions();
                console.log("condition", 1, -2)
            } else if (!val.includes("IIML-FT") && val.includes("IIML-SF")) {
                this.M6LevelOptions();
                console.log("condition", -1, 2)
            } else if (val.includes("IIML-FT") && val.includes("IIML-SF")) {
                this.M6LevelOptions();
                console.log("condition", 1, 2)
            } else if (!val.includes("IIML-FT") && !val.includes("IIML-SF")) {
                this.levelOptions();
                console.log("condition", -1, -2)
            }
            this.send_intro_options = val;
            this.webinar_options = val;
        },
        type_of_nfd(val){
            if(val == 'm_nfd'){
                this.selectedMLevel(this.levels);
            }
            else if(val == 'u_nfd'){
                this.isActive = false
                // this.selectedULevel(this.selected_macc_level);
            }
        },
    },
    data() {
        return {
            show_mhp_source: true,
            schedule_status:false,
            visit_found: 0,
            upcoming_visits_date: "",
            onlygooglemeetbutton: false,
            mwb_courses: [],
            type_of_nfd: 'm_nfd',
            isActive: true,
            show_schedule_call: false,
            loan_status:false,
            level_verify: false,
            collection_team: false,
            selected_connection_status: false,
            hide_select: true,
            selected_macc_level: "",
            type_of_next_call: "phone",
            schedule_call_popup: false,
            schedule_time: {
                HH: null,
                mm: null,
            },
            date_time: null,
            scheduled_call: false,
            // macc_levels: [
            //     { value: "U0",  text: "U0 : Not interested in US/Canda"},
            //     { value: "U1",  text: "U1 : Interest expressed, dropped out"},
            //     { value: "U2",  text: "U2 : Interest expressed, in pipeline (warm)"},
            //     { value: "U3-",  text: "U3- : Interested but yet to complete Graduation"},
            //     { value: "U3",  text: "U3 : Interest expressed, in pipeline (hot)"},
            //     { value: "U3+",  text: "U3+ : Interest expressed, in pipeline (Very Hot)"},
            //     { value: "U4-",  text: "U4- : Application initiated, dropped out"},
            //     { value: "U4",  text: "U4 : Application Initiated"},
            //     { value: "U4R",  text: "U4R : Refunded 20k"},
            //     { value: "U5",  text: "U5 : Application done, I-20 yet to be received"},
            //     { value: "U5+",  text: "U5+ : Conditional Offer Received"},
            //     { value: "U6-",  text: "U6- : I-20 received, Dropped Out"},
            //     { value: "U6",  text: "U6 : I-20 received, Yet to apply for Visa"},
            //     { value: "U6+",  text: "U6+ : I-20 received, Applied for Visa"},
            //     { value: "U7-",  text: "U7- : Visa Received But Dropped out"},
            //     { value: "U7",  text: "U7 : Visa received"},
            //     { value: "U8",  text: "U8 : JAGSOM enrolled"},
            //     { value: "U9",  text: "U9 : Started program in USA"},
            //     { value: "U10",  text: "U10 : Completed program in USA"},
            // ],
            check_courses: [],
            show_cfa_dropdown: false,
            show_frm_dropdown: false,
            selected_cfa_level: "",
            selected_frm_parts: "",
            cfa_levels: [
                { text: "CFA-L1", value: "CFA-L1" },
                { text: "CFA-L2", value: "CFA-L2" },
                { text: "CFA-L3", value: "CFA-L3" },
            ],
            frm_parts: [
                { text: "FRM-P1", value: "FRM-P1" },
                { text: "FRM-P2", value: "FRM-P2" },
            ],
            PrimaryMobile: "",
            PrimaryEmail: "",
            WlieyPersonName: "",
            SelectedCOurse: "",
            popupOpen: false,
            text: "",
            wileyCourseArray: [],
            wiley_course: "",
            registeringforwebinar: "",
            webinar_options: [],
            webinarIntro: "",
            sendIntrocourse: "",
            send_intro_options: [],
            whatsapp_information: [],
            webinarCourse: "",
            exceptionOption: false,
            phone_number: `91${this.completeData.mobiles[0].last_ten_digits}`,
            // phone_number: ``,
            whatsapp_message: "",
            escalation_boolean: false,
            iimlConnected: false,
            escalation_acads_member: "",
            escaltionOptions: [
                { text: "", value: "" },
                { text: "Samiya Zafar", value: 114 },
            ],
            second_visit: false,
            second_visit_select: "",
            secondvisitOptions: [
                { text: "", value: "" },
                { text: "Samiya Zafar", value: 114 },
            ],
            buttonText: "SAVE AND NEXT",
            netEnquiryDetails: "",
            // arr: [
            //     {
            //         name: "Connected, Busy",
            //         value: 0,
            //         form: "CB",
            //     },
            //     {
            //         name: "Connected, Never call back",
            //         value: 0,
            //         form: "CN",
            //     },
            //     {
            //         name: "Connected, Wrong Number",
            //         value: 0,
            //         form: "CW",
            //     },
            //     {
            //         name: "Busy",
            //         value: 0,
            //         form: "B",
            //     },
            //     {
            //         name: "Not Lifting",
            //         value: 0,
            //         form: "NL",
            //     },
            //     {
            //         name: "Not Reachable",
            //         value: 0,
            //         form: "NR",
            //     },
            //     {
            //         name: "Disconnected",
            //         value: 0,
            //         form: "D",
            //     },
            //     {
            //         name: "Invalid Number",
            //         value: 0,
            //         form: "IN",
            //     },
            //     {
            //         name: "Switched Off",
            //         value: 0,
            //         form: "SO",
            //     },
            //     {
            //         name: "Connected, Not Interested",
            //         value: 0,
            //         form: "CNI",
            //     },
            //     {
            //         name: "Communication barrier",
            //         value: 0,
            //         form: "CMB",
            //     },
            //     {
            //         name: "Not Educated",
            //         value: 0,
            //         form: "NE",
            //     },
            // ],
            arr: [
                {
                name: "Visit Confirmed",
                value: 0,
                form: "VCNF",
                },
                {
                name: "Visit Completed",
                value: 0,
                form: "VCMP",
                },
                {
                name: "Visit Rescheduled",
                value: 0,
                form: "VRE",
                },
                {
                name: "Visit Cancelled",
                value: 0,
                form: "VCNL",
                },
                {
                name: "Not Connected(NL/SO/NR)",
                value: 0,
                form: "NC",
                },
                {
                name: "Booked Visit By Mistake",
                value: 0,
                form: "BVBM",
                },
                {
                name: "Invalid Number/Wrong number",
                value: 0,
                form: "INWN",
                },
                {
                name: "Connected, Not Interested",
                value: 0,
                form: "CNI",
                },
                {
                name: "Communication barrier",
                value: 0,
                form: "CMB",
                },
                {
                name: "Not Educated",
                value: 0,
                form: "NE",
                },
                {
                name: "Connected/ Call Back",
                value: 0,
                form: "CCB",
                },
            ],
            indexActive: 0,
            disabledDates: {
                to: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 2,
                    5
                ),
                from: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    26
                ),
            },
            connected: false,
            connected_status: ["Connected / Discussed", "Connected / Not Interested", "Visit Confirmed", "Visit Completed", "Visit Rescheduled"],
            show: false,
            disable: true,
            level_options: [
                { label: "M1 : Did not Visit & not intersted", id: "M1", level_priority: 1 },
                { label: "M2 : Did not Visit & Postponed", id: "M2", level_priority: 1 },
                { label: "M3 : Called & Positive", id: "M3", level_priority: 1 },
                { label: "M3+ : Called & Coming", id: "M3+", level_priority: 1 },
                { label: "M3++ - Ready to enroll - Not visited", id: "M3++", level_priority: 1 },
                { label: "M4 : Visited but Postponed", id: "M4", level_priority: 2 },
                { label: "M4- : Visited but not interested", id: "M4-", level_priority: 2 },
                { label: "M5 : Visited & Positive", id: "M5", level_priority: 2 },
                { label: "M6  : Visited & Ready to Enroll", id: "M6", level_priority: 2 },
                { label: "M6 AR : Visited & Ready to Enroll, Application Received", id: "M6 AR", level_priority: 2 },
                { label: "M6 AR- : Visited & Ready to Enroll, Application Received - Drop Out", id: "M6 AR-", level_priority: 2 },
                { label: "M7 : Enrolled", id: "M7", level_priority: 3 },
                { label: "M7D1 : Defaulter", id: "M7D1", level_priority: 10 },
                { label: "M7D2 : Loan Defaulter", id: "M7D2", level_priority: 10 },
                { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ", level_priority: 4 },
                { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X", level_priority: 4 },
                { label: "M7- : Drop-out (30 days)", id: "M7-", level_priority: 4 },
                { label: "M7+ LMS demo completed (30-60 days)", id: "M7+", level_priority: 5 },
                { label: "M7# : Onboarding Done (30-60 days)", id: "M7#", level_priority: 5 },
                { label: "M8 : Evaluation (30 days)", id: "M8", level_priority: 5 },
                { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+", level_priority: 6 },
                { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-", level_priority: 7 },
                { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9", level_priority: 8 },
                { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-", level_priority: 8 },
                { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                { label: "M11 : Received CPA license", id: "M11" },
                { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                { label: "M12 : Received License Renewal Advice", id: "M12" },
                { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10", level_priority: 9 },
                { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                { label: "M7-IR  ", id: "M7-IR", level_priority: 11 },
                { label: "M7-INT  ", id: "M7-INT", level_priority: 10 },
            ],
            // options1: [
            //     { text: "", value: 1 },
            //     { text: "Connected / Discussed", value: 2 },
            //     { text: "Connected / Busy", value: 3 },
            //     { text: "Connected / Never call back", value: 4 },
            //     { text: "Connected / Wrong Number", value: 4 },
            //     { text: "Connected / Not Interested", value: 11 },
            //     { text: "Busy", value: 5 },
            //     { text: "Not Lifting", value: 6 },
            //     { text: "Not Reachable", value: 7 },
            //     { text: "Disconnected", value: 8 },
            //     { text: "Invalid Number", value: 9 },
            //     { text: "Switched Off", value: 10 },
            //     { text: "Communication barrier", value: 11 },
            //     { text: "Not Educated", value: 12 },
            // ],
            options1: [
                // { text: "", value: 1 },
                { text: "Visit Confirmed", value: 2 },
                { text: "Visit Completed", value: 3 },
                { text: "Visit Rescheduled", value: 4 },
                { text: "Visit Cancelled", value: 5 },
                { text: "Booked Visit By Mistake", value: 6 },
                { text: "Communication barrier", value: 7 },
                { text: "Not Educated", value: 8 },
                { text: "Not Connected(NL/SO/NR)", value: 9 },
                { text: "Connected / Not Interested", value: 10 },
                { text: "Invalid Number/Wrong number", value: 11 },
                { text: "Connected/ Call Back", value: 12 },
            ],
            dates: "",
            course: [],
            levels: "",
            textAre: "",
            phone_number_toupdate: "",
            responseSelection: "",
            select1: "",
            latestEngagement: "",
            navTitleName: "",
            allpage: true,
            visitpage: false,
            callpage: false,
            whatsapp_page: false,
            emailpage: false,
            lastengagement: "",
            details: "",
            labelOptionsvisit: [
                { text: "", value: "" },
                // { text: "M10 - Passed all 4 exams; Yet to receive license advice from Miles", value: "M10" },
                // { text: "M9 - Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams value: "M9" },
                // {
                //     text:
                //         "M9- - Drop-out (after clearing an exam) (once every 3 months)",
                //     value: "M9-",
                // },
                // { text: "M8 - Sent docs to evaluation agency; Yet to receive the evaluation report", value: "M8" },
                // {
                //     text:
                //         "M8- - Drop-out (after evaluation) (once every 3 months)",
                //     value: "M8-",
                // },
                // { text: "M7- - Drop-out (30 days)", value: "M7-" },
                { text: "M7 : Enrolled", value: "M7" },
                { text: "M6 : Visited & Ready to Enroll", value: "M6" },
                { text: "M5 : Visited & Positive", value: "M5" },
                { text: "M4 : Visited but Postponed", value: "M4" },
                { text: "M4- : Visited but not interested", value: "M4-" },
            ],
            labelOptions: [
                { label: "", id: "" },
                { label: "M11 : Received CPA license", id: "M11" },
                { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                { label: "M12 : Received License Renewal Advice", id: "M12" },
                { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-" },
                { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                { label: "M8 : Evaluation (30 days)", id: "M8" },
                { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
                { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-" },
                { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X" },
                { label: "M7D2 : Loan Defaulter", id: "M7D2" },
                { label: "M7D1 : Defaulter", id: "M7D1" },
                { label: "M7+ LMS demo completed (30-60 days)", id: "M7+" },
                { label: "M7- : Drop-out (30 days)", id: "M7-" },
                { label: "M7# : Onboarding Done (30-60 days)", id: "M7#" },
                { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
                { label: "M7 : Enrolled", id: "M7" },
                { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                { label: "M5 : Visited & Positive", id: "M5" },
                { label: "M4 : Visited but Postponed", id: "M4" },
                { label: "M4- : Visited but not interested", id: "M4-" },
                { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                { label: "M3+ : Called & Coming", id: "M3+" },
                { label: "M3 : Called & Positive", id: "M3" },
                { label: "M2 : Did not Visit & Postponed", id: "M2" },
                { label: "M1 : Did not Visit & not intersted", id: "M1" },
            ],
            typeSelction: "",
            courseSelection: "",
            connection_status: false,
            latestDate: "",
            enrollment: false,
            iimlLevel: "",
            macc_levels: [
                // { value: "",  text: ""},
                { id: "U0--", label: "U0-- : Not Eligible Lead", level_priority: 1 },
                { id: "U0", label: "U0 : Not interested in US/Canda", level_priority: 1 },
                { id: "U1", label: "U1 : Interest expressed, dropped out", level_priority: 1 },
                { id: "U1+", label: "U1+ : Interest expressed, yet to graduate", level_priority: 1 },
                { id: "U2", label: "U2 : Interest expressed, in pipeline (warm)", level_priority: 1 },
                { id: "U3-", label: "U3- : Postponed", level_priority: 1 },
                { id: "U3", label: "U3 : Interest expressed, in pipeline (hot)", level_priority: 1 },
                { id: "U3+", label: "U3+ : Zoom Attended(Very Hot)", level_priority: 1 },
                { id: "U3++", label: "U3++ : Ready to Enroll", level_priority: 1 },
                { id: "U4", label: "U4 : Visited but Postpone/Not Interested", level_priority: 2 },
                { id: "U5", label: "U5 : Visited and Very Interested", level_priority: 2 },
                { id: "U6", label: "U6 : Visited and Ready to Enroll", level_priority: 2 },
                { id: "U7-", label: "U7- : Application initiated, dropped out", level_priority: 3 },
                { id: "U7R", label: "U7R : Refunded 20k", level_priority: 3 },
                { id: "U7", label: "U7 : Enrolled (20k)", level_priority: 3 },
                { id: "U7+", label: "U7+ : Application Initiated", level_priority: 3 },
                { id: "U8", label: "U8 : Application done, I-20 yet to be received", level_priority: 4 },
                { id: "U8+", label: "U8+ : Conditional Offer Received", level_priority: 4 },
                { id: "U9-", label: "U9- : I-20 received, Dropped Out", level_priority: 4 },
                { id: "U9", label: "U9 : I 20 Rec. Yet to apply for Visa", level_priority: 4 },
                { id: "U9+", label: "U9+ : I 20 Rec. Applied for Visa", level_priority: 4 },
                { id: "U10-", label: "U10- : Visa Received But Dropped out", level_priority: 4 },
                { id: "U10", label: "U10 : Visa received", level_priority: 4 },
                { id: "U11", label: "U11 : IIM Indore", level_priority: 4 },
                { id: "U11+", label: "U11+ : Started program in USA", level_priority: 4 },
                { id: "U12", label: "U12 : Completed program in USA", level_priority: 4 },
            ],
            iimlLevels: [
                { text: "R1 : Not Interested", value: "R1" },
                { text: "R2: Interested but Postponed", value: "R2" },
                { text: "R2- : Untapped Not Lifting", value: "R2-" },
                { text: "R3- : Net Enquiry Not Lifting", value: "R3-" },
                { text: "R3 : Interested", value: "R3" },
                { text: "R3+ : Received CV", value: "R3+" },
                { text: "R4 : Submitted documents and Postponed", value: "R4" },
                { text: "R5 : Shortlisted", value: "R5" },
                { text: "R5- : Profile Rejected", value: "R5-" },
                { text: "R6 : Assessment/Interview Cleared", value: "R6" },
                { text: "R6- : Assessment/Interview Rejected", value: "R6-" },
                { text: "R7 : Offer letter received", value: "R7" },
                { text: "R7+ : Documents BGV initiated", value: "R7+" },
                { text: "R7- : Backout", value: "R7-" },
                { text: "R8 : Placed/On boarded", value: "R8" },
                { text: "R8+ : Due to Bill", value: "R8+" },
                { text: "R8- : Replacement", value: "R8-" },
                { text: "RX : Never to Call", value: "RX" },
                // { text: "Cold", value: "Cold" },
                // { text: "Hot", value: "Hot" },
                // { text: "Warm", value: "Warm" },
                // { text: "Very Hot", value: "Very Hot" },
                // // { text: "", value: "" },
                // { text: "Application Received", value: "Application Received" },
                // { text: "Exam-Pass", value: "Exam-Pass" },
                // { text: "Exam-Fail", value: "Exam-Fail" },
                // { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
                // { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
                // { text: "Exam-Noshow-Dropout", value: "Exam-Noshow-Dropout" },
                // // { text: "Enquiry", value: "Enquiry" },
                // { text: "RPO-M7", value: "RPO-M7" },
                // { text: "IIML-FA-M7", value: "IIML-FA-M7" },
                // { text: "IIML-SF-M7", value: "IIML-SF-M7" },
                // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
                // { text: "IIML-BA-M7", value: "IIML-BA-M7" },
                // { text: "IIML-PM-M7", value: "IIML-PM-M7" },
                // { text: "IIML-HR-M7", value: "IIML-HR-M7" },
                // { text: "IITR-BF-M7", value: "IITR-BF-M7" },
                // { text: "IITR-DB-M7", value: "IITR-DB-M7" },
                // { text: "IITM-AA-M7", value: "IITM-AA-M7" },
                // { text: "IIMK-CX-M7", value: "IIMK-CX-M7" },
                // { text: "IITM-FS-M7", value: "IITM-FS-M7" },
                // { text: "IITR-CC-M7", value: "IITR-CC-M7" },
                // { text: "IIMK-FT-M7", value: "IIMK-FT-M7" },
                // { text: "IIML-AB-M7", value: "IIML-AB-M7" },
                // { text: "IIML-SH-M7", value: "IIML-SH-M7" },
                // { text: "IITJ-DE-M7", value: "IITJ-DE-M7" },
                // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },
                // { text: "XLRI-DM-M7", value: "XLRI-DM-M7" },
                // { text: "CM-Yet-To-Connect", value: "CM-Yet-To-Connect" },
            ],
            exed_levels: [
                { label: "Cold", id: "Cold" },
                { label: "Hot", id: "Hot" },
                { label: "Warm", id: "Warm" },
                { label: "Very Hot", id: "Very Hot" },
                { label: "Application Received", id: "Application Received" },
                // { label: "Exam-Pass", id: "Exam-Pass" },
                // { label: "Exam-Fail", id: "Exam-Fail" },
                // { label: "Exam-Pass-Dropout", id: "Exam-Pass-Dropout" },
                // { label: "Exam-Fail-Dropout", id: "Exam-Fail-Dropout" },
                // { label: "Exam-Noshow-Dropout", id: "Exam-Noshow-Dropout" },
                // { label: "IIML-FT-M7", id: "IIML-FT-M7" },
                // { label: "IIMI-BA-M7", id: "IIMI-BA-M7" },
                // { label: "XLRI-HR-M7", id: "XLRI-HR-M7" },
                { label: "Net Enquiry", id: "Net Enquiry" },
                { label: "Busy/NL", id: "Busy/NL" },
                { label: "Application Approved", id: "Application Approved" },
                { label: "Enrolled", id: "Enrolled" },
                { label: "Final payment Received", id: "Final payment Received" },
                { label: "NA", id: "NA" },
            ],
            exed_level: "",
            whatsapp_options: [
                {
                    course: "CPA",
                    name: "CPA Game Plan",
                    message: "",
                },
                {
                    course: "CPA-Message-2",
                    name: "CPA Webinar",
                    message: "",
                },
                {
                    course: "CMA",
                    name: "CMA Game Plan",
                    message: "",
                },
                {
                    course: "CMA-Webinar",
                    name: "CMA Webinar",
                    message: "",
                },
                {
                    course: "IIML-FA",
                    name: "IIML-FA Message",
                    message: "",
                },
                {
                    course: "IIML-FT",
                    name: "IIML-FT Message",
                    message: "",
                },
                {
                    course: "IIML-SF",
                    name: "IIML-SF Message",
                    message: "",
                },
            ],
            selected_template: {},
            save_loading: false,
            save_and_whatsapp_loading: false,
            all_loading: false,
            persona_details: {},
            personna_data: false,
            old_persona_data: {},
            new_persona_data: {},
            persona: "",
            pathway: "",
            level_priority_condition: false,
            level_defaulter_condition:false,
            defaulter_level:false,
        };
    },
    methods: {
        stopLoading() {
            this.$vs.loading.close(this.$refs.loadableButton.$el);
        },
        scheduleCall() {
        console.log("date_sc--", this.dates);
        console.log("schedule_time--", this.schedule_time);

        if (
            this.schedule_time.HH != null &&
            this.schedule_time.mm != null &&
            this.schedule_time.HH != "" &&
            this.schedule_time.mm != ""
        ) {
            let schedule_date = moment(this.dates, "DD-MM-YYYY").format(
            "DD-MMM-YYYY"
            );
            console.log("scheduleDate--", schedule_date);
            let hours_minutes = this.schedule_time.HH.concat(
            ":",
            this.schedule_time.mm
            );
            this.date_time = schedule_date + " " + hours_minutes;

            console.log("scheduleDateTime--", this.date_time);

            // let momentData = new Date(date_time).getTime() / 1000;
            // console.log("momentData--", momentData)

            //  this.dates = schedule_date + ' ' + hours_minutes
            // console.log("converted--", new Date(this.dates).getTime() / 1000);
            this.scheduled_call = true;
        } else {
            this.scheduled_call = false;
        }
        this.schedule_call_popup = false;
        },
        getSelectedDate() {
            console.log("date--", this.dates);
            console.log("date_time--", this.date_time);
            if (this.scheduled_call) {
                return moment(this.date_time).format("DD-MMM-YYYY HH:mm");
            } else {
                if (this.dates != "") {
                return moment(this.dates).format("DD-MMM-YYYY");
                }
            }
        },
        googleMeetButton() {
            this.$vs.loading({
                background: "primary",
                color: "#ffffff",
                container: this.$refs.loadableButton.$el,
                scale: 0.45,
            });
            this.onlygooglemeetbutton = true;
            setTimeout(() => {
                let meetButton = document.getElementById("meet-now-button");
                meetButton.click();
                this.onlygooglemeetbutton = false;
            }, 1000);
        },
        changeMLevelBasedOnULevel(val){
            let u_levels = ['U2','U3','U3+','U3++'];
            if(u_levels.includes(val)){
                if(this.levels == 'M1' || this.levels == 'M2'){
                this.levels = 'M3'
                }else if(this.levels == 'M4' || this.levels == 'M4-'){
                this.levels = 'M5'
                }
            }else{
                this.levels = this.payload.level;
            }
        },
        levelChange() {
            let level_from_server = this.payload.level;
            if (level_from_server != null && level_from_server != "") {
                let priority_of_level_from_server = this.level_options.find((item) => item.id === level_from_server).level_priority;
                let priority_of_level_from_selected = this.level_options.find((item) => item.id === this.levels).level_priority;
                if (priority_of_level_from_selected < priority_of_level_from_server) {
                    this.$vs.notify({
                        title: "Not Allowed",
                        text: "Sorry you can't downgrad the level",
                        color: "danger",
                    });
                    this.levels = level_from_server;
                    this.level_priority_condition = true;
                }else{
                    this.level_priority_condition = false;
                }
                if(this.levels == 'M7D1' && this.loan_status && this.collection_team){
                this.$vs.notify({
                    title: "Not Allowed",
                    text: "Enrolled with loan can't mark it as M7D1",
                    color: "danger",
                });
                this.level_defaulter_condition = true;
                }else{
                this.level_defaulter_condition = false;
                }
                if(this.levels == 'M7D2' && !this.loan_status && this.collection_team){
                this.$vs.notify({
                    title: "Not Allowed",
                    text: "Not enrolled with loan can't mark it as M7D2",
                    color: "danger",
                });
                this.defaulter_level = true;
                }else{
                this.defaulter_level = false;
                }
            }
        },
        M6LevelOptions() {
            this.labelOptions = [];
            if (this.getTeam("SR")) {
                this.labelOptions = [
                    { label: "", id: "" },
                    { label: "M11 : Received CPA license", id: "M11" },
                    { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                    { label: "M12 : Received License Renewal Advice", id: "M12" },
                    { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                    { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                    { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                    { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-" },
                    { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                    { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                    { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                    { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
                    { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
                    { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-" },
                    { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                    { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                    { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                    { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                    { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                    { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                    { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                    { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                    { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                    { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                    { label: "M7 - INT", id: "M7-INT"},
                    { label: "M7-IR", id: "M7-IR"},
                    { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X" },
                    { label: "M7D1 : Defaulter", id: "M7D1" },
                    { label: "M7D2 : Loan Defaulter", id: "M7D2" },
                    { label: "M7+ LMS demo completed (30-60 days)", id: "M7+" },
                    { label: "M7# : Onboarding Done (30-60 days)", id: "M7#" },
                    { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
                    { label: "M7- : Drop-out (30 days)", id: "M7-" },
                    { label: "M7 : Enrolled", id: "M7" },
                    { label: "M6 AR : Visited & Ready to Enroll, Application Received", id: "M6 AR" },
                    { label: "M6 AR- : Visited & Ready to Enroll, Application Received - Drop Out", id: "M6 AR-" },
                    { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                    { label: "M5 : Visited & Positive", id: "M5" },
                    { label: "M4 : Visited but Postponed", id: "M4" },
                    { label: "M4- : Visited but not interested", id: "M4-" },
                    { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                    { label: "M3+ : Called & Coming", id: "M3+" },
                    { label: "M3 : Called & Positive", id: "M3" },
                    { label: "M2 : Did not Visit & Postponed", id: "M2" },
                    { label: "M1 : Did not Visit & not intersted", id: "M1" },
                ];
            } else {
                this.labelOptions = [
                    { label: "", id: "" },
                    { label: "M7 : Enrolled", id: "M7" },
                    { label: "M6 AR : Visited & Ready to Enroll, Application Received", id: "M6 AR" },
                    { label: "M6 AR- : Visited & Ready to Enroll, Application Received - Drop Out", id: "M6 AR-"},
                    { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                    { label: "M5 : Visited & Positive", id: "M5" },
                    { label: "M4 : Visited but Postponed", id: "M4" },
                    { label: "M4- : Visited but not interested", id: "M4-" },
                    { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                    { label: "M3+ : Called & Coming", id: "M3+" },
                    { label: "M3 : Called & Positive", id: "M3" },
                    { label: "M2 : Did not Visit & Postponed", id: "M2" },
                    { label: "M1 : Did not Visit & not intersted", id: "M1" },
                ];
            }
        },
        levelOptions() {
            if (
                this.payload.level === "M4-" ||
                this.payload.level === "M4" ||
                this.payload.level === "M5" ||
                this.payload.level === "M6" ||
                this.payload.level === "L4-" ||
                this.payload.level === "L4" ||
                this.payload.level === "L5" ||
                this.payload.level === "L6" ||
                this.payload.level === "M7"
            ) {
                if (this.getTeam("SR") && this.getSubTeam("SR")) {
                    this.labelOptions = [
                        { label: "", id: "" },
                        { label: "M11 : Received CPA license", id: "M11" },
                        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                        { label: "M12 : Received License Renewal Advice", id: "M12" },
                        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                        { label: "M9- : Drop-out (after clearing an exam)", id: "M9-"},
                        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                        { label: "M8 : Evaluation or IMA Regt(CMA)", id: "M8" },
                        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
                        { label: "M8- : Drop-out (after evaluation)", id: "M8-"},
                        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                        { label: "M7 - INT", id: "M7-INT"},
                        { label: "M7-IR", id: "M7-IR"},
                        { label: "M7X : Do Not Ever Call", id: "M7X" },
                        { label: "M7D1 : Miles Defaulters", id: "M7D1" },
                        { label: "M7D2 : Loan Defaulters", id: "M7D2" },
                        { label: "M7+ : LMS demo completed", id: "M7+" },
                        { label: "M7# : Onboarding Done", id: "M7#" },
                        { label: "M7-JJ : Non Miles Jain Enrolled Candidates", id: "M7-JJ" },
                        { label: "M7- : Drop-out", id: "M7-" },
                        { label: "M7 : Enrolled", id: "M7" },
                        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                        { label: "M5 : Visited & Positive", id: "M5" },
                        { label: "M4 : Visited but Postponed", id: "M4" },
                        { label: "M4- : Visited but not interested", id: "M4-" },
                        { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                        { label: "M3+ : Called & Coming", id: "M3+" },
                        { label: "M3 : Called & Positive", id: "M3" },
                        { label: "M2 : Did not Visit & Postponed", id: "M2" },
                        { label: "M1 : Did not Visit & not intersted", id: "M1" },
                    ];
                } else {
                    if (
                        this.payload.level === "M4-" ||
                        this.payload.level === "M4" ||
                        this.payload.level === "M5" ||
                        this.payload.level === "M6" ||
                        this.payload.level === "L4-" ||
                        this.payload.level === "L4" ||
                        this.payload.level === "L5" ||
                        this.payload.level === "L6" ||
                        this.payload.level === "M7" ||
                        this.payload.level === "N/A" ||
                        this.payload.level === "DND"
                    ) {
                        this.labelOptions = [
                            { label: "", id: "" },
                            { label: "M7 : Enrolled", id: "M7" },
                            { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                            { label: "M5 : Visited & Positive", id: "M5" },
                            { label: "M4 : Visited but Postponed", id: "M4" },
                            { label: "M4- : Visited but not interested", id: "M4-" },
                            { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                            { label: "M3+ : Called & Coming", id: "M3+" },
                            { label: "M3 : Called & Positive", id: "M3" },
                            { label: "M2 : Did not Visit & Postponed", id: "M2" },
                            { label: "M1 : Did not Visit & not intersted", id: "M1" },
                        ];
                    } else {
                        this.labelOptions = [
                            { label: "", id: "" },
                            { label: "M11 - Received CPA license", id: "M11" },
                            { label: "M11# - Yet to recieve License renewal advice from Miles", id: "M11#" },
                            { label: "M12 - Received License Renewal Advice", id: "M12" },
                            { label: "M10 - Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                            { label: "M10# - Received license advice from Miles; License application WIP", id: "M10#" },
                            { label: "M9 - Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                            { label: "M9- - Drop-out (after clearing an exam) (once every 3 months)", id: "M9-" },
                            { label: "M9+1 - Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                            { label: "M9+2 - Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                            { label: "M9+3 - New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                            { label: "M8 - Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
                            { label: "M8+ - Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
                            { label: "M8- - Drop-out (after evaluation) (once every 3 months)", id: "M8-"},
                            { label: "M8#1 - Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                            { label: "M8#1- - Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                            { label: "M8#1+ - Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                            { label: "M8#2 - Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                            { label: "M8+1 - Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                            { label: "M8+2 - Applied for NTS; Yet to receive NTS", id: "M8+2" },
                            { label: "M8+3 - Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                            { label: "M8+4 - Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                            { label: "M8+5 - Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                            { label: "M8+6 - Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                            { label: "M7X - Do Not Ever Call (NFD - Blank)", id: "M7X" },
                            { label: "M7D2 - Loan Defaulter", id: "M7D2" },
                            { label: "M7D1 - Defaulter", id: "M7D1" },
                            { label: "M7+ LMS demo completed (30-60 days)", id: "M7+" },
                            { label: "M7- - Drop-out (30 days)", id: "M7-" },
                            { label: "M7# - Onboarding Done (30-60 days)", id: "M7#" },
                            { label: "M7-JJ - Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
                            { label: "M7 - Enrolled", id: "M7" },
                            { label: "M7- - Drop-out (30 days)", id: "M7-" },
                            { label: "M6 - Visited & Ready to Enroll", id: "M6" },
                            { label: "M5 - Visited & Positive", id: "M5" },
                            { label: "M4 - Visited but Postponed", id: "M4" },
                            { label: "M4- - Visited but not interested", id: "M4-" },
                        ];
                    }
                }
            } else {
                if (this.getTeam("SR")) {
                    this.labelOptions = [
                        { label: "", id: "" },
                        { label: "M11 : Received CPA license", id: "M11" },
                        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
                        { label: "M12 : Received License Renewal Advice", id: "M12" },
                        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
                        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
                        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
                        { label: "M9- : Drop-out (after clearing an exam)", id: "M9-" },
                        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
                        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
                        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
                        { label: "M8 : Evaluation or IMA Regt(CMA)", id: "M8" },
                        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
                        { label: "M8- : Drop-out (after evaluation)", id: "M8-" },
                        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
                        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
                        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
                        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
                        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
                        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
                        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
                        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
                        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
                        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
                        { label: "M7 - INT", id: "M7-INT"},
                        { label: "M7-IR", id: "M7-IR"},
                        { label: "M7X : Do Not Ever Call", id: "M7X" },
                        { label: "M7D2 : Loan Defaulters", id: "M7D2" },
                        { label: "M7D1 : Miles Defaulter", id: "M7D1" },
                        { label: "M7+ : LMS demo completed", id: "M7+" },
                        { label: "M7- : Drop-out", id: "M7-" },
                        { label: "M7# : Onboarding Done", id: "M7#" },
                        { label: "M7-JJ : Non Miles Jain Enrolled Candidates", id: "M7-JJ" },
                        { label: "M7 : Enrolled", id: "M7" },
                        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                        { label: "M5 : Visited & Positive", id: "M5" },
                        { label: "M4 : Visited but Postponed", id: "M4" },
                        { label: "M4- : Visited but not interested", id: "M4-" },
                        { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                        { label: "M3+ : Called & Coming", id: "M3+" },
                        { label: "M3 : Called & Positive", id: "M3" },
                        { label: "M2 : Did not Visit & Postponed", id: "M2" },
                        { label: "M1 : Did not Visit & not intersted", id: "M1" },
                    ];
                } else {
                    this.labelOptions = [
                        { label: "", id: "" },
                        { label: "M7 : Enrolled", id: "M7" },
                        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                        { label: "M5 : Visited & Positive", id: "M5" },
                        { label: "M4 : Visited but Postponed", id: "M4" },
                        { label: "M4- : Visited but not interested", id: "M4-" },
                        { label: "M3++ - Ready to enroll - Not visited", id: "M3++" },
                        { label: "M3+ : Called & Coming", id: "M3+" },
                        { label: "M3 : Called & Positive", id: "M3" },
                        { label: "M2 : Did not Visit & Postponed", id: "M2" },
                        { label: "M1 : Did not Visit & not intersted", id: "M1" },
                    ];
                }
            }
        },
        freezeMAccLevels() {
            let macc_level_from_server = this.payload.iiml_level
            console.log("freezeMAccLevels called on change ", macc_level_from_server)
            if (macc_level_from_server != null && macc_level_from_server != "") {
                // let downgradable_levels = ['U0', 'U1', 'U2', 'U3-', 'U3', 'U3+', 'U4-']
                // if(!downgradable_levels.includes(macc_level_from_server))
                // {
                //   let index_of_macc_level_from_server = this.macc_levels.findIndex((item) => item.id === macc_level_from_server)
                //   console.log("index_of_macc_level_from_server ", index_of_macc_level_from_server, "length", downgradable_levels.length);
                //   if (index_of_macc_level_from_server > (downgradable_levels.length))
                //   {
                //     this.$vs.notify({
                //         title: "Not Allowed",
                //         text: "To downgrade a higher U Level",
                //         color: "danger",
                //       });
                //     this.selected_macc_level = macc_level_from_server;
                //   }
                // }
                let priority_of_macc_level_from_server = this.macc_levels.find((item) => item.id === macc_level_from_server).level_priority;
                let priority_of_macc_level_from_selected = this.macc_levels.find((item) => item.id === this.selected_macc_level).level_priority;
                console.log("priority_of_macc_level_from_server", priority_of_macc_level_from_server);
                console.log("priority_of_macc_level_from_selected", priority_of_macc_level_from_selected);
                if (priority_of_macc_level_from_selected < priority_of_macc_level_from_server) {
                    this.$vs.notify({
                        title: "Not Allowed",
                        text: "To downgrade from higher U Level",
                        color: "danger",
                    });
                    this.selected_macc_level = macc_level_from_server;
                }
            }
        },
        checkLevel() {
            if (this.levels === 'M1' || this.levels === 'M4-' || this.levels === 'M4' || this.levels === 'M2' || this.selected_macc_level === "U0") {
                return false;
            } else {
                return true;
            }
        },
        storePersona() {
            console.log("STORE PERSONA FROM CALL SECTION", this.new_persona_data)

            let url = `https://mfcomms.2x2.ninja/api/updateCandidatePersonaDetails`;
            axios
                .post(url, this.new_persona_data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("Personna Data", response.data);

                    if (response.data.status == "success") {
                        this.$vs.notify({
                            title: "Success",
                            text: "Persona Details Updated Successfully",
                            color: "success",
                        });
                        this.getCandidatePersonaDetails();

                        // console.log("API AT EMIT",this.existing_persona_data ,obj)

                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Something went wrong",
                            color: "danger",
                        });
                    }

                })
                .catch((error) => {
                    this.handleError(error);
                    // this.getCandidatePersonaDetails();
                    this.$vs.loading.close();
                });

        },
        checkPersonaData(new_data) {
            // this.old_persona_data = existing_data;
            this.new_persona_data = new_data
            // console.log("OLD PERSONA",existing_data);
            // console.log("NEW PERSONA", new_data);

        },
        getPersonnaUpdatedData() {
            this.getCandidatePersonaDetails();
        },
        // determineMaccLevel() {
        //     console.log("DETERMINE MACC LEVEL PATHWAY CHANGE", this.pathway, this.course)

        //     let downgradable_levels = ["U0", "U1", "U2", "U3-", "U3", "U3+", "U4-"]

        //     let hot_level_pathways = ["Pathway 2 - US", "Pathway 3 - Canada", "Pathway 4 - US or Canada"]

        //     //For Pathway 2,3,4 U level = U3
        //     // if this.payload.iiml_level includes (U0, U1)
        //     if (hot_level_pathways.includes(this.pathway)) {
        //         if (this.payload.iiml_level != "" && this.payload.iiml_level != null && downgradable_levels.includes(this.payload.iiml_level)) {
        //             this.selected_macc_level = "U3"
        //             if (!this.course.includes('MAcc')) {
        //                 this.course.push('MAcc');
        //             }
        //         } else if (this.payload.iiml_level == "" || this.payload.iiml_level == null) {
        //             this.selected_macc_level = "U3"
        //             if (!this.course.includes('MAcc')) {
        //                 this.course.push('MAcc');
        //             }
        //         } else if (this.payload.iiml_level != "" && this.payload.iiml_level != null && !downgradable_levels.includes(this.payload.iiml_level)) {
        //             this.selected_macc_level = this.payload.iiml_level
        //             if (!this.course.includes('MAcc')) {
        //                 this.course.push('MAcc');
        //             }
        //         }
        //     }

        //     //For Pathway 5 U Level = U2
        //     let warm_level_pathways = ["Pathway 5 - India/US/Canada"]

        //     if (warm_level_pathways.includes(this.pathway)) {
        //         if (this.payload.iiml_level != "" && this.payload.iiml_level != null && downgradable_levels.includes(this.payload.iiml_level)) {
        //             if (!this.course.includes('MAcc')) {
        //                 this.course.push('MAcc');
        //             }
        //             this.selected_macc_level = "U2"
        //         } else if (this.payload.iiml_level == "" || this.payload.iiml_level == null) {
        //             this.selected_macc_level = "U2"
        //             if (!this.course.includes('MAcc')) {
        //                 this.course.push('MAcc');
        //             }
        //         } else if (this.payload.iiml_level != "" && this.payload.iiml_level != null && !downgradable_levels.includes(this.payload.iiml_level)) {
        //             this.selected_macc_level = this.payload.iiml_level
        //             if (!this.course.includes('MAcc')) {
        //                 this.course.push('MAcc');
        //             }
        //         }
        //     }

        //     // For Pathway 1 U Level = U0
        //     if (this.pathway == "Pathway 1 - India") {
        //         if (this.payload.iiml_level != "" && this.payload.iiml_level != null && downgradable_levels.includes(this.payload.iiml_level)) {
        //             this.selected_macc_level = "U0"
        //             this.course.splice(this.course.indexOf('MAcc'), 1);
        //         } else if (this.payload.iiml_level != "" && this.payload.iiml_level != null && !downgradable_levels.includes(this.payload.iiml_level)) {
        //             this.selected_macc_level = this.payload.iiml_level
        //             this.$vs.notify({
        //                 title: "U-level cannot be downgraded",
        //                 color: "danger",
        //                 time: 5000
        //             });
        //             EventBus.$emit('make-pathway-empty');
        //         } else if (this.payload.iiml_level == "" || this.payload.iiml_level == null) {
        //             this.selected_macc_level = "U0"
        //             this.course.splice(this.course.indexOf('MAcc'), 1);
        //         }
        //     }
        // },
        getCandidatePersonaDetails() {
            this.persona_details = {};
            // this.$vs.loading();
            let mwb_id = this.payload.id;
            let url = `https://mfcomms.2x2.ninja/api/getCandidatePersonaDetails?mwb_id=${mwb_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.persona_details = response.data;
                    this.personna_data = true;
                    console.log("personnaaaa", this.persona_details)
                    //  console.log("Personna call Data",  this.persona_details, this.persona_details.length);
                    // this.$vs.loading.close();
                    EventBus.$emit("persona-candidate-queue-updated-data", this.persona_details)
                })
                .catch((error) => {
                    this.handleError(error);
                    this.personna_data = true;
                    this.$vs.loading.close();
                });
        },
        openWileyPopup() {
            if (this.course.length > 1) {
                this.showCustomNotification(
                    "Please Select only one course",
                    "For this candidate multiple courses are seleted.please select only one course for sending wiley application ",
                    "danger"
                );
                return;
            }
            if (this.course.toString() !== "IITR-CC" && this.course.toString() !== "IITM-AA") {
                this.showCustomNotification(
                    "Please Select only IITR-CC OR IITM-AA courses",
                    "For selecting course " + this.course.toString() + " , ITRAC Application is not created. Please select only IITR-CC OR IITM-AAcourses.",
                    "danger"
                );
                return;
            }
            if (!this.primaryExists(this.completeData.emails)) {
                this.showCustomNotification(
                    "Not Valid",
                    "Primary email is required for registered user to send wiley application",
                    "danger"
                );
                return;
            } else if (!this.primaryExists(this.completeData.mobiles)) {
                this.showCustomNotification(
                    "Not Valid",
                    "Primary mobile number is required for registered user to send wiley application",
                    "danger"
                );
                return;
            }
            this.text = "Testing";
            this.popupOpen = true;
            console.log("course", this.course.toString())
            this.PrimaryMobile = this.getMobileForCandidate(this.completeData.mobiles);
            this.PrimaryEmail = this.getEmailForCandidate(this.completeData.emails);
            this.WlieyPersonName = this.completeData.mwb.person_name;
            this.SelectedCOurse = this.course.toString();
        },
        primaryExists(PurchaseData) {
            console.log("dim", PurchaseData);
            let bool = false;
            PurchaseData.forEach((item) => {
                if (item.pri_mary === 1) {
                    bool = true;
                }
            });
            return bool;
        },
        sendWileyApplication() {
            let obj = {
                mwb_id: this.completeData.mwb.id,
                can_name: this.completeData.mwb.person_name,
                miles_course: this.SelectedCOurse,
                mobile: this.getMobileForCandidate(this.completeData.mobiles),
                email: this.getEmailForCandidate(this.completeData.emails)
            };
            console.log(obj)
            // return;
            let url = `${constants.SERVER_API}createWileyApplication`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            color: "success",
                        });
                        this.popupOpen = false;
                    }
                    if (response.data.status === "failed" || response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendCMIntroEmails(course) {
            let exclude = [];
            if (exclude.indexOf(course) !== -1) {
                // this.sendIntroEmail(course);
                return;
            }
            let smsObj = {
                course: this.sendIntrocourse,
            };
            this.sendIIMLSMS(this.completeData, smsObj);
            if (this.getrole("exed2") && course === "IIML-HR") {
                this.sendIIMLHRInvite();
                return;
            } else if (this.getrole("exed2") && course === "IITR-BF") {
                // this.sendIIMLHRInvite();
                this.sendIITRBFInvite();
                return;
            } else if (this.getrole("exed2") && course === "IITR-DB") {
                // this.sendIIMLHRInvite();
                this.sendIITRDBInvite();
                return;
            } else if (this.getrole("exed2") && course === "IITM-AA") {
                // this.sendIIMLHRInvite();
                this.sendIITMAAInvite();
                return;
            } else if (this.getrole("exed2") && course === "IIMK-CX") {
                // this.sendIIMLHRInvite();
                this.sendIIMKCXInvite();
                return;
            } else if (this.getrole("exed2") && course === "IITM-FS") {
                // this.sendIIMLHRInvite();
                this.sendIITMFSInvite();
                return;
            } else if (this.getrole("exed2") && course === "IITR-CC") {
                // this.sendIIMLHRInvite();
                this.sendIITRCCInvite();
                return;
            } else if (this.getrole("exed2") && course === "IIMK-FT") {
                // this.sendIIMLHRInvite();
                this.sendIIMKFTInvite();
                return;
            } else if (this.getrole("exed2") && course === "IIML-AB") {
                // this.sendIIMLHRInvite();
                this.sendIIMLABInvite();
                return;
            } else if (this.getrole("exed2") && course === "IIML-SH") {
                // this.sendIIMLHRInvite();
                this.sendIIMLSHInvite();
                return;
            } else if (this.getrole("exed2") && course === "IITJ-DE") {
                // this.sendIIMLHRInvite();
                this.sendIITJDEInvite();
                return;
            } else if (this.getrole("exed2") && course === "XLRI-HR") {
                // this.sendIIMLHRInvite();
                this.sendXLRIHRInvite();
                return;
            } else if (this.getrole("exed2") && course === "XLRI-SH") {
                // this.sendIIMLHRInvite();
                this.sendXLRISHInvite();
                return;
            } else if (this.getrole("exed2") && course === "XLRI-DM") {
                // this.sendIIMLHRInvite();
                this.sendXLRIDMInvite();
                return;
            } else if (this.getrole("exed2")) {
                this.sendIIMLPAInvite();
                return;
            }
            let obj = {
                mwb_id: this.completeData.mwb.id,
            };
            let url = null;
            // `${constants.SERVER_API}sentIIMLPAIntorEmail`
            if (course === "CPA") {
                url = `${constants.SERVER_API}sentCPAIntroEmail`;
            } else if (course === "CMA") {
                url = `${constants.SERVER_API}sentCMAIntroEmail`;
            } else if (course === "CFA") {
                url = `${constants.SERVER_API}sentCFAIntroEmail`;
            } else if (course === "FRM") {
                url = `${constants.SERVER_API}sentFRMIntroEmail`;
            } else if (course === "USP") {
                url = `${constants.SERVER_API}sentMSSTEMIntroEmail`;
            } else if (course === "IIML-FA") {
                url = `${constants.SERVER_API}sentIIMLFAIntroEmail`;
            }
            axios
                .get(url, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendIITRDBInvite() {
            console.log("DB invite");
        },
        sendIITMAAInvite() {
            console.log("AA invite");
        },
        sendIIMKCXInvite() {
            console.log("IIMk-CX invite");
        },
        sendIITMFSInvite() {
            console.log("IITM-FS invite");
        },
        sendIITRCCInvite() {
            console.log("IITR-CC invite");
        },
        sendIIMKFTInvite() {
            console.log("IIMK-FT invite");
        },
        sendIIMLABInvite() {
            console.log("IIML-AB invite");
        },
        sendIIMLSHInvite() {
            console.log("IIML-SH invite");
        },
        sendIITJDEInvite() {
            console.log("IITJ-DE invite");
        },
        sendXLRIHRInvite() {
            console.log("XLRI-HR invite");
        },
        sendXLRISHInvite() {
            console.log("XLRI-SH invite");
        },
        sendXLRIDMInvite() {
            console.log("XLRI-DM invite");
        },
        getWhatsAppDisplayText() {
            let mobile = this.getMobileForCandidate(this.completeData.mobiles);
            let email = this.getEmailForCandidate(this.completeData.emails);
            // if (mobile === null || email === null) {
            //   return;
            // }
            let course = null;
            if (this.getSubTeam("IIML-HR")) {
                course = "HR";
            } else if (this.getSubTeam("IITR-BF")) {
                course = "IITR-BF";
            } else if (this.getSubTeam("IITR-DB")) {
                course = "IITR-DB";
            } else if (this.getSubTeam("IITM-AA")) {
                course = "IITM-AA";
            } else if (this.getSubTeam("IIMK-CX")) {
                course = "IIMK-CX";
            } else if (this.getSubTeam("IITM-FS")) {
                course = "IITM-FS";
            } else if (this.getSubTeam("IITR-CC")) {
                course = "IITR-CC";
            } else if (this.getSubTeam("IIMK-FT")) {
                course = "IIMK-FT";
            } else if (this.getSubTeam("IIML-AB")) {
                course = "IIML-AB";
            } else if (this.getSubTeam("IIML-SH")) {
                course = "IIML-SH";
            } else if (this.getSubTeam("IITJ-DE")) {
                course = "IITJ-DE";
            } else if (this.getSubTeam("XLRI-HR")) {
                course = "XLRI-HR";
            } else if (this.getSubTeam("XLRI-SH")) {
                course = "XLRI-SH";
            } else if (this.getSubTeam("IIMI-BA")) {
                course = "IIMI-BA";
            } else if (this.getSubTeam("IIMI-AA")) {
                course = "IIMI-AA";
            }else if (this.getSubTeam("XLRI-DM")) {
                course = "XLRI-DM";
            } else {
                course = "PM";
            }
            let text = `Send Whatsapp and ${course} Intro Email`;
            if (mobile == null) {
                text = `Send ${course} Intro Email`;
            } else if (email == null) {
                text = "Send Whatsapp Message";
            }
            return text;
        },
        sendIntroEmail() {
            if (this.getrole("exed2") && this.getSubTeam("IIML-HR")) {
                this.sendIIMLHRInvite();
            } else if (this.getrole("exed2") && this.getSubTeam("IITR-BF")) {
                // this.sendIIMLHRInvite();
                this.sendIITRBFInvite();
                console.log("Send IITR-BF Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IITR-DB")) {
                // this.sendIIMLHRInvite();
                this.sendIITRDBInvite();
                console.log("Send IITR-DB Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IITM-AA")) {
                // this.sendIIMLHRInvite();
                this.sendIITMAAInvite();
                console.log("Send IITM-AA Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IIMK-CX")) {
                // this.sendIIMLHRInvite();
                this.sendIIMKCXInvite();
                console.log("Send IIMK-CX Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IITM-FS")) {
                // this.sendIIMLHRInvite();
                this.sendIITMFSInvite();
                console.log("Send IITM-FS Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IITR-CC")) {
                // this.sendIIMLHRInvite();
                this.sendIITRCCInvite();
                console.log("Send IITR-CC Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IIMK-FT")) {
                // this.sendIIMLHRInvite();
                this.sendIIMKFTInvite();
                console.log("Send IIMK-FT Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IIML-AB")) {
                // this.sendIIMLHRInvite();
                this.sendIIMLABInvite();
                console.log("Send IIML-AB Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IIML-SH")) {
                // this.sendIIMLHRInvite();
                this.sendIIMLSHInvite();
                console.log("Send IIML-SH Intro Invite");
            } else if (this.getrole("exed2") && this.getSubTeam("IITJ-DE")) {
                // this.sendIIMLHRInvite();
                this.sendIITJDEInvite();
                console.log("Send IITJ-DE Intro Invite");
            } else if (this.getrole("exed2")) {
                this.sendIIMLPAInvite();
            }
        },
        sendIITRBFInvite() {
            let obj = {
                mwb_id: this.completeData.mwb.id,
            };
            axios
                .get(`${constants.SERVER_API}sentIITRBFIntroEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendIIMLHRInvite() {
            let obj = {
                mwb_id: this.completeData.mwb.id,
            };
            axios
                .get(`${constants.SERVER_API}sentIIMLHRIntroEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                    2;
                });
        },
        sendIIMLPAInvite() {
            let obj = {
                mwb_id: this.completeData.mwb.id,
            };
            axios
                .get(`${constants.SERVER_API}sentIIMLPAIntorEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                    2;
                });
        },
        sendZoomInviteIIMLPM() {
            this.$vs.loading();
            let mobile = this.getMobileForCandidate(this.completeData.mobiles);
            let email = this.getEmailForCandidate(this.completeData.emails);
            if (mobile === null || email === null) {
                return;
            }
            let params = {
                form_array: [
                    {
                        canid: this.completeData.mwb.identity,
                        first_name: this.completeData.mwb.person_name,
                        last_name: null,
                        email: email,
                        contact: mobile,
                        comingFrom: "MilesForce",
                        course: "IIML-PM",
                        location: this.completeData.mwb.city,
                        webinarId: "91233796915",
                        city: this.completeData.mwb.city,
                        state: null,
                        country: null,
                    },
                ],
            };
            axios
                .post(
                    `https://website-api.mileseducation.com/zoomWileyRegistration`,
                    params
                    // {
                    //   headers: {
                    //     Authorization: `Bearer ${localStorage.userAccessToken}`,
                    //   },
                    // }
                )
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    if (
                        response.data.message ===
                        "Webinar Created Successfully."
                    ) {
                        this.$vs.notify({
                            title: `Success`,
                            text: response.data.message,
                            color: "success",
                        });
                    } else {
                        this.$vs.notify({
                            title: `Error`,
                            text: response.data.data.message,
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendZoomInvite() {
            let mobile = this.getMobileForCandidate(this.completeData.mobiles);
            let email = this.getEmailForCandidate(this.completeData.emails);
            if (mobile === null || email === null) {
                return;
            }
            let spocName = null;
            if (this.getrole("cm1")) {
                spocName = this.completeData.mwb.spoc_name;
            } else if (this.getrole("exed2")) {
                spocName = this.completeData.mwb.assigned_iiml_name;
            }
            this.sendMilesZoomInvite(
                this.completeData.mwb,
                this.webinarIntro,
                email,
                mobile,
                spocName
            );
        },
        sendZoomInviteBA() {
            console.log(this.payload);
            let obj = {
                mwb_id: this.payload.id,
            };
            axios
                .post(`${constants.SERVER_API}sendZoomInviteBA`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        checkIfRoleis(role) {
            if (localStorage.getItem("role") === role) {
                return true;
            } else {
                return false;
            }
        },
        generateWhatsAppMessage() {
            console.log("called");
            let selected_courses_array = this.payload.courses.split(",");
            if (this.getrole("exed")) {
                if (selected_courses_array.indexOf("IIML-BA") !== -1) {
                    this.whatsapp_message =
                        "Be an IIM Lucknow MDP Alumni as you upskill in Business & Data Analytics. IIM Lucknow & Wiley - Executive Education Program in Business and Data Analytics - 6 months, Part-time, Online plus 6 days immersions at IIML campus. Stay Safe and attend Intro Webinar on Saturday (April 25th) at 5 PM. Register at: https://mileseducation.com/roadmap/iiml-ba";
                } else if (selected_courses_array.indexOf("IIML-FA") !== -1) {
                    this.whatsapp_message =
                        "Be an IIM Lucknow MDP Alumni as you upskill in Analytics for Finance. Register for Webinar on IIM Lucknow & Wiley - Executive Education Program in Analytics for Finance & Accounting Professionals, 6 months, Part-time, Online plus 6 days immersions at IIML campus. The session will be hosted by Varun Jain, CPA, CMA, Harvard B-School Alumnus. Date: Apr 19, 2020, at 11 AM IST. Registration link: https://mileseducation.com/roadmap/iiml-fa";
                }
            } else if (this.getrole("cm1") || this.getrole("cm4")) {
                this.selected_template = this.whatsapp_options[0];
                console.log("this.selected_template", this.whatsapp_options[0]);
                this.whatsapp_message = `*US CPA exams now in India - Pass the 4 CPA exams in Sep 2020*
Stay Safe while India’s favourite CPA instructor - Varun Jain - helps you prepare for the Sep 2020 exams via *live webinar masterclasses starting April 25*. Are you ready?
*Apr 25 - Aug 29*:
- *CPA exam prep*: Attend live online classes by Varun + Varun’s high-energy CPA videos + Practice on Miles online test-bank = 25 hrs per week x 18 weeks
- *CPA exam formalities*: Transcripts, Evaluation, NTS from state board
*Sep 1 - Sep 30*:
- Appear for and clear the *4 CPA exams* in India
*By Dec 31*:
Earn your *CPA license* which requires:
- Exams (you’ll clear the 4 exams in Sep)
- Education (150 credits)
- Experience (Varun will sign on your experience form as a verifier)
- Ethics (online on-demand course, to be completed in Oct)
Please revert on this message if you’re keen to get started this *Saturday, April 25* and we’ll complete your eligibility check and enrollment formalities to ensure you’re all set.
All the best 👍
Team Miles
mileseducation.com/cpa
P.S. 1 - For Miles study materials and training/support fees, zero-interest & zero-processing 3-month loan option
P.S. 2 - Watch Varun’s short gameplan video: https://youtu.be/xZgwUZ8Ts-g
P.S. 3 - Watch Varun's Webinar (April 19) on US CPA Roadmap & CPA exams in India [this a long one! 2 hours session and 3 hours Q&A] on https://youtu.be/-REv4cEeeWI`;
                // "Great News: US CPA exams now in India. Stay Safe as India's favorite CPA instrutor - Varun Jain - helps you achieve the CPA designation in 2020 via live online MasterClasses, best-in-class CPA videos, ebooks and online test bank. Career at Big 4 and MNC's. Start with Free Webinar @Sunday, 5 PM: www.mileseducation.com/2020";
            } else {
                this.whatsapp_message = "";
            }
        },
        generateEngagementInfoBadges() {
            // if (this.payload.incommunicado_status !== 0) {
            //   for (let x = 0; x <= this.payload.engagements.length - 1; x++) {
            //     if (this.payload.engagements[x].connection_status === "CD") {
            //       break;
            //     } else {
            //       this.arr.forEach(ar => {
            //         if (this.payload.engagements[x].connection_status === ar.form) {
            //           ar.value = ar.value + 1;
            //         }
            //       });
            //     }
            //   }
            // }
            // console.log(this.arr);
        },
        generateEngagementText(value) {
            if(this.select1 != "Connected / Discussed" || this.select1 != "Visit Confirmed"){
            let engagementString = "";
            console.log(value);
            if (
                this.payload.engagement_details === null ||
                this.payload.engagement_details === ""
            ) {
                // this.textAre = value;
                this.latestDate = `${this.getData()} - ${this.textAre}`;
                this.connection_status = false;
            } else {
                if (this.payload.engagement_details.includes("|")) {
                    console.log("in the if block");
                    // let index = this.payload.engagement_details.indexOf("|");
                    // index = index + 1;
                    // engagementString = `${this.getData()} - ${value}, | ${this.payload.engagement_details.substring(
                    //     index
                    // )}`;
                    let char1 =  this.payload.engagement_details.indexOf(',') + 1;
                    let char2 =  this.payload.engagement_details.lastIndexOf("|");
                        engagementString = `${this.getData()} - ${this.textAre} | ${this.payload.engagement_details.substring(
                        char1, char2
                        )}`;
                } else {
                    console.log("in the else block");
                    engagementString = `${this.getData()} - ${this.textAre}, | ${this.payload.engagement_details
                        }`;
                }
                // this.textAre = value;
                this.latestDate = engagementString;
                this.connection_status = false;
            }
            }
            console.log(this.latestDate);
        },
        getData() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();
            today = dd + "/" + mm + "/" + yyyy;
            return today;
        },
        customFormatter(date) {
            return moment(date).format("DD-MMM-YYYY");
        },
        emailform() {
            EventBus.$emit("open-email-engagement", this.payload);
        },
        fetchIcon(type) {
            switch (type) {
                case "call":
                    return "local_phone";
                case "visit":
                    return "place";
                case "email":
                    return "mail";
            }
        },
        getStatus(bool) {
            if (bool) {
                return 1;
            } else {
                return 0;
            }
        },
        async API_post_add_engagement(mode) {
            console.log("NEW-PERSONA-data", this.new_persona_data)
            let courses_list = ["CFA", "FRM", "IIML-FT", "IIML-SF", "IIMI-BA", "USP", "CMA", "CPA", "CPA-AA", "USP", "XLRI-HR", "XLRI-SH", "IIMI-AA"];
            let cfa_list = ["CFA-L1", "CFA-L2", "CFA-L3"];
            let frm_list = ["FRM-P1", "FRM-P2"];
            let allowed_status_for_persona = ["Connected / Discussed", "Visit Confirmed", "Visit Completed", "Visit Rescheduled"];
            let course_array_length = _.intersection(courses_list, this.course);
            let cfa_array_length = _.intersection(cfa_list, this.course);
            let frm_array_length = _.intersection(frm_list, this.course);
            if (mode === "all") {
                this.registeringforwebinar = "yes";
            } else {
                this.registeringforwebinar = "";
            }
            this.textAre = this.textAre.trim();
            let check_lvl = this.levels == this.payload.level
            if (this.textAre === "" || this.date === "") {
                this.$vs.notify({
                    title: "Fill out the form",
                    text: "Please fill out the details in the engagement form",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (this.level_priority_condition == true) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Not Allowed",
                    text: "Sorry you can't downgrade the level",
                    color: "danger",
                });
                this.resetLoadingButtons();
            }
            else if(this.level_defaulter_condition){
                this.$vs.notify({
                    title: "Not Allowed",
                    text: "Enrolled with loan can't mark it as M7D1",
                    color: "danger",
                });
                this.resetLoadingButtons();
            }
            else if(this.defaulter_level){
                this.$vs.notify({
                    title: "Not Allowed",
                    text: "Not enrolled with loan can't mark it as M7D2",
                    color: "danger",
                });
                this.resetLoadingButtons();
            }
            else if (course_array_length.length == 0) {
                // this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please Select Course",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (this.course.includes('CFA') && cfa_array_length.length == 0) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please Select CFA level",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (this.course.includes('FRM') && frm_array_length.length == 0) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please Select FRM Part",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (isNaN(Number(this.new_persona_data.years_of_experience)) && allowed_status_for_persona.includes(this.select1)) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    text: "Experience Cannot Be String in Persona",
                    color: "danger",
                });

                this.resetLoadingButtons();
            }
            // else if ((this.new_persona_data.location == null || this.new_persona_data.location == "") && allowed_status_for_persona.includes(this.select1)) {
            //     this.$vs.notify({
            //         text: "Location cannot be empty",
            //         color: "danger",
            //     });
            //     this.resetLoadingButtons();
            // }
            else if ((this.select1 == 'Connected / Discussed' || this.select1 == "Visit Confirmed" || this.select1 == "Visit Completed" || this.select1 == "Visit Rescheduled") && this.new_persona_data.pathway_value != 'Yes' && (this.new_persona_data.placement_assistance == '' || this.new_persona_data.placement_assistance == null || this.new_persona_data.placement_assistance == undefined)) {
                this.$vs.notify({
                text: "Please check Placement Assistance",
                color: "danger",
                });
                this.resetLoadingButtons();
            }else if(this.level_verify && !check_lvl){
                this.$vs.notify({
                title: "Not Allowed",
                text: "You don't have access to change the level to defaulter",
                color: "danger",
                });
                this.resetLoadingButtons();
            }
            else if(!this.loan_status && this.levels.includes('M7D2')){
                this.$vs.notify({
                title: "Not Eligible",
                text: "Candidate doesn't have any loan status",
                color: "danger",
                });
                this.resetLoadingButtons();
            }
            else {
                if (
                    this.select1 != 'Communication barrier' && this.select1 != 'Not Educated' && this.dates === "" &&
                    (this.getrole("cm1"))&&
                    this.levels !== "M7" && this.levels != "M4-" && this.levels != "M1" && this.levels != "M4" && this.levels != "M2" && this.levels != "DND"
                ) {
                    this.$vs.notify({
                        title: "Next call date is required",
                        text:
                            "Please select a next call date for adding an engagement",
                        color: "danger",
                    });
                    this.resetLoadingButtons();
                    return;
                }
                if (
                    this.select1 != 'Communication barrier' && this.select1 != 'Not Educated' &&this.dates === "" && this.dates === null &&
                    this.getrole("exed2") && !this.getSubTeam('XLRI-HR') && !this.getSubTeam('XLRI-SH') && !this.getSubTeam('IIML-FT') && !this.getSubTeam('IIMI-BA') && !this.getSubTeam('IIMI-AA')&&
                    this.exed_level !== "Cold" && this.exed_level !== "Enrolled"
                ) {
                    this.$vs.notify({
                        title: "Next call date is required",
                        text:
                            "Please select a next call date for adding an engagement",
                        color: "danger",
                    });
                    this.resetLoadingButtons();
                    return;
                }
                const engagementType = this.navTitleName.toLowerCase();
                if (
                    this.connection_status === false &&
                    engagementType === "call"
                ) {
                    this.textAre = this.latestDate;
                }
                let timestmp = "";
                // if (this.payload.level !== "M7" ) {
                // timestmp = new Date(this.dates).getTime() / 1000;
                // }
                if (this.scheduled_call) {
                    timestmp = new Date(this.date_time).getTime() / 1000;
                } else {
                    timestmp = new Date(this.dates).getTime() / 1000;
                }
                let connect_status = "";
                // switch (this.select1) {
                //     case "Connected / Discussed":
                //         connect_status = "cd";
                //         break;
                //     case "Connected / Busy":
                //         connect_status = "cb";
                //         break;
                //     case "Connected / Never call back":
                //         connect_status = "cn";
                //         break;
                //     case "Connected / Wrong Number":
                //         connect_status = "cw";
                //         break;
                //     case "Busy":
                //         connect_status = "b";
                //         break;
                //     case "Not Lifting":
                //         connect_status = "nl";
                //         break;
                //     case "Not Reachable":
                //         connect_status = "nr";
                //         break;
                //     case "Disconnected":
                //         connect_status = "d";
                //         break;
                //     case "Invalid Number":
                //         connect_status = "in";
                //         break;
                //     case "Switched Off":
                //         connect_status = "so";
                //         break;
                //     case "Connected / Not Interested":
                //         connect_status = "cni";
                //         break;
                //     case "Communication barrier":
                //         connect_status = "cmb";
                //         break;
                //     case "Not Educated":
                //         connect_status = "ne";
                //         break;
                // }
                switch (this.select1) {
          case "Connected / Discussed":
            connect_status = "cd";
            break;
          case "Visit Confirmed":
            connect_status = "vcnf";
            break;
          case "Visit Completed":
            connect_status = "vcmp";
            break;
          case "Visit Rescheduled":
            connect_status = "vre";
            break;
          case "Visit Cancelled":
            connect_status = "vcnl";
            break;
          case "Booked Visit By Mistake":
            connect_status = "bvbm";
            break;
          case "Not Connected(NL/SO/NR)":
            connect_status = "nc";
            break;
          case "Invalid Number/Wrong number":
            connect_status = "inwn";
            break;
          case "Connected / Not Interested":
            connect_status = "cni";
            break;
          case "Communication barrier":
            connect_status = "cmb";
            break;
          case "Not Educated":
            connect_status = "ne";
            break;
          case "Connected/ Call Back":
            connect_status = "ccb";
            break;
        }
                if (this.navTitleName.toLowerCase() === "visit") {
                    connect_status = "cd";
                }
                let copyenrollment = "";
                if (this.enrollment) {
                    copyenrollment = "Yes";
                } else {
                    copyenrollment = "No";
                }
                // console.log(this.enrollment);
                // this.course.forEach((subject, index) => {
                //   if (subject === "None") {
                //     this.course.splice(index, 1);
                //   }
                // });

                if (allowed_status_for_persona.includes(this.select1)) {
                    this.storePersona();
                }

                if(this.select1 != 'Communication barrier' && this.select1 != 'Not Educated' && this.getTeam('SR') && this.getSubTeam('SR') && (this.dates === "" || this.dates === null) && this.levels != "M7-JJ" && this.levels != "M7D1" && this.levels != "M7D2" && this.levels != "M7-INT" && this.levels != "M7-IR" && this.levels != "DND"){
                    this.$vs.notify({
                        title: "Next call date is required",
                        text:
                            "Please select a next call date for adding an engagement",
                        color: "danger",
                    });
                    this.resetLoadingButtons();
                    return;
                }

                let co = this.course.join();
                let url = `${constants.SERVER_API}addEngagement`;
                console.log(this.levels);
                console.log(this.exed_level);
                let obj = {
                    can_id: this.payload.can_id,
                    identity: this.payload.identity,
                    person_id: this.payload.person_id,
                    person_name: this.payload.person_name,
                    level: this.levels,
                    escalation_status: this.getStatus(this.escalation_boolean),
                    escalated_to: this.escalation_acads_member,
                    second_visit_status: this.getStatus(this.second_visit),
                    second_visit_to: this.second_visit_select,
                    courses: co,
                    details: this.textAre,
                    // iiml_level: this.iimlLevel,
                    iiml_level: this.exed_level,
                    type: this.navTitleName.toLowerCase(),
                    acads: 0,
                    next_call: timestmp,
                    type_of_nfd: this.type_of_nfd,
                    google_next_call_type: this.type_of_next_call,
                    update_call_log: "Yes",
                    connection_status: connect_status.toUpperCase(),
                    upcoming_visit_found: this.visit_found,
                    enrollment: copyenrollment,
                    registering_for_webinar: this.registeringforwebinar,
                    scheduled_call: this.scheduled_call,
                };

                if (localStorage.getItem("team") == "ExEd" && localStorage.getItem("sub_team") != "MAcc") {
                    obj.iiml_level = this.exed_level;
                }
                else if (localStorage.getItem("team") == "CM" && localStorage.getItem("sub_team") == "IIML-FT") {
                    obj.iiml_level = this.exed_level;
                }
                else {
                    obj.iiml_level = this.selected_macc_level;
                }
                // if (
                //     obj.iiml_level === "CM-Yet-To-Connect" &&
                //     obj.connection_status === "CD" &&
                //     this.getrole("cm1")
                // ) {
                //     this.$vs.notify({
                //         title: "Update the IIML Level",
                //         text:
                //             "Please update the IIML level to add an engagement",
                //         color: "warning",
                //     });
                //     this.resetLoadingButtons();
                //     return;
                // }
                console.log("paramsss",  obj, url);
                //---
                axios
                    .post(url, obj, {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === "error") {
                            this.$vs.notify({
                                title: "Error",
                                text: response.data.message,
                                color: "danger",
                            });
                            this.resetLoadingButtons();
                            return;
                        }
                        this.resetLoadingButtons();
                        this.$store.commit("UPDATE_ENGAGEMENT", [
                            this.payload,
                            this.textAre,
                            this.getData(),
                            connect_status,
                        ]);
                        this.$store.commit("UPDATE_ENGAGEMENT_AND_LEVEL", [
                            this.payload,
                            response.data.engagement,
                        ]);
                        this.$vs.notify({
                            title: "Engagement Added",
                            text: "Engagement is successfully added",
                            color: "success",
                        });
                        if (this.$route.path === "/queue") {
                            // console.log("this is in queue page");
                            EventBus.$emit("update-queue");
                        }
                        if (this.$route.path === "/netEnquiryTable") {
                            console.log(" ");
                            EventBus.$emit("updateCompleteNetEnquiry");
                            EventBus.$emit("close-MWB-popup");
                            EventBus.$emit("close-popup-redirect");
                        } else {
                            EventBus.$emit("close-MWB-popup");
                            EventBus.$emit("close-popup-redirect");
                        }
                    })
                    .catch((error) => {
                        this.resetLoadingButtons();
                        this.handleError(error);
                    });
            }
        },
        resetLoadingButtons() {
            this.all_loading = false;
            this.save_and_whatsapp_loading = false;
            this.save_loading = false;
        },
        addEngagement(mode) {
            // if(mode == 'all' && (this.course.includes("CPA") || this.course.includes("CMA") || this.course.includes("USP"))){
            //   this.milesCallbackWebhook();
            // }
            var webinar_courses = [];
            if(this.new_persona_data.pathway_value == 'Yes' && (mode == 'save only' || mode == 'save and whatsapp') ){
              webinar_courses.push("USP");
              this.milesCallbackWebhook(webinar_courses);
            }else if(mode == 'all'){
              webinar_courses = this.course;
              if(this.new_persona_data.pathway_value == 'Yes' && !webinar_courses.includes("USP")){
                webinar_courses.push("USP");
              }
              this.milesCallbackWebhook(webinar_courses);
            }
            if (this.select1 === "Connected / Discussed" || this.select1 == "Visit Confirmed" || this.select1 == "Visit Completed" || this.select1 == "Visit Rescheduled") {
                let data = this.new_persona_data
                let fields = ['ug_qualification','global_professional_qualification','indian_professional_qualification','years_of_experience','company','location','graduation_year', 'pathway_value']
                let headers = { company: 'Company',graduation_year: 'Graduation Year', location: 'Location', pathway_value: 'Pathway', indian_professional_qualification: 'Indian Professional Qualification', global_professional_qualification: 'Global Professional Qualification', ug_qualification: 'UG Qualification', years_of_experience: 'Years Of Experience' }
                let empty_field = ''
                let status = true
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i];
                    if (data[field] === '' || data[field] === null) {
                        empty_field = field
                        status = false
                    }
                }
                console.log("pathwayVal", this.new_persona_data.pathway_value);
                if(this.new_persona_data.pathway_value != 'Yes'){
                if (!status) {
                  if (empty_field == 'company' && (this.payload.college == null || this.payload.college == '')) {
                    this.$vs.notify({
                      title: 'If the company name is blank, enter the collage name on the left hand side.',
                      color: "danger",
                      time: 4000
                  });
                  }else{
                    if (empty_field != 'company') {
                    this.$vs.notify({
                        title: headers[empty_field] +' cannot be empty.',
                        color: "danger",
                        time: 1000
                    });
                    }else{
                      this.addEngagementPassed(mode)
                    }
                }
                }
                else{
                this.addEngagementPassed(mode)
                }
                }
                else if(this.new_persona_data.pathway_value == 'Yes' && (this.new_persona_data.graduation_year == '' || this.new_persona_data.graduation_year == null)){
                    this.$vs.notify({
                        title: 'Graduation year cannot be empty.',
                        color: "danger",
                        time: 1000
                    });
                }
                else if(this.new_persona_data.pathway_value == 'Yes' && (this.new_persona_data.location == '' || this.new_persona_data.location == null)){
                    this.$vs.notify({
                        title: 'Location cannot be empty.',
                        color: "danger",
                        time: 1000
                    });
                }
                else{
                this.addEngagementPassed(mode)
                }
            } else {
                this.addEngagementPassed(mode)
            }
        },
        milesCallbackWebhook(webinar_courses){
            let email = "";
            if(this.completeData.emails.length > 0){
                for (let i = 0; i < this.completeData.emails.length; i++) {
                    const element = this.completeData.emails[i];
                    if(element.pri_mary == 1){
                    email = element.email;
                    }
                }
                if(email == ""){
                    email = this.completeData.emails[0].email
                }
            }

            let number = "";
            if(this.completeData.mobiles.length > 0){
                for (let i = 0; i < this.completeData.mobiles.length; i++) {
                    const element = this.completeData.mobiles[i];
                    if(element.pri_mary == 1){
                    number = element.phone_number;
                    }
                }
                if(number == ""){
                    number = this.completeData.mobiles[0].phone_number;
                }
            }
            // const webinar_courses = this.course.filter(item => ["CPA", "CMA", "USP"].includes(item));
            console.log("webinar_courses", webinar_courses);
            const url= "https://webhook.miles-api.com/webhook/register";
            const obj = {
                "fname": this.completeData.mwb.person_name,
                "lname": "",
                "phone": number,
                "email": email,
                "location": this.completeData.mwb.city,
                "src": "MilesForce",
                "type": "",
                "camp": "",
                "course" : webinar_courses
            }
            if(number != "" && email != ""){
                axios
                    .post(url, obj)
                    .then((response) => {
                    console.log("milesCallbackWebhook",response.data.message);
                    if(response.data.status == true){
                        this.$vs.notify({
                        title: "Successfully registered for Breaking News USP Webinar",
                        color: "success",
                        });
                    } else {
                        this.$vs.notify({
                        title: response.data.message,
                        color: "success",
                        });
                    }
                    })
                    .catch((error) => {
                    this.handleError(error);
                    });
            } else {
                this.$vs.notify({
                    title: "Please Add Email And Mobile Number to register Breaking News USP Webinar",
                    color: "warning",
                });
            }
        },
        addEngagementPassed(mode) {
            let timestmp = "";
            if (this.scheduled_call) {
                timestmp = new Date(this.date_time).getTime() / 1000;
            } else {
                timestmp = new Date(this.dates).getTime() / 1000;
            }
            console.log("Add engagement mode: ", mode);
            let getParams = {
            mwb_id: this.payload.person_id,
            next_follow_up_date: timestmp,
            previous_discussion: this.textAre,
            type_of_nfd: this.type_of_nfd,
            };
            console.log("getParamss", getParams);
            ApiServices.storeNextFollowUps(getParams)
                .then((response) => {
                console.log(response);
                })
                .catch((error) => {
                this.$vs.notify({
                    title: error,
                    color: "danger",
                });
            });
            if (mode === "save only") {
                this.API_post_add_engagement(mode);
            } else if (mode === "save and whatsapp") {
                let mobile = this.getMobileForCandidate(
                    this.completeData.mobiles
                );
                let email = this.getEmailForCandidate(this.completeData.emails);
                if (mobile === null) {
                    this.resetLoadingButtons();
                    return;
                }
                if (email != null) {
                    // this.sendIntroEmails();
                }
                this.clickWhatsappButton();
                this.API_post_add_engagement(mode);
            } else if (mode === "all") {
                let mobile = this.getMobileForCandidate(
                    this.completeData.mobiles
                );
                let email = this.getEmailForCandidate(this.completeData.emails);
                if (mobile === null || email === null) {
                    this.resetLoadingButtons();
                    return;
                }
                // this.sendIntroEmails();
                this.clickWhatsappButton();
                this.sendWebinarInvite();
                this.API_post_add_engagement(mode);
            }
        },
        sendWebinarInvite() {
            console.log("Sending webinar invite", {
                mwb_id: this.completeData.mwb.id,
                courses: this.course.join(),
            });
            const obj = {
                mwb_id: this.completeData.mwb.id,
                courses: this.course.join(),
            };
            const url = `${constants.SERVER_API}saveWebinarSentDetails`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("savewebinarresponse:", response.data);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendIntroEmails() {
            console.log("Sending intro emails", {
                mwb_id: this.completeData.mwb.id,
                courses: this.course.join(),
            });
            const obj = {
                mwb_id: this.completeData.mwb.id,
                courses: this.course.join(),
            };
            const url = `${constants.SERVER_API}sendIntroEmailWithCourses`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("savewebinarresponse:", response.data);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        clickWhatsappButton() {
            let whatsappButton = document.getElementById(
                "send_whatsapp_message_btn"
            );
            whatsappButton.click();
            console.log("clicking whatsapp button", whatsappButton);
        },
        navSettings(text) {
            this.navTitleName = text;
            // console.log("change nav");
            switch (text) {
                case "All":
                    this.indexActive = 0;
                    this.allpage = true;
                    this.callpage = false;
                    this.visitpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Call":
                    this.indexActive = 1;
                    this.textAre = "";
                    this.select1 = "";
                    this.levels = this.payload.level;
                    this.enrollment = false;
                    this.callpage = true;
                    this.allpage = false;
                    this.visitpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Visit":
                    this.indexActive = 2;
                    this.textAre = "";
                    this.enrollment = false;
                    this.visitpage = true;
                    this.disable = false;
                    this.connected = true;
                    this.connection_status = true;
                    this.allpage = false;
                    this.callpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    if (
                        !this.payload.level.includes("4") &&
                        !this.payload.level.includes("5") &&
                        !this.payload.level.includes("6") &&
                        !this.payload.level.includes("7")
                    ) {
                        1;
                        this.levels = "M5";
                    } else {
                        if (this.payload.level.includes("L")) {
                            this.levels = "M5";
                        } else {
                            this.levels = this.payload.level;
                        }
                    }
                    break;
                case "Email":
                    this.indexActive = 3;
                    this.emailpage = true;
                    this.visitpage = false;
                    this.allpage = false;
                    this.callpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Whatsapp":
                    this.indexActive = 4;
                    this.whatsapp_page = true;
                    this.emailpage = false;
                    this.visitpage = false;
                    this.allpage = false;
                    this.callpage = false;
                    break;
            }
        },
        selectedULevel(value){
            let limit = 180;
            let suggested = 180;

            const macc_nfds = {
                "U0": {
                    limit: 0,
                    suggested: 0,
                },
                "U1": {
                    limit: 365,
                    suggested: 7,
                },
                "U1+": {
                    limit: 365,
                    suggested: 7,
                },
                "U2": {
                    limit: 30,
                    suggested: 7,
                },
                "U3-": {
                    limit: 365,
                    suggested: 7,
                },
                "U3": {
                    limit: 5,
                    suggested: 3,
                },
                "U3+": {
                    limit: 3,
                    suggested: 1,
                },
                "U3++": {
                    limit: 3,
                    suggested: 1,
                },
                "U4-": {
                    limit: 15,
                    suggested: 1,
                },
                "U4": {
                    limit: 3,
                    suggested: 1,
                },
                "U4R": {
                    limit: 3,
                    suggested: 1,
                },
                "U5": {
                    limit: 20,
                    suggested: 7,
                },
                "U5+": {
                    limit: 5,
                    suggested: 2,
                },
                "U6-": {
                    limit: 15,
                    suggested: 2,
                },
                "U6": {
                    limit: 30,
                    suggested: 7,
                },
                "U6+": {
                    limit: 30,
                    suggested: 7,
                },
                "U7-": {
                    limit: 5,
                    suggested: 1,
                },
                "U7": {
                    limit: 3,
                    suggested: 1,
                },
                "U8": {
                    limit: 3,
                    suggested: 1,
                },
                "U9": {
                    limit: 30,
                    suggested: 7,
                },
                "U10": {
                    limit: 90,
                    suggested: 30,
                },
            }
            limit = macc_nfds[value].limit;
            suggested = macc_nfds[value].suggested;

            this.disabledDates.from = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + limit
            );
            this.disabledDates.to = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            );
            if(!this.getTeam('SR') && !this.getSubTeam('SR')){
                this.dates = new Date(
                    new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate() + suggested
                    )
                );
            }
            console.log("manual dates", this.dates);
            // if(value == "U0"){
            //     this.dates = null;
            //     this.show_schedule_call = false;
            // }else{
            //     this.show_schedule_call = true;
            // }
            // this.freezeMAccLevels();
            var pathway_value = "Yes";
            if (value == "U2" || value == "U1" || value == "U3-") {
                pathway_value = "Maybe";
            } else if (value == "U0") {
                pathway_value = "No";
            } else if (value == "U1+") {
                pathway_value = "Yes but graduating in 2025 or after"
            }
            EventBus.$emit("changed-pathway-value", pathway_value);
            // this.determineMaccLevel();
        },
        selectedMLevel(value){
            if((value.includes('M7D1') || value.includes('M7D2')) && !this.collection_team){
                this.level_verify = true
            }else{
                this.level_verify = false
            }
            this.levelChange();
            if (!this.getrole("cm1") && !this.getTeam('SR')) {
                return;
            }
            let nfds = {};
            let limit = 180;
            let suggested = 180;

            if(!this.getTeam('SR')){
                nfds = {
                    M6: {
                        limit: 30,
                        suggested: 1,
                    },
                    M5: {
                        limit: 60,
                        suggested: 1,
                    },
                    M4: {
                        limit: 365,
                        suggested: 90,
                    },
                    // "M4-": {
                    //     limit: "",
                    //     suggested: "",
                    // },
                    "M3++": {
                        limit: 14,
                        suggested: 1,
                    },
                    "M3+": {
                        limit: 40,
                        suggested: 1,
                    },
                    M3: {
                        limit: 60,
                        suggested: 1,
                    },
                    M2: {
                        limit: 365,
                        suggested: 90,
                    },
                    // M1: {
                    //     limit: "",
                    //     suggested: "",
                    // },
                    M7: {
                        limit: 7,
                        suggested: 1,
                    },
                    "M7-": {
                        limit: 30,
                        suggested: 1,
                    },
                    M8: {
                        limit: 60,
                        suggested: 30,
                    },
                    "M8-": {
                        limit: 90,
                        suggested: 1,
                    },
                    M9: {
                        limit: 60,
                        suggested: 30,
                    },
                    "M9-": {
                        limit: 90,
                        suggested: 1,
                    },
                    M10: {
                        limit: 30,
                        suggested: 1,
                    }
                };
            }
            if(this.getTeam('SR')){
                nfds = {
                    M6: {
                        limit: 7,
                        suggested: 1,
                    },
                    M5: {
                        limit: 30,
                        suggested: 1,
                    },
                    M4: {
                        limit: 365,
                        suggested: 90,
                    },
                    // "M4-": {
                    //     limit: "",
                    //     suggested: "",
                    // },
                    "M3++": {
                        limit: 14,
                        suggested: 1,
                    },
                    "M3+": {
                        limit: 40,
                        suggested: 1,
                    },
                    M3: {
                        limit: 60,
                        suggested: 1,
                    },
                    M2: {
                        limit: 365,
                        suggested: 90,
                    },
                    // M1: {
                    //     limit: "",
                    //     suggested: "",
                    // },
                    M7: {
                        limit: 90,
                        suggested: 1,
                    },
                    "M7#": {
                        limit: 90,
                        suggested: 1,
                    },
                    "M7+": {
                        limit: 90,
                        suggested: 1,
                    },
                    "M7-": {
                        limit: 90,
                        suggested: 1,
                    },
                    M7X: {
                        limit: 0,
                        suggested: 0,
                    },
                    M8: {
                        limit: 90,
                        suggested: 1,
                    },
                    "M8+": {
                        limit: 90,
                        suggested: 1,
                    },
                    "M8-": {
                        limit: 90,
                        suggested: 1,
                    },
                    M9: {
                        limit: 90,
                        suggested: 1,
                    },
                    "M9-": {
                        limit: 90,
                        suggested: 1,
                    },
                    M10: {
                        limit: 90,
                        suggested: 1,
                    }
                };
            }
            if (nfds[value] !== undefined) {
                limit = nfds[value].limit;
                console.log("limit", limit)
                suggested = nfds[value].suggested;
                this.disabledDates.from = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + limit
                );
                this.disabledDates.to = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                );
                if(!this.getTeam('SR') && !this.getSubTeam('SR')){
                    this.dates = new Date(
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate() + suggested
                        )
                    );
                }
                let sr_show_nfd_levels = ["M2", "M3", "M3+", "M3++","M4", "M5", "M6"]
                if(sr_show_nfd_levels.includes(value) && this.getTeam("SR") && this.getSubTeam("SR")){
                    this.dates = new Date(
                        new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate() + suggested
                        )
                    );
                }
            }

            if (value === 'M7-IR' || value === 'M7-INT' || value === "M7X" || value === "M7-JJ" || value === "M7D1" || value === "M7D2" || value === "M4-" || value === "M4" || value === "M1" || value === "M2") {
                this.dates = "";
                this.show_schedule_call = false;
            }else{
                this.show_schedule_call = true;
            }
            // if (value === "M4-" || value === "M1" || value == "M4" || value == "M2") {
            //     this.disabledDates.from = "";
            //     this.disabledDates.to = "";
            // }
        }
    },
};
</script>

<style>
.contact_buttons {
    font-size: 25px;
    width: 50px !important;
    height: 50px !important;
    padding-top: 12px;
}
.mradio .vs-radio{
  background: rgb(0, 68, 186);
}
.connection_btn {
  color: #ffffff;
  background: #0044ba !important;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 9px 2px !important;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
}

.connection_btn .vs-button-text {
  padding-inline: 10px;
}
.insid-container {
  background: #ffffff;
  border: 1px solid rgba(77, 76, 76, 0.25);;
  border-radius: 15px;
  margin-left: 5px;
}
.status2 {
  font-family: "montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 17.7px;
  color: #000000;
}
.connectionStatus.vs--searchable .vs__dropdown-toggle.vs__selected-options.vs__selected.vs--disabled.vs__search{
    background: none;
}
</style>
