<template>
  <div>
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <p class="heading">POA</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <!-- <vs-button
            size="small"
            color="danger"
            type="gradient"
            @click="poaEscalation()"
          >
            <b>Escalate</b>
          </vs-button>-->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="warning"
            type="border"
            icon="chat"
            @click="editPOA"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px"></b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px"></b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px">Exam Target-1</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px">Exam Target-2</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px"></b>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px"></b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px"></b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px">
            {{ studentDetails.cma.part_1_exam_target }}
          </b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px">
            {{ studentDetails.cma.part_2_exam_target }}
          </b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px"></b>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <p style="color: gray; font-size: 15px">Classes Attended:</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-chip color="dark">
            <b>0</b>
          </vs-chip>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-chip color="dark">
            <b>0</b>
          </vs-chip>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.classes_attended}}</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.classes_attended}}</b>
        </vs-chip>
        </vs-col>-->
      </vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <p style="color: gray; font-size: 15px">OTB Attempt:</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-chip color="dark">
            <b>0</b>
          </vs-chip>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-chip color="dark">
            <b>0</b>
          </vs-chip>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.OTB_attended}}</b>
        </vs-chip>
      </vs-col>
       <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.OTB_attended}}</b>
        </vs-chip>
        </vs-col>-->
      </vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <p style="color: gray; font-size: 15px">IMA Schloarship Details:</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>{{ studentDetails.name_of_college }}</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>{{ studentDetails.schloarship_status }}</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>{{ studentDetails.schloarship_comments }}</b>
        </vs-col>
      </vs-row>
    </div>
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">UPSELLING</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Exam Target-1</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Exam Target-2</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Classes</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p>25</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p>30</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.classes, 'CLASSES')"
            >
              <b>AddOn</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Videos</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p :style="{color :  upsellingcolor(true)}">54</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p :style="{color :  upsellingcolor(false)}">85</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.videos, 'VIDEOS')"
            >
              <b>Buy</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">OTB</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p :style="{color :  upsellingcolor(true)}">85</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p :style="{color :  upsellingcolor(true)}">98</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.OTB, 'OTB')"
            >
              <b>Extend</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Books</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p>74</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p>89</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.Books, 'BOOKS')"
            >
              <b>Buy</b>
            </vs-button>
          </vs-col>
        </vs-row>
    </div>-->
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <p style="color: gray; font-size: 15px">Educational Tags:</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
            <v-select
              placeholder="Badges"
              multiple
              style="z-index: 1000;  margin-top: 4%; margin: 10px;"
              class="w-full"
              v-model="select1"
              :options="options1"
            ></v-select>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            <vs-chip
              @click="remove(chip)"
              closable
              color="primary"
              v-for="chip in select1"
              :key="chip.index"
            >
              <b>{{ chip }}</b>
            </vs-chip>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <p style="color: gray; font-size: 15px">Need For Bridge Course:</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="yes">YES</vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="no">NO</vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="na">N/A</vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="taken">TAKEN</vs-radio>
          </vs-col>
        </vs-row>
      </div>
      <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">CORPORATES REFFERANCE</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Name</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Designation</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Yrs</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Referral</b>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Apple</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Programmer</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">6</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">--</b>
          </vs-col>
        </vs-row>
        <vs-collapse :type="'margin'">
          <vs-collapse-item>
            <div slot="header">Miles Referrance</div>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Name</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Department</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Status</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                <vs-button
                  color="dark"
                  type="gradient"
                  @click="emitaddDetails('MILES_REFERRANCE')"
                  icon="add"
                ></vs-button>
              </vs-col>
            </vs-row>
            <div v-for="users in miles_referrance" :key="users.index">
              <vs-row class="gap">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.name}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.department}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.status}}</b>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
          <vs-collapse-item>
            <div slot="header">CareerGraph Referrance</div>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Name</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Department</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Status</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                <vs-button
                  color="dark"
                  type="gradient"
                  @click="emitaddDetails('CAREERGRAPH_REFERRANCE')"
                  icon="add"
                ></vs-button>
              </vs-col>
            </vs-row>
            <div v-for="users in careergraph_referrance" :key="users.index">
              <vs-row class="gap">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.name}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.department}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.status}}</b>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
        </vs-collapse>
    </div>-->
    <!-- <vs-di/vider class="mt-6"></vs-divider> -->
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="10"
        >
          <p class="heading">STUDENT REFERENCE</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="dark"
            type="gradient"
            icon="add"
            @click="add_student_cma_referrance"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Can-ID</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Candidate Name</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Referrence Date</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Current Status</b>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Bonus</b>
        </vs-col>-->
      </vs-row>
      <div v-for="users in studentDetails.student_reference" :key="users.index">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_identity }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_person_name }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ convertTimestampToDate(users.reference_given_date) }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">{{ users.bonus_status }}</b>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="font-size: 15px; color: gray">
            City: 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" style="font-size: 15px; color: gray">
            <vs-select class="selectExample" label="Cities" v-model="city">
              <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item,index) in cityOptions"/>
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="font-size: 15px; color: gray">
            Eligibility: 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
            <vs-select class="selectExample" label="Eligibility" v-model="eligibility">
              <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item,index) in eligibilityOptions"/>
            </vs-select>
          </vs-col>
        </vs-row>
        
    </div>-->
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">OTHER COURSES</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>CPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>DA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>RPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>FOF(O)</vs-checkbox>
          </vs-col>
        </vs-row>
    </div>-->
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">FEEDBACK ON MILES</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            style="font-size: 15px; color: gray"
          >On Call:</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
            
            <star-rating :star-size="20" v-model="customrating" @rating-selected="setRating" />
            
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
            
            {{ratingFeedback}}
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            style="font-size: 15px; color: gray"
          >On App:</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
            
          </vs-col>
        </vs-row>
    </div>-->

    <CMAPOAEdit />
  </div>
</template>

<script>
import EventBus from "../eventbus";
import CMAPOAEdit from "../queue/queueCmaPoa.vue";
import AddCmaStudentReference from "../queue/queueCmaAddSr.vue";
export default {
  components: {
    CMAPOAEdit,
    AddCmaStudentReference
  },
  props: {
    studentDetails: {
      type: Object,
      required: true
    }
  },
  mounted() {
    console.log("emitted", this.studentDetails.student_reference);
  },
  methods: {
    poaEscalation() {
      EventBus.$emit("poa-escalation");
    },
    editPOA() {
      this.EmitEvent("open-edit-cma-POA", this.studentDetails);
    },
    add_student_cma_referrance() {
      EventBus.$emit("open-add-student-cma-referrance", this.studentDetails);
      // this.EmitEvent('open-add-student-referrance', this.studentDetails.id)
    },
    EmitEvent(emit, payload) {
      // console.log("emitting");
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    }
  }
};
</script>

<style></style>
