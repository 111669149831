<template>
  <div>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <p class="heading">POA</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
        <!-- <vs-button
          size="small"
          color="danger"
          type="gradient"
          @click="poaEscalation()"
        >
          <b>SR Connect Request</b>
        </vs-button> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <vs-button
          radius
          color="warning"
          type="border"
          icon="chat"
          @click="editPOA()"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">Evaluation</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">NTS</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">AUD</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">FAR</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">BEC</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">REG</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{
          studentDetails.cpa.evaluation
        }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{ studentDetails.cpa.nts }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{ studentDetails.cpa.aud }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{ studentDetails.cpa.far }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{ studentDetails.cpa.bec }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{ studentDetails.cpa.reg }}</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <b style="color: gray; font-size: 12px">Transcripts : {{ studentDetails.cpa.transcript }}</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Classes Attended:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">OTB Attempt:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>0</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <POAEdit v-on:poa-updated="udatePOA" />
  </div>
</template>

<script>
import EventBus from "../eventbus";
import POAEdit from "../queue/queuePoaEdit.vue";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object
    }
  },
  components: {
    POAEdit
  },
  methods: {
    poaEscalation() {
      EventBus.$emit("poa-escalation", this.studentDetails);
    },
    editPOA() {
      EventBus.$emit("open-edit-POA", this.studentDetails);
    },
    udatePOA(cpa) {
      this.studentDetails.cpa = cpa;
    }
  }
};
</script>

<style></style>
