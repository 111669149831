<template>
  <div>
    <!-- <todayWhatsappBroadcastCount /> -->
    <div
      id="todo-app"
      class="
        border border-solid border-grey-light
        rounded
        relative
        overflow-hidden
      "
    >
      <vs-sidebar
        class="items-no-padding"
        parent="#todo-app"
        :click-not-close="clickNotClose"
        :hidden-background="clickNotClose"
        v-model="isSidebarActive"
      >
        <VuePerfectScrollbar
          style="width: -webkit-fill-available"
          class="chat-scroll-area pt-4"
          :settings="settings"
        >
          <div class="chat__contacts">
            <ul class="chat__active-chats bordered-items">
              <li class="cursor-pointer">
                <ChatContactIIML
                  v-if="getrole('exed') || getrole('exed2')"
                  :activeIndication="true"
                  :CardInfo="ActiveLead"
                ></ChatContactIIML>

                <chat-contact
                  v-else
                  :activeIndication="true"
                  :CardInfo="ActiveLead"
                ></chat-contact>
              </li>
            </ul>
            <ul class="chat__contacts bordered-items">
              <li
                class="cursor-pointer"
                v-for="(contact, index) in $store.state.MwbLeads"
                :key="index"
              >
                <ChatContactIIML
                  v-if="(getrole('exed') || getrole('exed2')) && index > 0"
                  :activeIndication="false"
                  :CardInfo="contact"
                ></ChatContactIIML>

                <!-- <chat-contact
                  v-else
                  :activeIndication="true"
                  :CardInfo="ActiveLead"
                ></chat-contact> -->

                <chat-contact
                  v-if="!getrole('exed') && !getrole('exed2') && index > 0"
                  :activeIndication="false"
                  :CardInfo="contact"
                ></chat-contact>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>
      </vs-sidebar>
      <div
        :class="{ 'sidebar-spacer': clickNotClose }"
        class="
          app-fixed-height
          border border-r-0 border-b-0 border-t-0 border-solid border-grey-light
          app-fixed-height
        "
      >
        <chat-log
          v-if="QueueRendered && queue_data == false"
          :callPermission="callPermission"
          @changeCallPermission="changeCallPermission"
          :activeUser="ActiveLeadDetails"
        ></chat-log>
        <div v-if="queue_data" class="no-queue-data-found">
          No Queue Data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import constants from "../../../constants.json";
import contacts from "./contacts";
import ChatContact from "./ChatContact.vue";
import ChatContactIIML from "./ChatContanctIIML.vue";
import UserProfile from "./UserProfile.vue";
import ChatNavbar from "./ChatNavbar.vue";
import ChatLog from "./ChatLog.vue";
import eventbus from "../eventbus";
import QueueContact from "./QueueContact.vue";
// import todayWhatsappBroadcastCount from "../whatsapp/TodayWhatsappCount.vue";
const hash = require('object-hash');
export default {
  name: "vx-sidebar",
  data() {
    return {
      queue_data: false,
      hash: null,
      isSidebarActive: true,
      active: true,
      isHidden: false,
      contacts: contacts,
      searchContact: "",
      activeProfileSidebar: false,
      activeChatUser: null,
      userProfileId: -1,
      typedMessage: "",
      isChatPinned: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
      clickNotClose: true,
      isChatSidebarActive: true,
      windowWidth: window.innerWidth,
      // Main Response
      fetchedQueueData: [],
      // The Lead which is active
      ActiveLead: {},
      // All the queued leads
      QueueData: [],
      // This is to check if the queued list is rendered
      QueueRendered: false,
      // This is for the play and pause option
      callPermission: "Pause",
      queueAddition: false,
      ActiveLeadDetails: {},
      processedData: [],
      // candidate_id_list: [],
      processedCount: 0
    };
  },
  mounted() {
    this.fetchedQueueData = [];
    this.fetchQueueData();
    eventbus.$on("update-queue", () => {
      console.log("EVENT FIRED")
      this.QueueRendered = false;
      this.processingData();
    });
    eventbus.$on("update-queue-addition", () => {
      console.log("EVENT FIRED")
      this.QueueRendered = false;
      this.queueAddition = true;
      this.processingData();
    });
    eventbus.$on("update-activelead-data", () => {
      this.updateActiveLeadDetails();
    });
    eventbus.$on("reload-queue-data", () => {
      this.updateActiveLeadDetails();
    });
  },
  watch: {
    ActiveLead(value) {
      if (
        value.given_type === "missed_calls" ||
        value.given_type === "untracked_calls"
      ) {
        this.fetchMissedCallData();
      } else if (value.given_type === "ne_visits" || value.given_type === "net_enquiry" || value.given_type === "ne_website_google" || value.given_type === "ne_other_sources") {
        this.fetchNetEnquiryData();
      } else {
        this.fetchMWBDetails();
      }
    },
  },
  methods: {
    processingData() {
      // Removing first element from fetchedQueueData
      this.processedCount++;
      this.fetchedQueueData.shift();
      // Removing element from processedData & getting the new data
      if (this.processedCount >= 3 || this.fetchedQueueData.length == 0) {
        this.processedCount = 0;
        this.fetchQueueData();
      }
      // To reload the queue section
      this.displayQueue();
    },
    fetchNetEnquiryData() {
      // console.log(this.ActiveLead);
      this.ActiveLeadDetails = this.ActiveLead;
      this.QueueRendered = true;
      if (this.callPermission === "Call" && !this.queueAddition) {
        this.InitializeCall();
      }
      this.queueAddition = false;
    },
    async updateActiveLeadDetails() {
      this.ActiveLeadDetails = await this.API_call();
    },
    fetchMissedCallData() {
      this.ActiveLeadDetails = this.ActiveLead;
      this.QueueRendered = true;
      if (this.callPermission === "Call" && !this.queueAddition) {
        this.InitializeCall();
      }
      this.queueAddition = false; 
    },
    fetchQueueData() {
      this.$vs.loading();
       let url = `${constants.SERVER_API}getQueue`;
      // let url = `https://crm.milesforce.com/api/getQueue`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // We don't want to empty the array so we r appending the new data
          console.log(response);
          //  let pushed_enquires_mobiles = [];
          if(response.data.final_ten == null || response.data.final_ten.length == 0){
            this.queue_data = true;
          }else{
          this.queue_data = false;
          response.data.final_ten.forEach((data) => {
            data.data.given_type = data.type;
            let new_hash = hash(data.data)
            if(this.processedData.indexOf(new_hash) !== -1) {
              console.log("new hash found in processed data", new_hash, this.processedData)
            }
            if (
              this.fetchedQueueData.length <= 10 &&
              this.processedData.indexOf(new_hash) == -1
            ) {
              this.processedData.push(new_hash);
              this.fetchedQueueData.push(data.data);
            }
         
          });
          console.log("queue data ", this.fetchedQueueData);    
          this.displayQueue();
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    displayQueue() {
      this.fetchedQueueData.forEach((data_point) => {
        if (data_point.given_type === "ne_visits" || data_point.given_type === "net_enquiry" || data_point.given_type === "ne_website_google" || data_point.given_type === "ne_other_sources") {
          if(typeof data_point.mobile === "string"){
               data_point.mobile = data_point.mobile.split(",");
          }
         
          data_point.dialingNumber = "";
          
          if (data_point.mobile.length > 0) {
            data_point.dialingNumber = this.getLastTenDigits(
              data_point.mobile[0]
            );
          }
          if (data_point.email !== null && data_point.email !== "") {
            if(typeof data_point.email === "string"){
                data_point.email = data_point.email.split(",");
            }  
          }
          data_point.Emails = "";
          if (data_point.email !== null && data_point.email !== "") {
            if (data_point.email.length > 0) {
              data_point.Emails = data_point.email[0];
            } 
          }
          data_point.engagements = [];
        } else {
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        }
      });
      this.$store.commit("MOUNT_MWB_LEADS", this.fetchedQueueData);
      this.ActiveLead = this.fetchedQueueData[0];
      console.log("ActiveLeaddd", this.ActiveLead);
    },
    formQueue(providedData) {
      providedData.forEach((datapoint) => {
        this.QueueData.push(datapoint);
      });
    },
    async fetchMWBDetails() {
      // let userdata = this.ActiveLead;
      let data = await this.API_call();
      eventbus.$emit("queue-disable", true);
      this.ActiveLeadDetails = data;
      this.QueueRendered = true;
      if (this.callPermission === "Call" && !this.queueAddition) {
        this.InitializeCall();
      }
      this.queueAddition = false;
    },
    API_call() {
      let getParams = {
        mwb_id: this.ActiveLead.id,
      };
      let url = `${constants.SERVER_API}getCandidateDetails`;
      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: getParams,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            if (response.data.status !== "success") {
              this.handleNotification(response);
              reject(response.data.message);
            } else {
              response.data.data.given_type = this.ActiveLead.given_type;
              resolve(response.data.data);
              // console.log(response.data.data);
            }
            // this.$vs.loading.close();
          })
          .catch((error) => {
            reject(error);
            this.handleError(error);
            this.$vs.loading.close();
          });
      });
    },
    fetchCPAdetails(userdata) {
      const mwb_id = userdata.id;
      // const mwb_id = 16112
      let url = `${constants.SERVER_API}getCpaFromMwbId?mwb_id=${mwb_id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("cpa person", response);
          const payload = response.data;
          if (payload.length > 0) {
            this.$vs.loading();
            let url = `${constants.SERVER_API}getMwbDetailsForEwb?person_id=${payload[0].cpa.person_id}&mwb_id=${payload[0].cpa.mwb_id}`;
            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              })
              .then((response) => {
                this.$store.commit("UPDATE_EWB_NEW_DATA_QUEUE", [
                  payload[0],
                  response.data.engagements,
                  response.data.mobile,
                  response.data.emails,
                  response.data.mwb,
                ]);
                this.QueueRendered = true;
                if (this.callPermission === "Call") {
                  this.InitializeCall();
                }
                // EventBus.$emit("open-student-report-popup", payload[0]);
                this.$vs.loading.close();
              })
              .catch((error) => {
                this.handleError(error);
                this.fetchMWBDetails(userdata);
                this.$vs.loading.close();
              });
          } else {
            this.fetchMWBDetails(userdata);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.openMWBPopup(userdata);
          this.$vs.loading.close();
        });
    },
    fetchCMAdetails(userdata) {
      // const mwb_id = userdata.id;
      let url = `${constants.SERVER_API}getCmaFromMwbId?mwb_id=${userdata.id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          const payload = response.data;
          if (payload.length > 0) {
            this.$vs.loading();
            let url = `${constants.SERVER_API}getMwbDetailsForEwb?person_id=${payload[0].cma.person_id}&mwb_id=${payload[0].cma.mwb_id}`;
            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              })
              .then((response) => {
                this.$store.commit("UPDATE_NEW_DATA", [
                  payload[0],
                  response.data.engagements,
                  response.data.mobile,
                  response.data.emails,
                  response.data.mwb,
                ]);
                this.QueueRendered = true;
                if (this.callPermission === "Call") {
                  this.InitializeCall();
                }
                this.$vs.loading.close();
              })
              .catch((error) => {
                this.handleError(error);
                this.fetchMWBDetails(userdata);
                this.$vs.loading.close();
              });
          } else {
            this.fetchMWBDetails(userdata);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.fetchMWBDetails(userdata);
          this.$vs.loading.close();
        });
    },
    changeCallPermission(event) {
      this.callPermission = event;
      if (event === "Call") {
        this.InitializeCall();
      }
    },
    filterNumber(number) {
      let returnNumber = "";
      if (number.indexOf("+91") > -1) {
        returnNumber = "0" + this.getLastTenDigits(number);
      } else {
        returnNumber = number;
      }
      return returnNumber;
    },
    InitializeCall() {
      setTimeout(() => {
        this.call();
      }, 1000);
    },
    call() {
      if (
        this.ActiveLead.given_type === "missed_calls" ||
        this.ActiveLead.given_type === "untracked_calls"
      ) {
        // console.log(this.ActiveLead);9
        let number = null;
        if (this.getSubTeam("INT")) {
          number = this.ActiveLead.phone_number;
        } else {
          number = this.filterNumber(this.ActiveLead.phone_number);
        }
        console.log(number);
        // return;
        axios
          .get(
            `${constants.SERVER_API}clickToCallUntracked?phone_number=${number}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .catch((error) => {
            this.handleError(error);
          });
        // console.log("should find a way to start calling leads");
      } else if (this.ActiveLead.given_type === "ne_visits" || this.ActiveLead.given_type === "net_enquiry" || this.ActiveLead.given_type === "ne_website_google" || this.ActiveLead.given_type === "ne_other_sources") {
        axios
          .get(
            `${constants.SERVER_API}clickToCallUntracked?phone_number=${this.ActiveLead.dialingNumber}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        let call_id = 0;
        if (this.ActiveLeadDetails.mobiles.length > 0) {
          console.log(this.ActiveLeadDetails.mobiles);
          // call_id = this.ActiveLeadDetails.mobiles[0].id;
          call_id = this.getTheFirstValidNumber(this.ActiveLeadDetails.mobiles);
          if (call_id === null) {
            this.showCustomNotification(
              "No Valid number",
              "No valid numbers found for this candidate",
              "danger"
            );
            return;
          }
          axios
            .get(
              `${constants.SERVER_API}clickToCallPusher?mobile_id=${call_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              }
            )
            .catch((error) => {
              this.handleError(error);
            });
        } else {
          this.$vs.notify({
            title: "Cannot initialize the call",
            text: "mobile number not available",
            color: "danger",
          });
        }
      }
    },
    getTheFirstValidNumber(mobileNumbers) {
      let valid_numbers = [];
      mobileNumbers.forEach((number) => {
        if (number.invalid === 0) {
          valid_numbers.push(number);
        }
      });
      if (valid_numbers.length === 0) {
        return null;
      }
      return valid_numbers[0].id;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
  },
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatNavbar,
    ChatLog,
    QueueContact,
    ChatContactIIML,
    // Router
    // todayWhatsappBroadcastCount,
  },
  
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
    eventbus.$emit("sidebar-config-queue", true);
    // window.onbeforeunload = this.handleExit;
  },
  beforeDestroy: function () {
    eventbus.$emit("queue-disable", true);
    window.removeEventListener("resize", this.handleWindowResize);
    eventbus.$emit("sidebar-config-queue", false);
    // eventbus.$emit("change-navbar-config", "floating");
  },
  setSidebarWidth() {
    if (this.windowWidth < 992) {
      this.isSidebarActive = this.clickNotClose = false;
    } else {
      this.isSidebarActive = this.clickNotClose = true;
    }
  },
  toggleTodoSidebar(value = false) {
    if (!value && this.clickNotClose) return;
    this.isSidebarActive = value;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/todo.scss";
.sidebar-spacer {
  width: calc(100% - 260px);
  margin-left: 290px;
}
.vs-sidebar{
  max-width: 290px;
}
.no-queue-data-found {
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: 'Montserrat';
    letter-spacing: 0.3px;
    font-size: 20px;
    font-weight: 900;
    margin-top: 280px
}
</style>