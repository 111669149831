<template>
  <div
    class="chat__contact flex items-center px-2 py-3"
    :class="{ 'bg-primary-gradient text-white shadow-lg': activeIndication }"
  >
    <div class="contact__avatar mr-3"></div>
    <div
      class="contact__container w-full flex items-center justify-between overflow-hidden"
    >
      <div class="contact__info flex flex-col truncate w-5/6">
        <vx-tooltip
          :title="CardInfo.identity"
          :text="unixTimestampConvert(CardInfo.last_call)"
        >
          <p :class="{ 'text-white': activeIndication }"> 
            {{ getTitle() }}
          </p>
        </vx-tooltip>
      </div>
      <div
        class="chat__contact__meta flex self-start flex-col items-end w-1/6"
        style="margin-right: -1.8%"
      >
        <vs-chip :color="activeIndication ? '#ffffff' : 'primary'" style="white-space: nowrap;">
          <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
            giveShortForm(CardInfo.given_type)
          }}</b>
        </vs-chip>
      </div>

      <div
        class="chat__contact__meta flex self-start flex-col items-end w-1/6 ml-3"
        v-if="showLevel"
      >
        <vs-chip
          :color="activeIndication ? '#ffffff' : getColor(CardInfo.level)"
        >
          <!-- <b v-if="CardInfo.given_type == 'u3_today' || CardInfo.given_type == 'u3_delays' || CardInfo.given_type == 'u2_today' || CardInfo.given_type == 'u2_delays' || CardInfo.given_type == 'mhps'|| CardInfo.given_type == 'flag' || CardInfo.given_type == 'waiting' || CardInfo.given_type == 'blank_u'" :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.iiml_level
          }}</b>
          <b v-else-if="CardInfo.given_type == 'u3_plus_today' || CardInfo.given_type == 'u3_plus_delays' || CardInfo.given_type == 'u3_plus_plus_today' || CardInfo.given_type == 'u3_plus_plus_delays'" :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.iiml_level
          }}</b> -->
          <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.level
          }}</b>
        </vs-chip>
        
      </div>
      <div
        class="chat__contact__meta flex self-start flex-col items-end w-1/6 ml-1"
        v-if="showLevel"
      >
      <vs-chip v-if="CardInfo.iiml_level !='' && CardInfo.iiml_level != null"
          :color="activeIndication ? '#ffffff' : getColor(CardInfo.level)"
        >
          <!-- <b v-if="CardInfo.given_type == 'u3_today' || CardInfo.given_type == 'u3_delays' || CardInfo.given_type == 'u2_today' || CardInfo.given_type == 'u2_delays' || CardInfo.given_type == 'mhps'|| CardInfo.given_type == 'flag' || CardInfo.given_type == 'waiting' || CardInfo.given_type == 'blank_u'" :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.level
          }}</b>
          <b v-else-if="CardInfo.given_type == 'u3_plus_today' || CardInfo.given_type == 'u3_plus_delays' || CardInfo.given_type == 'u3_plus_plus_today' || CardInfo.given_type == 'u3_plus_plus_delays'" :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.level
          }}</b> -->
          <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
            CardInfo.iiml_level
          }}</b>
        </vs-chip>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndication: {
      type: Boolean,
      required: true,
    },
    CardInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLevel: false,
    };
  },
  mounted() {
    // console.log("cardsss", this.CardInfo);
  },
  methods: {
    getTitle() {
      let title = "";
      if (
        this.CardInfo.given_type === "missed_calls" ||
        this.CardInfo.given_type === "untracked_calls"
      ) {
        // console.log(this.CardInfo);
        if (this.CardInfo.contact_type === "B2C") {
          title = `${this.CardInfo.person_name}`;
          this.showLevel = true;
        } else {
          this.showLevel = false;
          title = this.CardInfo.phone_number;
        }
      } else if (this.CardInfo.given_type === "ne_visits" || this.CardInfo.given_type === "net_enquiry" || this.CardInfo.given_type === "ne_website_google" || this.CardInfo.given_type === "ne_other_sources" || this.CardInfo.given_type === "ne_superbot" || this.CardInfo.given_type === "ne_website_google_without_superbot") {
        if(this.CardInfo.name === "") {
          title = `${this.CardInfo.mobile[0]}`;
          this.showLevel = false;
        } else {
          title = `${this.CardInfo.name}`;
          this.showLevel = false;
        }
        
      } else {
        title = this.CardInfo.person_name;
        this.showLevel = true;
      }
      return title;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3++":
          return "success";
        case "M3+":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    giveShortForm(given_type) {
      switch (given_type) {
        case "ne_superbot":
          return "NE SB";
        case "mhps_superbot":
          return "MHP SB";
        case "ne_website_google_without_superbot":
          return "NE";
        case "mhps_without_superbot":
          return "MHP";
        case "m3_plus_today":
          return "T";
        case "m3_plus_delays":
          return "D";
        case "m5_todays":
          return "T";
        case "m5_delays":
          return "D";
        case "m3_todays":
          return "T";
        case "m3_delays":
          return "D";
        case "u3_plus_plus_today":
          return "T";
        case "m6_today":
          return "T";
        case "m3_plus_plus_today":
          return "T";
        case "u3_plus_plus_delays":
          return "D";
        case "m6_delays":
          return "D";
        case "m3_plus_plus_delays":
          return "D";
        case "m7_onboarding_today":
          return "T";
        case "m7_onboarding_delays":
          return "D";
        case "m7_hash_today":
          return "T";
        case "m7_hash_delays":
          return "D";
        case "ne_website_google":
          return "NE";
        case "ne_other_sources":
          return "NE";
        case "u6_today":
          return "T";
        case "u6_delays":
          return "D";
          case "u5_today":
          return "T";
        case "u5_delays":
          return "D";
        case "u3_plus_today":
          return "T";
        case "u3_plus_delays":
          return "D";
        case "u3_today":
          return "T";
        case "u3_delays":
          return "D";
        case "u2_today":
          return "T";
        case "u2_delays":
          return "D";
        case "delays":
          return "D";
        case "blank_u":
        return "No U";
        case "waiting":
          return "W";
        case "today":
          return "T";
        case "ne_visits":
          return "NE V";
        case "net_enquiry":
          return "NE";
        case "missed_calls":
          return "M";
        case "sr_queue_priority":
          return "P";
        case "lead_visits":
          return "LV";
        case "mhps":
          return "MHP";
        case "untracked_calls":
          return "UC";
        case "untapped":
          return "UT";
        case "flag":
          return "F";
        default:
          return given_type;
      }
    },
  },
};
</script>
