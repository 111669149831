<template>
  <vs-card style="margin-bottom: 2%">
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 15px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <b>{{ userdata.calldata.phone_number }}</b> -
        <!-- <b>{{userdata.mwb.level}}</b> - -->
        <!-- <b>{{userdata.mwb.source}}</b> - -->
        <!-- <b>{{userdata.mwb.company}}</b> -->
        <!-- <b>{{userdata.mwb.person_name}}</b> -->
      </vs-col>
    </vs-row>
    <!-- <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="right"
        vs-align="flex-start"
        vs-w="3"
        >Type:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="9"
        >{{ userdata.directory }}</vs-col
      >
    </vs-row> -->
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 14px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        >Time:</vs-col
      >
      <vs-col
        style="font-size: 14px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="5"
      >
        <p>{{ unixTimestampConvert(userdata.calldata.time) }}</p>
      </vs-col>
      <vs-col
        style="font-size: 12px"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="5"
      >
        <vs-button
          color="warning"
          type="gradient"
          icon="local_phone"
          @click="open"
        ></vs-button>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
import moment from "moment";
export default {
  props: {
    userdata: {}
  },
  data() {
    return {
      untracked: false,
      untrackedPhone: ""
    };
  },
  mounted() {
    // EventBus.$on("get-sorting-missed", () => {
    //   if ((this.userdata.mwb = "")) {
    //     this.untrackedPhone = this.userdata.calldata.phone_number;
    //     this.untracked = true;
    //   } else {
    //     this.untracked = false;
    //   }
    // });
  },
  methods: {
    open() {
      console.log(this.userdata);
      // EventBus.$emit("open-missed-call-popup", this.userdata);
    },
    unixTimestampConvert(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    }
  }
};
</script>

<style></style>
