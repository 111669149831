<template>
  <div style="margin-left: 2%">
    <!-- <vs-row
      class="gaps"
      style="margin-top: 2%"
      v-if="studentDetails.mwb.courses.includes('IIML-PM')"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button color="dark" type="relief" @click="sendIIMLPAInvite()"
          >Send IIML PM Intro Email</vs-button
        >
      </vs-col>
    </vs-row> -->
     <div v-if="studentDetails.upcoming_visits.length >0" class="mt-6">
        <div class="py-3 ml-2 added_by mb-3" v-for="(upcoming_visit, i) in studentDetails.upcoming_visits" :key="i" style="background-color : gold;">

          <!-- <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="width: none; min-width: none;">
              <p class="text-data" >Upcoming Visit :</p>
              <p class="visit_data">{{getEnrolledDate(upcoming_visit.scheduled_date)}}</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="width: none; min-width: none; border-left: 2px solid rgba(0, 0, 0, 0.25)">
              <p  class="text-data" >Scheduled By :</p>
              <p class="visit_data">{{ upcoming_visit.scheduled_by_first_name }} {{ upcoming_visit.scheduled_by_last_name }}</p>
            </vs-col>
          </vs-row> -->

          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <p class="text-data" >Upcoming Visit :</p>
              <p class="visits_data">{{getEnrolledDate(upcoming_visit.scheduled_date,'time')}}</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <p  class="text-data" >Scheduled By :</p>
              <p class="visits_data">{{ upcoming_visit.scheduled_by}}</p>
              <!-- <p class="visits_data" v-else>&nbsp;CLEVERTAP</p> -->

            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
                <div class="custom-tooltip"><vs-button @click="schedule_call_popup=true;schedule_visit_id = upcoming_visit.id" radius color="warning" type="filled" icon="event_note"></vs-button>
                  <span class="tooltiptext">
                    Re-Schedule Visit
                  </span>
                </div>
              </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
              <div class="custom-tooltip"><vs-button @click="open_delete_popup=true;schedule_visit_id = upcoming_visit.id" radius color="danger" type="filled" icon="event_busy"></vs-button>
                  <span class="tooltiptext">
                    Cancel Visit
                  </span>
                </div>
            </vs-col>
          </vs-row>

        </div>
    </div>
    <div v-for="(engagement, i) in studentDetails.engagements" :key="i" class="mt-4">
      <div class="py-3 ml-2 added_by mb-3" v-if="engagement.type == 'visit' && engagement.visit_approved == 'Yes'">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="width: none; min-width: none;">
              <p class="text-data"> Visited Date : <span style="color: #6B6E67; margin-left:10px;">{{ getCreateAtDate(engagement.created_at) }}</span></p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="width: none; min-width: none; border-left: 2px solid rgba(0, 0, 0, 0.25);">
              <p class="text-data">SPOC: <span style="color: #6B6E67; margin-left:10px;">{{ engagement.assigned_spoc_name }}</span></p>
            </vs-col>
          </vs-row>
      </div>
    </div>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6" v-if="this.eligibility_report_link !== null">
        <vs-button color="dark" type="border" @click="openConfirm()"
          >Send Payment link</vs-button
        >
      </vs-col>
      <vs-col v-else vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" >
        <vs-button color="dark" type="border" @click="openConfirm()"
          >Send Payment link</vs-button
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" v-if="this.eligibility_report_link != null && this.eligibility_report_link != '' && (this.eligibility_report_link_cpa_us == '' || this.eligibility_report_link_cpa_us == null)">
        <a :href="this.eligibility_report_link" target="_blank" rel="noopener noreferrer">
          <vs-button color="dark" type="border">Eligibility Report</vs-button>
        </a>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" v-if="this.eligibility_report_link_cpa_us != null && this.eligibility_report_link_cpa_us != ''">
        <a :href="this.eligibility_report_link_cpa_us" target="_blank" rel="noopener noreferrer">
          <vs-button color="dark" type="border">Eligibility Report</vs-button>
        </a>
      </vs-col>
      <!--  -->
    </vs-row>
    <vs-row class="mt-4 mb-4">
        <vs-col  vs-w="3.5">
          <vs-checkbox
          color="dark"
          class="inline-flex"
          disabled="true"
          v-model="cpa_eligibility"
          >CPA Eligibility</vs-checkbox
        >
        </vs-col>
        <vs-col vs-w="3.5">
          <vs-checkbox
          color="dark"
          class="inline-flex"
          disabled="true"
          v-model="usp_eligibility"
          >USP Eligibility</vs-checkbox
        >
        </vs-col>
        <vs-col vs-w="5">
          <vs-checkbox
          color="dark"
          class="inline-flex"
          disabled="true"
          v-model="bridge_course_eligibility"
          >Bridge Course Eligibility</vs-checkbox
        >
        </vs-col>
    </vs-row>

    <div class="px-2">
    <vs-collapse class="collapse_card mt-4" v-if='bridge_course_eligibility'>
    <vs-collapse-item>
      <div slot="header">
        Bridge Course Recommended : {{  studentDetails.bridge_course_recommended }}
      </div>
      <vs-row>
        <vs-col vs-w="3" style="padding-left: 0;">
          Electives Choosen : 
        </vs-col>
        <vs-col vs-w="6">
              <div v-for="(elective, i) in studentDetails.recommend_arka_elective_ids" :key="i">
                    <vs-checkbox
                    style="font-size: 12px;"
                    color="dark"
                    class="inline-flex"
                    disabled="true"
                    v-model="electives"
                    >{{ elective }}</vs-checkbox
                  >
                  </div>
        </vs-col>
      </vs-row>
    </vs-collapse-item>
    </vs-collapse>
    </div>
    
    <div class="px-2">
    <div class="boxed mt-4">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b style="font-size: 15px; color: gray">Previous Payment Links</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap" v-show="paymentTable">
          <vs-table :data="this.PaymentLinks" style="width: 96%;">
            <template slot="thead">
              <vs-th>
                Course
              </vs-th>
              <vs-th>Agreed Cost</vs-th>
              <!-- <vs-th>Payment Links</vs-th> -->
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody ref="tbody" >
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div>
                      {{ tr.course }}
                    </div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.agreed_cost }}</div>
                  </vs-td>
                  <!-- <vs-td style="">
                    <div :id="tr.course" :title="tr.payment_link">
                      {{ tr.agreed_cost }}
                    </div>
                  </vs-td> -->
                  <vs-td>
                    <vs-button
                      size="small"
                      color="primary"
                      @click="resendLink(tr)"
                      >Resend Payment Link</vs-button
                    >
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
      </vs-row>
      <vs-row class="gap" v-show="paymentERROR">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          {{ this.errorpayment }}
        </vs-col>
      </vs-row>
    </div>
  </div>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        :style="{ color: 'gray' }"
        >Name:</vs-col
      >
      <vs-col
        v-if="nameEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        :style="{ color: 'gray' }"
      >
        <b>{{ studentDetails.mwb.person_name }}</b>
      </vs-col>
      <vs-col
        v-else-if="nameEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <vs-input icon="edit" v-model="editName" />
      </vs-col>
      <vs-col
        v-if="nameEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          color="warning"
          size="small"
          type="border"
          icon="create"
          @click="nameEditFunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="nameEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          size="small"
          icon="done_outline"
          @click="editNameAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row v-if="show_source" class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Source:</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
        style="color: gray"
        >{{ studentDetails.mwb.source }} - {{ studentDetails.mwb.source_details }}</vs-col
      >
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Mobile:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <div
          v-for="(mob, index) in studentDetails.mobiles"
          :key="index"
          style="margin-left: 1%"
          :data-tooltip="mob.masked_number"
        >
          <!-- <span class="tooltiptext">
            <b>{{ mob.masked_number }}</b>
          </span> -->
          <vs-button
            size="small"
            :color="getMobileColor(mob)"
            :type="mob.invalid === 0 ? 'gradient' : 'filled'"
            icon="local_phone"
            @click="mobilePopup(mob)"
          >
            <!-- <vs-icon icon="lcal_phone "></vs-icon> -->
          </vs-button>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="7">
        <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="local_phone"
          size="small"
          @click="EmitEvent('add-mobile-number', studentDetails.mwb)"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Email:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <div
          v-for="(email, indexemail) in studentDetails.emails"
          :key="indexemail"
          style="margin-left: 1%"
          :data-tooltip="email.masked_email"
        >
          <!-- <span class="tooltiptext">
            <b>{{ email.masked_email }}</b>
          </span> -->
          <vs-button
            style="margin-left: 1%"
            :color="getEmailColor(email)"
            type="gradient"
            size="small"
            icon="email"
            @click="emailPopup(email)"
          ></vs-button>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="7">
        <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          size="small"
          icon="email"
          @click="EmitEvent('add-email', studentDetails.mwb)"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <!-- <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="1"
        style="color: gray"
        >Course:</vs-col
      > -->
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="CPA"
          >CPA </vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="CPA-AA"
          >CPA-AA</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="USP"
          >USP</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="CMA"
          >CMA</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="CFA"
          >CFA</vs-checkbox
        >
    </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="FRM"
          >FRM</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="USP"
          >USP</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="AICPA"
          >AICPA </vs-checkbox
        >
        <!-- <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="CISA"
          >CISA</vs-checkbox
        > -->
        <!-- <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="EA"
          >EA</vs-checkbox
        ><vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="CIA"
          >CIA</vs-checkbox
        > -->
        <!-- <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="RPO"
          >RPO</vs-checkbox
        > -->
        <!-- <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIML-FA"
          >IIML-FA</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIML-FT"
          >IIML-FT</vs-checkbox
        > -->
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-checkbox
          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIMI-AA"
          >IIMI-AA</vs-checkbox
        >
        <vs-checkbox

          v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIML-SF"
          >IIML-SF</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIML-BA"
          >IIML-BA</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIML-PM"
          >IIML-PM</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIML-HR"
          >IIML-HR</vs-checkbox
        >
      </vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IITR-BF"
          >IITR-BF</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IITR-DB"
          >IITR-DB</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IITM-AA"
          >IITM-AA</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IIMK-CX"
          >IIMK-CX</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="true"
          color="dark"
          v-model="course"
          vs-value="IITM-FS"
          >IITM-FS</vs-checkbox
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="IITR-CC"
          style="margin-right: 1%"
          >IITR-CC</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="IIMK-FT"
          style="margin-right: 1%"
          >IIMK-FT</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="IIML-AB"
          style="margin-right: 1%"
          >IIML-AB</vs-checkbox
        >
        <vs-checkbox
              v-if="checkIfRoleis('exed2')"
              disabled="disable"
              color="dark"
              v-model="course"
              vs-value="IIML-SH"
              style="margin-right: 1%"
              >IIML-SH</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="IITJ-DE"
          style="margin-right: 1%"
          >IITJ-DE</vs-checkbox
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="XLRI-HR"
          style="margin-right: 1%"
          >XLRI-HR</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="XLRI-SH"
          style="margin-right: 1%"
          >XLRI-SH</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="IIMI-BA"
          style="margin-right: 1%"
          >IIMI-BA</vs-checkbox
        >
        <vs-checkbox
          v-if="checkIfRoleis('exed2')"
          disabled="disable"
          color="dark"
          v-model="course"
          vs-value="XLRI-DM"
          style="margin-right: 1%"
          >XLRI-DM</vs-checkbox
        >
      </vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps"> </vs-row> -->
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Company:</vs-col
      >
      <vs-col
        v-if="companyEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <b>{{ studentDetails.mwb.company }}</b>
      </vs-col>
      <vs-col
        v-else-if="companyEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <vs-input icon="edit" v-model="editCompany" />
      </vs-col>
      <vs-col
        v-if="companyEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          size="small"
          color="warning"
          type="border"
          icon="create"
          @click="companyeditfunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="companyEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          size="small"
          color="warning"
          type="border"
          icon="done_outline"
          @click="editCompanyAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Experience({{ studentDetails.mwb.experience }}):</vs-col
      >
      <vs-col
        v-if="exEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <b>{{ studentDetails.mwb.experience_int }}</b>
      </vs-col>
      <vs-col
        v-else-if="exEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <vs-input icon="edit" v-model="editExperience" />
      </vs-col>
      <vs-col
        v-if="exEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          size="small"
          color="warning"
          type="border"
          icon="create"
          @click="exeditfunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="exEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          size="small"
          color="warning"
          type="border"
          icon="done_outline"
          @click="editExperienceAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Graduation Year :</vs-col
      >
      <vs-col
        v-if="studentDetails.persona_details"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
        style="color: gray"
        >{{ studentDetails.persona_details.graduation_year }}</vs-col
      >
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >College:</vs-col
      >
      <vs-col
        v-if="collegeEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <b>{{ studentDetails.mwb.college }}</b>
      </vs-col>
      <vs-col
        v-else-if="collegeEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <vs-input icon="edit" v-model="editCollege" />
      </vs-col>
      <vs-col
        v-if="collegeEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          size="small"
          color="warning"
          type="border"
          icon="create"
          @click="collegeEditFunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="collegeEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          size="small"
          color="warning"
          type="border"
          icon="done_outline"
          @click="editCollegeAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="color: gray"
        >Designation:</vs-col
      >
      <vs-col
        v-if="designationEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <b>{{ studentDetails.mwb.designation }}</b>
      </vs-col>
      <vs-col
        v-else-if="designationEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="color: gray"
      >
        <vs-input icon="edit" v-model="editDesignation" />
      </vs-col>
      <vs-col
        v-if="designationEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          size="small"
          icon="create"
          @click="designationeditfunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="designationEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="color: gray"
      >
        <vs-button
          radius
          color="warning"
          size="small"
          type="border"
          icon="done_outline"
          @click="editDesignationAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray">Archived Education:</p>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="8"
        style="color: gray"
      >
        <b>{{ studentDetails.mwb.education }}</b>
      </vs-col>
    </vs-row> -->
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray">Educational Tags:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <!-- <v-select
          placeholder="Badges"
          multiple
          style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="select1"
          :options="options1"
        ></v-select> -->
        <treeselect v-model="select1" openDirection="bottom" :multiple="true" :options="options1"
          style="border: 1px solid #0044ba;border-radius: 5px;" />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <!-- <p style="color: gray; font-size: 15px">Educational Tags:</p> -->
        <vs-button size="small" color="danger" type="gradient" @click="updateEducation">
          <b>Update</b>
        </vs-button>
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Educational Tags:</p>
      </vs-col>-->
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
        <vs-chip
          @click="remove(chip)"
          closable
          color="primary"
          v-for="chip in select1"
          :key="chip.index"
        >
          <b>{{ chip }}</b>
        </vs-chip>
      </vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="color: gray"
        >Eligibility Submitted Documents:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxdisable"
          v-model="submitted_documents"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          v-if="submit === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="eligibility"
          :options="eligibilityOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="submit === true"
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="eligibility"
          :options="eligibilityOptions"
          label="text"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="danger"
          type="gradient"
          v-if="eligibility !== ''"
          @click="addEligibility"
        >
          <b>Update</b>
        </vs-button>
      </vs-col>
    </vs-row> -->
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="color: gray"
        >Requested For Loan Assistance</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxloandisable"
          v-model="applied_for_loan"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          v-if="submitLoan === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="loan"
          :options="loanOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="submitLoan === true"
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="loan"
          :options="loanOptions"
          label="text"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="danger"
          type="gradient"
          v-if="loan !== ''"
          @click="addLoan"
        >
          <b>Update</b>
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-popup
      id="paymentid"
      title="Send Payment Link"
      style="z-index: 53010"
      :active.sync="activePrompt"
    >
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="radios1" vs-value="course"
            ><b>Course Purchase</b></vs-radio
          >
        </vs-col>

         <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="radios1" vs-value="Additional Purchase"
            ><b>Additional Purchase</b></vs-radio
          >
        </vs-col> -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-radio color="dark" v-model="radios1" vs-value="Miscellaneous"
            ><b>Miscellaneous Purchase</b>&nbsp;&nbsp;<b>(Processing Fee 2500)</b></vs-radio
          >
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 0%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
        </vs-col>
        <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
          style="margin-left: 3%;margin-top: -1%;"
        >
          <b>(Processing Fee 2500)</b>
        </vs-col> -->
      </vs-row>
      <vs-row
        class="gap"
        style="margin-top: 4%"
        v-if="this.radios1 === 'course'"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>Course</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-stary"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            placeholder="Courses"
            style="z-index: 1000; margin-top: 4%; margin: 10px; margin-left: 0%;"
            class="w-full"
            v-model="purchase_courses"
            :options="purchaseCourseList"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row
        class="gap"
        style="margin-top: 4%"
        v-if="this.radios1 === 'Additional Purchase'"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>Additional Amount:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-input
            class="inputx"
            placeholder="Additional Amount"
            v-model="additional_cost"
            style="width:97%"
          />
        </vs-col>
      </vs-row>
      <vs-row
        class="gap"
        style="margin-top: 4%"
        v-if="this.radios1 === 'course'"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>Agreed Cost</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-input
            class="inputx"
            placeholder="Agreed Cost"
            v-model="purchase_agreedcost"
            style="width:97%"
          />
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>Primary Mobile:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>{{ this.primary_mobile }}</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>Primary Email:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>{{ this.primary_email }}</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="success" type="border" @click="sendPaymentlink"
            >Accept</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button color="danger" @click="activePrompt = false" type="border"
            >Cancel</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row v-if="this.Areusure === true">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="8">
            <div style="font-size: 16px"><b>Are you sure you want to send {{ this.PurchaseCourse }} Purchase link for Rs:{{ this.AGreedCost }} to {{ this.PersonName }} with contact details Mobile: {{ this.primary_mobile }} and Email: {{ this.primary_email }}?</b></div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row style="margin-top: 4%">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">

          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <vs-button color="success" type="border" @click="Areusurelink"
              >Yes</vs-button
            >
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
          </vs-col>
        </vs-row>
      </vs-row>
    </vs-popup>
    <vs-popup class="holamundo reschedule_popup" title="Reschedule Call" :active.sync="schedule_call_popup" style="border-radius: 15px !important">
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents"><vs-icon icon="calendar_today"></vs-icon>&nbsp;<span class="schedule_title">&nbsp; Select Date :</span></p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <datepicker v-if="!getrole('exed2')" :disabledDates="disabledDates" placeholder="Select Date" 
            :format="customFormatter" v-model="dates" style="position: fixed;z-index: 2000;border: 1px solid #0044ba;border-radius: 5px;width: 205px;"></datepicker>
          <datepicker v-else placeholder="Select Date" :disabledDates="disabledDates" :format="customFormatter" v-model="dates" style="position: fixed;z-index: 2000;border: 1px solid #0044ba;border-radius: 5px;width: 205px;"></datepicker>
        </vs-col>
      </vs-row>
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="schedule"></vs-icon>&nbsp;
            <span class="schedule_title">&nbsp; Select Time :</span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vue-timepicker :minute-range="[0, 6, [10, 30], 42, 50]" :minute-interval="30" format="HH:mm"
            v-model="schedule_time" class="myTimePicker" style="position: fixed;z-index: 1500;border: 1px solid #0044ba;border-radius: 3px;width: 205px;">
          </vue-timepicker>
        </vs-col>
      </vs-row>

      <div style="display: flex; justify-content: center; margin-top: 2%">
        <vs-button color="#0044ba" @click="reScheduleCall">Okay</vs-button>
      </div>
    </vs-popup>
    <vs-popup class="holamundo"  title="Cancel Visit" :active.sync="open_delete_popup">
      <vs-row>Are you sure, do you want to cancel this visit ?</vs-row><br/>
      <vs-row>
        <vs-col vs-type='flex' vs-align='flex-end' vs-justify='flex-end' vs-w='12'>
          <vs-button @click='open_delete_popup = false'>No</vs-button>&nbsp;
          <vs-button color="danger" @click="cancelVisit()">Yes</vs-button>
        </vs-col>
      </vs-row>	
    </vs-popup>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import constants from "../../../constants.json";
import VueTimepicker from "vue2-timepicker";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "vue-select/dist/vue-select.css";
// import swal from "sweetalert2/src/sweetalert2.js";
// import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    "v-select": vSelect,
    Treeselect,
    VueTimepicker,
    Datepicker
  },
  props: {
    studentDetails: {
      required: true,
    },
  },
  data() {
    return {
      open_delete_popup:false,
      schedule_visit_id:null,
      schedule_call_popup: false,
      dates: "",
      disabledDates: {
        to: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 5),
        from: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 26),
      },
      schedule_time: {
        HH: null,
        mm: null,
      },
      eligibility_report_link: "",
      eligibility_report_link_cpa_us: "",
      options1 : [],
      Areusure: false,
      AGreedCost: "",
      PurchaseCourse: "",
      PersonName: "",
      additional_cost: "",
      paymentTable: false,
      paymentERROR: false,
      errorpayment: "",
      PaymentLinks: [],
      primary_mobile: "",
      primary_email: "",
      purchase_agreedcost: "",
      purchase_courses: "",
      purchaseCourseList: [
        "CPA",
        // "CIA",
        "CPA-AA",
        "USP",
        'AICPA',
        "CMA",
        "CFA",
        "FRM",
        // "CISA",
        "IIMI-BA",
        "IIMI-AA",
        "XLRI-HR",
        "XLRI-SH",
        // "RPO",
        "IIML-FA",
        "IIML-HR",
        "IIML-PM",
        "IIML-BA",
        "ITR-BF",
        "IIML-SF",
        "IIML-FT",
        "IITR-DB",
        "IITM-AA",
        "IIMK-CX",
      ],
      radios1: "",
      activePrompt: false,
      loanCode: "",
      eligibilityCode: "",
      applied_for_loan: "",
      checkboxloandisable: false,
      navTitleName: "",
      // options1: [
      //   "PUC",
      //   "INTER",
      //   "B.Com",
      //   "B.Com(P)",
      //   "M.Com",
      //   "M.Com(P)",
      //   "MBA",
      //   "MBA(P)",
      //   "CA",
      //   "CWA",
      //   "CS",
      //   "BMS",
      //   "BMS(P)",
      //   "BBA",
      //   "BBA(P)",
      //   "PGD",
      //   "PGD(P)",
      //   "LLB",
      //   "LLB(P)",
      //   "B.Tech",
      //   "B.Tech(P)",
      //   "B.Sc",
      //   "B.Sc(P)",
      //   "BCA",
      //   "BA",
      //   "BA(P)",
      //   "CA-Inter",
      //   "CWA-Inter",
      //   "CS-Inter",
      //   "PG(P)",
      //   "CA Final",
      //   "CWA Final",
      //   "CS Final",
      //   "IGNOU - B.Com (CA)",
      //   "IGNOU - B.Com (CWA)",
      //   "IGNOU - B.Com (CS)",
      //   "MCA",
      //   "PGDPA",
      //   "PGDSF",
      //   "PGDM",
      //   "BBM",
      //   "SCESA",
      //   "CFA",
      //   "FRM",
      //   "CFA(P)",
      //   "MFA",
      //   "MFA(P)",
      //   "CMA(US)",
      //   "CPA(US)",
      //   // { text: "", value: 0 },
      //   // { text: "B.Com", value: 0 },
      //   // { text: "B.Com(P)", value: 18 },
      //   // { text: "M.Com", value: 1 },
      //   // { text: "M.Com(P)", value: 19 },
      //   // { text: "MBA", value: 2 },
      //   // { text: "MBA(P)", value: 20 },
      //   // { text: "CA", value: 3 },
      //   // { text: "CWA", value: 4 },
      //   // { text: "CS", value: 5 },
      //   // { text: "BMS", value: 6 },
      //   // { text: "BMS(P)", value: 21 },
      //   // { text: "BBA", value: 7 },
      //   // { text: "BBA(P)", value: 17 },
      //   // { text: "PGD", value: 8 },
      //   // { text: "PGD(P)", value: 22 },
      //   // { text: "LLB", value: 9 },
      //   // { text: "LLB(P)", value: 23 },
      //   // { text: "B.Tech", value: 10 },
      //   // { text: "B.Tech(P)", value: 24 },
      //   // { text: "B.Sc", value: 11 },
      //   // { text: "B.Sc(P)", value: 25 },
      //   // { text: "BA", value: 12 },
      //   // { text: "BA(P)", value: 26 },
      //   // { text: "CA-Inter", value: 13 },
      //   // { text: "CWA-Inter", value: 14 },
      //   // { text: "CS-Inter", value: 15 },
      //   // { text: "PG(P)", value: 16 },
      //   // { text: "CA Final", value: 16 },
      //   // { text: "CWA Final", value: 16 },
      //   // { text: "CS Final", value: 16 },
      //   // // { text: "IGNOU", value: 16 },
      //   // { text: "IGNOU - B.Com (CA)", value: 16 },
      //   // { text: "IGNOU - B.Com (CWA)", value: 16 },
      //   // { text: "IGNOU - B.Com (CS)", value: 16 },
      //   // { text: "MCA", value: 16 },
      //   // { text: "PGDPA", value: 16 },
      //   // { text: "PGDSF", value: 16 },
      //   // { text: "PGDM", value: 16 },
      //   // { text: "BBM", value: 16 },
      //   // { text: "SCESA", value: 16 }
      // ],
      cityOptions: ["HYD", "BNG", "BOM"],
      eligibilityOptions: ["Pending", "Clear", "Check", "PGDPA"],
      loanOptions: [
        "WIP",
        "Not Interested",
        "Not Eligible",
        "Postponed",
        "Rejected",
        "Processed",
        "Disbursed",
        "SELF",
      ],
      editCompany: this.studentDetails.mwb.company,
      editCollege: this.studentDetails.mwb.college,
      editDesignation: this.studentDetails.mwb.designation,
      editExperience: this.studentDetails.mwb.experience_int,
      companyEdit: false,
      companyEditbutton: true,
      collegeEdit: false,
      collegeEditbutton: true,
      nameEdit: false,
      nameEditbutton: true,
      editName: this.studentDetails.mwb.person_name,
      designationEdit: false,
      designationEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      city: "",
      additionalInfo: "",
      eligibility: "",
      loan: "",
      select1: [],
      submitted_documents: "",
      engagementsload: [],
      getMaskedNumber: [],
      course: [],
      submit: false,
      submitLoan: false,
      enablebutton: false,
      enablebuttonloan: false,
      checkboxdisable: false,
      cpa_eligibility: '',
      usp_eligibility: '',
      bridge_course_eligibility: '',
      show_source: true,
    };
  },
  watch: {
    eligibility: function () {
      this.enablebutton = true;
    },
    // submit: function(value) {
    //   switch (value) {
    //     case true:
    //       this.eligibility = "Pending";
    //       break;
    //     case false:
    //       this.eligibility = "";
    //       this.enablebutton = false;
    //       break;
    //   }
    // },
    submitted_documents: function (value) {
      this.submit = value;
    },
    applied_for_loan: function (value) {
      this.submitLoan = value;
    },
    // submitLoan: function(value) {
    //   switch (value) {
    //     case true:
    //       if (this.studentDetails.loan_status === "") {
    //         this.loan = "WIP";
    //       } else {
    //         this.loan = this.studentDetails.loan_status;
    //       }
    //       this.enablebuttonloan = true;
    //       break;
    //     case false:
    //       this.loan = "";
    //       this.enablebuttonloan = false;
    //   }
    // }
  },
  mounted() {
    let login_team = localStorage.getItem("team");
    if(login_team == 'CM'){
      this.show_source = false;
    }
    this.disabledDates.from = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 15
      );
      this.disabledDates.to = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    this.dates = new Date(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() +1
      ));
    this.cpa_eligibility = this.studentDetails.cpa_eligibility
    this.usp_eligibility = this.studentDetails.usp_eligibility
    this.bridge_course_eligibility = this.studentDetails.bridge_course_eligibility
    this.getPurchaseLinks();
    this.getEducationTags();
    this.eligibility_report_link = this.studentDetails.eligibility_report_link;
    this.eligibility_report_link_cpa_us = this.studentDetails.eligibility_report_link_cpa_us;
    this.course = [];
    this.course = this.studentDetails.mwb.courses.split(",");
    console.log("studentDetails...", this.studentDetails);
    // this.select1 = [];
    if (this.studentDetails.mwb.education_tags !== null) {
      this.select1 = this.studentDetails.mwb.education_tags.split(",");
    } else {
      this.select1 = [];
    }
    // this.select1.push(this.studentDetails.education)
    if (this.studentDetails.mwb.submitted_documents !== "No") {
      this.submitted_documents = true;
      this.checkboxdisable = true;
      // console.log(this.studentDetails.eligibility, "eligibility");
      this.eligibility = this.studentDetails.mwb.eligibility;
    }
    if (this.studentDetails.mwb.applied_for_loan === 1) {
      // console.log(this.studentDetails.loan_status, "loan status");
      this.applied_for_loan = true;
      this.loan = this.studentDetails.mwb.loan_status;
      this.checkboxloandisable = true;
    }
  },
  methods: {
    getEnrolledDate(date){
      const formattedDate = moment.unix(date).format('DD-MMM-YYYY, h:mm a');
      return formattedDate;
    },
    getCreateAtDate(date){
      return moment(date).format("DD-MMM-YYYY");
    },
    openConfirm() {
      if (!this.primaryExists(this.studentDetails.emails)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary email is required to start the enrollment process",
          "danger"
        );
        return;
      } else if (!this.primaryExists(this.studentDetails.mobiles)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary mobile number is required to start the enrollment process",
          "danger"
        );
        return;
      }
      document.getElementById("paymentid").children[1].style.width = "65%";
      this.radios1 = "";
      this.purchase_courses = "";
      this.purchase_agreedcost = "";
      this.additional_cost = "";
      this.PersonName = this.studentDetails.mwb.person_name;
      this.activePrompt = true;
      this.studentDetails.mobiles.forEach((mobile) => {
        if (mobile.pri_mary === 1) {
          this.primary_mobile = mobile.phone_number;
        }
      });
      this.studentDetails.emails.forEach((email) => {
        if (email.pri_mary === 1) {
          this.primary_email = email.email;
        }
      });
    },
    copyText(id) {
      console.log("id", id);
      var copyText = document.getElementById(id).title;
      console.log("Copied: ", document.getElementById(id).title);
      document.addEventListener(
        "copy",
        function(event) {
          event.clipboardData.setData("text/plain", copyText);
          event.preventDefault();
        },
        true
      );
      document.execCommand("copy");

      this.$vs.notify({
        title: "Link Copied",
        color: "success",
      });
    },
    resendLink(tr) {
      console.log(tr)
      let obj = {
        purchase_id: tr.purchase_id
      }
      axios
        .get(`${constants.SERVER_API}resendPaymentLink`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
              .then((response) => {
                console.log(response);
                // EventBus.$emit("reload-bigpopup-data");
                this.$vs.notify({
                  title: response.data.message,
                  color: "success",
                });
                this.getPurchaseLinks();
              })
              .catch((error) => {
                this.handleError(error);
              });

    },
    sendPaymentlink() {

      if (this.radios1 === "Miscellaneous") {
        this.AGreedCost = "2500";
        this.PurchaseCourse = "Miscellaneous";
      } else if(this.radios1 === "Additional Purchase") {
        this.AGreedCost = this.additional_cost;
        this.PurchaseCourse = "Additional Purchase";
      } else {
        this.AGreedCost = this.purchase_agreedcost;
        this.PurchaseCourse = this.purchase_courses;
      }

      this.Areusure = true;
      // this.Areusurelink(params);

          // }
        // });
    },
    Areusurelink() {
      this.$vs.loading();
      let params = {
        mwb_id: this.studentDetails.mwb.id,
        course: this.PurchaseCourse,
        type: this.radios1,
        amount: this.AGreedCost,
      };
      console.log("data", params);
      let url = `${constants.SERVER_API}sendPaymentLinkEmail`;

            console.log();
            axios
              .post(url, params, {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              })
              .then((response) => {
                console.log(response);
                this.activePrompt = false;
                EventBus.$emit("reload-bigpopup-data");
                this.$vs.notify({
                  title: response.data.message,
                  color: "success",
                });
                this.getPurchaseLinks();
                this.$vs.loading.close();
              })
              .catch((error) => {
                this.handleError(error);
              });
    },
    getPurchaseLinks() {
      let obj = {
        person_id: this.studentDetails.mwb.person_id,
      };
      console.log("data", obj);
      axios
        .get(`${constants.SERVER_API}getPurchaseLinks`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Links", response.data);
          if(response.data.status === "success") {
            this.PaymentLinks = response.data.purchase_links;
            this.paymentTable = true;
            this.paymentERROR = false;
          } else if(response.data.status === "error") {
            this.paymentTable = false;
            this.paymentERROR = true;
            this.errorpayment = response.data.message;
          }

          // response.data.purchase_links.forEach(element => {
          //   console.log("data", element)
          // });
          // this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    primaryExists(PurchaseData) {
      console.log("dim", PurchaseData);
      let bool = false;
      PurchaseData.forEach((item) => {
        if (item.pri_mary === 1) {
          bool = true;
        }
      });
      return bool;
    },
    sendIIMLPAInvite() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
      };

      axios
        .get(`${constants.SERVER_API}sentIIMLPAIntorEmail`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
          2;
        });
    },
    getMobileColor(mob) {
      let color = "";
      if (mob.invalid === 1) {
        color = "danger";
      } else if (mob.pri_mary === 1) {
        color = "success";
      } else {
        color = "primary";
      }
      return color;
    },
    getEmailColor(mob) {
      let color = "";
      if (mob.invalid === 1) {
        color = "danger";
      } else if (mob.pri_mary === 1) {
        color = "success";
      } else {
        color = "primary";
      }
      return color;
    },
    async addLoan() {
      let sendValue = "";
      // console.log(this.requested_loan)
      switch (this.applied_for_loan) {
        case true:
          sendValue = "Yes";
          break;
        case false:
          sendValue = "No";
          break;
      }
      // console.log(sendValue)
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        applied_for_loan: sendValue,
        loan_status: this.loan,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addAppliedForLoan`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.checkboxloandisable = true;
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
          }
          // console.log(response);
          // if (response.data.status === "success") {
          //   this.$vs.notify({
          //     title: "Updated",
          //     text: "Successfully updated",
          //     color: "success",
          //     position: "top-center"
          //   });
          //   this.$store.commit("UPDATE_LOAN", [
          //     this.studentDetails,
          //     sendValue,
          //     this.loan
          //   ]);
          // } else {
          //   this.$vs.notify({
          //     title: "Not Updated",
          //     text: response.data.message,
          //     color: "danger",
          //     position: "top-center"
          //   });
          // }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
    },
    async addEligibility() {
      let submitvalue = "";
      switch (this.submitted_documents) {
        case true:
          submitvalue = "Yes";
          break;
        case false:
          submitvalue = "No";
          break;
      }
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        eligibility: this.eligibility,
        submitted_documents: submitvalue,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addEligibility`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.checkboxdisable = true;
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
          }
          // if (response.data.status === "success") {
          //   this.$vs.notify({
          //     title: "Updated",
          //     text: "Successfully updated",
          //     color: "success",
          //     position: "top-center"
          //   });
          //   this.$store.commit("UPDATE_ELIGIBILITY", [
          //     this.studentDetails,
          //     submitvalue,
          //     this.eligibility
          //   ]);
          // } else {
          //   this.$vs.notify({
          //     title: "Not Updated",
          //     text: "An Error occured while updating, please try again",
          //     color: "danger",
          //     position: "top-center"
          //   });
          // }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
      // console.log(postreq)
    },
    async mobilePopup(mobile) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewMobileNumberNew?mobile_id=${mobile.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-mobile-options-popup", [
            response.data,
            this.studentDetails.mwb,
            mobile,
          ]);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
        });
    },
    updateEducation() {
      // console.log(this.select1.join());
      // console.log(this.studentDetails);
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        education_tags: this.select1.join(),
      };
      axios
        .post(`${constants.SERVER_API}editEducation`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          // this.$vs.notify({
          //   title: response.data.status,
          //   text: response.data.message,
          //   color: "success"
          // });
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
          }
          // console.log(response);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
    },
    async emailPopup(email) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewEmailIdNew?email_id=${email.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-email-options-popup", [
            response.data,
            this.studentDetails.mwb,
            email,
          ]);
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    remove(item) {
      this.select1.splice(this.select1.indexOf(item), 1);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;

    },
    collegeEditFunc() {
      this.collegeEdit = !this.collegeEdit;
      this.collegeEditbutton = !this.collegeEditbutton;
    },
    nameEditFunc() {
      this.nameEdit = !this.nameEdit;
      this.nameEditbutton = !this.nameEditbutton;
    },
    editNameAPI() {
      // this.nameEditFunc();
      let obj = {
        person_id: this.studentDetails.mwb.person_id,
        new_person_name: this.editName,
      };
      axios
        .post(`${constants.SERVER_API}editCandidateNameInMwb`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(obj);
          console.log(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
            this.$store.commit("UPDATE_NAME", [this.studentDetails, this.editName]);
            this.nameEditFunc();
            this.handleNotification(response);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the name of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async editCompanyAPI() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        edited_company: this.editCompany,
      };
      axios
        .post(`${constants.SERVER_API}editCompany`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(obj);
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
            this.companyeditfunc();
          }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger"
          //   });
          // }
        });
    },
    async editCollegeAPI() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        edited_college: this.editCollege,
      };
      axios
        .post(`${constants.SERVER_API}editCollege`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(obj);
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
            this.collegeEditFunc();
          }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger"
          //   });
          // }
        });
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    async editDesignationAPI() {
      axios
        .post(
          `${constants.SERVER_API}editDesignation`,
          {
            mwb_id: this.studentDetails.mwb.id,
            person_id: this.studentDetails.mwb.person_id,
            edited_designation: this.editDesignation,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
            this.designationeditfunc();
          }
          // if (response.data.status === "success") {
          //   this.$store.commit("UPDATE_DESIGNATION", [
          //     this.studentDetails,
          //     this.editDesignation
          //   ]);
          //   this.designationeditfunc();
          //   this.$vs.notify({
          //     title: "Success",
          //     text: "Designation details successfully updated",
          //     color: "success"
          //   });
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text:
          //       "An Error occured while editing the designation of this lead",
          //     color: "danger"
          //   });
          // }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger"
          //   });
          // }
          this.handleError(error);
        });
      // console.log(postreq)
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    async editExperienceAPI() {
      const obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        edited_experience: this.editExperience,
      };
      axios
        .post(`${constants.SERVER_API}editExperience`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("update-activelead-data");
            this.exeditfunc();
          }
          // if (response.data.status === "success") {
          //   this.$store.commit("UPDATE_EXPERIENCE", [
          //     this.studentDetails,
          //     this.editExperience
          //   ]);
          //   this.exeditfunc();
          //   this.$vs.notify({
          //     title: "Success",
          //     text: "Experience details successfully updated",
          //     color: "success"
          //   });
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text:
          //       "An Error occured while editing the experience of this lead",
          //     color: "danger"
          //   });
          // }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger"
          //   });
          // }
          this.handleError(error);
        });
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    getEducationTags() {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getEducationTags`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("EDUCATION TAGS", response)

          if(response.data.length > 0){
            for (let i = 0; i < response.data.length; i++) {
              this.options1.push({
                id : response.data[i],
                label : response.data[i]
              })

            }

          }
          // this.options1 = response.data
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    },
    reScheduleCall() {
      if (this.schedule_time.HH != null && this.schedule_time.mm != null && this.schedule_time.HH != "" && this.schedule_time.mm != "") {
        let schedule_date = moment(this.dates, "DD-MM-YYYY").format("DD-MMM-YYYY");
        let hours_minutes = this.schedule_time.HH.concat(":",this.schedule_time.mm);
        var str = Date.parse(schedule_date + " " + hours_minutes);
        str = str.toString();
        str = str.slice(0, -3);
        this.date_time = parseInt(str);
        this.$vs.loading();
        let obj = {
                next_call_timestamp:this.date_time,
                id:this.schedule_visit_id
              }
      axios.post(`${constants.SERVER_API}visitReschedule`, obj, {headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },})
        .then((response) => {
          console.log('shashi',response);
          this.$vs.loading.close();
          this.schedule_call_popup = false
          this.$vs.notify({
            title: "Rescheduled Visit",
            color: "success",
          });
          setTimeout(() => {
            // EventBus.$emit("visit-rescheduled");
            EventBus.$emit("change-connection-status-queue", "Visit Rescheduled");
          }, 750);
        })
        .catch((error) => {
          this.schedule_call_popup = false
          this.$vs.loading.close();
          this.handleError(error);
        });
      }
    },
    cancelVisit(){
      this.$vs.loading();
      let obj = {
        id: this.schedule_visit_id,
      };
      axios.post(`${constants.SERVER_API}cancelSchedule`, obj, {headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },})
        .then((response) => {
          console.log('shashi',response);
          this.$vs.loading.close();
          this.open_delete_popup = false
          this.$vs.notify({
            title: "Visit Canceled",
            color: "danger",
          });
          setTimeout(() => {
            // EventBus.$emit("visit-canceled");
            EventBus.$emit("change-connection-status-queue", "Visit Cancelled");
          }, 750);
        })
        .catch((error) => {
          this.open_delete_popup = false
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
  },
};
</script>

<style>
.gaps {
  margin-bottom: 4%;
}
.added_by {
  width:98% !important;
  display: flex;
  /* justify-content: start; */
  /* margin-inline: 1%; */
  /* margin-block: 20px; */
  box-sizing: border-box;
  height: fit-content;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.text-data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #000000;
}
.visit_data{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin-left:10px
}
.myTimePicker .display-time {
  border: 1px solid #ffffff;
  width: 203px !important;
  height: 37px !important;
  padding: 0.3em 0.5em;
  font-size: 1em;
}
.collapse_card {
  box-sizing: border-box;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.vs-collapse .vs-collapse-item--header{
  padding: 0;
  font-size: 14px;
  margin-left: 2%;
}
</style>
