<template>
  <div>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
      >
        <p class="heading">PURCHASES</p>
        <vs-button
          color="danger"
          type="gradient"
          size="small"
          style="margin-left:3%"
          @click="escalate(studentDetails.Books, 'BOOKS')"
        >
          <b>New Purchase</b>
        </vs-button>
      </vs-col>
    </vs-row>
    <!-- <div class="boxed" v-for="(purchase, index) in studentDetails.purchases" :key="index"> -->
    <!-- <vs-row class="gap" v-if="purchase.admin_approval_required === 1">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-chip color="danger" :style="{ opacity: '100%' }">
            <b>Admin Approval Required</b>
          </vs-chip>
        </vs-col>
    </vs-row>-->
    <!-- <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"></vs-col>
        <vs-col
          v-for="(book, index) in getPurchaseDeliverables(purchase, 'book')"
          :key="index"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px">{{ book.name }}</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b style="color: gray; font-size: 12px">Books</b>
        </vs-col>
        <vs-col
          v-for="(book, index) in getPurchaseDeliverables(purchase, 'book')"
          :key="index"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-tooltip :text="getTextforDisplay(book)">
            <vs-icon size="medium" :icon="generateicon(book)" :color="generatecolor(book)"></vs-icon>
            <p>{{ book.version }}</p>
          </vs-tooltip>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Amount Paid</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Amount Pending</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Total Amount</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">
            <vs-chip color="success">{{ purchase.amount_paid }}</vs-chip>
          </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">
            <vs-chip color="danger">{{ purchase.amount_pending }}</vs-chip>
          </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">
            <vs-chip color="dark">{{ purchase.agreed_cost }}</vs-chip>
          </b>
        </vs-col>
    </vs-row>-->
    <vx-card
      class="boxed"
      v-for="(purchase, index) in studentDetails.purchases"
      :key="index"
    >
      <div>
        <vs-row class="gap" v-if="purchase.admin_approval_required === 1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-chip color="danger" :style="{ opacity: '100%' }">
              <b style="font-size: 13px;">Admin Approval Required!</b>
            </vs-chip>
          </vs-col>
        </vs-row>
      </div>
      <div>
        <vs-table :data="getPurchasesDeliverable(purchase)">
          <template slot="thead">
            <vs-th>TYPE</vs-th>
            <vs-th>NAME</vs-th>
            <vs-th>DELIVARY STATUS</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div>{{ sortname(tr.type) }}</div>
                </vs-td>

                <vs-td v-if="tr.type === 'video_access' || tr.type === 'otb'">
                  <div>{{ tr.subject }}</div>
                </vs-td>
                <vs-td v-else>
                  <div>{{ tr.name }}</div>
                </vs-td>

                <vs-td>{{ sortdeliveryName(tr.delivery_status) }}</vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      <div slot="footer">
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="6"
          >
            <b style="font-size: 13px;">Payments:</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            style="font-size: 13px;"
            ><b style="font-size: 13px;"
              >{{ purchase.amount_paid }}/{{ purchase.agreed_cost }}</b
            ></vs-col
          >
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="6"
          >
            <b style="font-size: 13px;">Purchases Comments:</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            style="font-size: 13px;"
            >{{ purchase.comments }}</vs-col
          >
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import EventBus from "../eventbus";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      books: [],
      opacity: 0,
      opacityMeter: "0%"
    };
  },
  mounted() {
    this.books = [];
    // console.log("this", this.studentDetails);
    this.studentDetails.deliverables.forEach(deliverable => {
      if (deliverable.type === "book") {
        this.books.push(deliverable);
      }
      // let name = "";
      // if (deliverable.type === "video_access") {
      //   name = `${deliverable.subject} Video`;
      // } else if (deliverable.type === "otb") {
      //   name = `${deliverable.subject} OTB`;
      // } else {
      //   name = `${deliverable.name}`;
      // }
      // this.books.push(name);
    });
    // this.startAnimation();
  },
  methods: {
    sortdeliveryName(name) {
      // console.log(name);
      let changedName = name.replace(/_/g, " ");
      return changedName
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    sortname(name) {
      switch (name) {
        case "video_access":
          return "Video";
        case "otb":
          return "OTB";
        case "book":
          return "Book";
      }
    },
    startAnimation() {
      // let count = 0;
      // window.setInterval(function() {
      //   this.opacityMeter = (++count % 101) + "%";
      // }, 50);
      // console.log("something is happening");
    },
    upsellingcolor(number) {
      number = parseInt(number);
      if (number < 30 || number < 31) {
        return "red";
      } else {
        return "";
      }
    },
    escalate() {
      EventBus.$emit("escalate-popup", this.studentDetails);
    },
    /*     getPurchaseDeliverables(purchase, type) {
      let arr = [];
      this.studentDetails.deliverables.forEach(deliverable => {
        if (deliverable.type === type) {
          if (deliverable.purchase_id === purchase.id) {
            arr.push(deliverable);
          }
        }
      });
      return arr;
    },
 */

    getPurchasesDeliverable(purchase) {
      let arr = [];
      this.studentDetails.deliverables.forEach(deliverable => {
        if (deliverable.purchase_id === purchase.id) {
          arr.push(deliverable);
          // this.getNames(purchase);
        }
      });
      return arr;
    },
    generateicon(book) {
      // console.log("got the book for icon genereation");
      let icon = "";
      if (book.delivery_status === "delivered") {
        icon = "done";
      } else if (book.dispatch_status === "dispatched") {
        icon = "done";
      } else if (book.dispatch_status === "to_be_dispatched") {
        icon = "clear";
      } else if (book.dispatch_status === "amount_pending") {
        icon = "report_problem";
      }
      return icon;
    },
    generatecolor(book) {
      let color = "";
      if (book.delivery_status === "delivered") {
        color = "success";
      } else if (book.dispatch_status === "dispatched") {
        color = "warning";
      } else if (book.dispatch_status === "to_be_dispatched") {
        color = "danger";
      } else if (book.dispatch_status === "amount_pending") {
        color = "danger";
      }
      return color;
    },
    getTextforDisplay(book) {
      let color = "";
      if (book.delivery_status === "delivered") {
        color = "Delivered";
      } else if (book.dispatch_status === "dispatched") {
        color = "Dispatched";
      } else if (book.dispatch_status === "to_be_dispatched") {
        color = "To Be Dispatched";
      } else if (book.dispatch_status === "amount_pending") {
        color = "Amount Pending";
      }
      return color;
    }
  }
};
</script>

<style>
.boxed {
  border: 1px solid gray;
  margin-bottom: 1%;
}
</style>
