<template>
  <div
    class="chat__contact flex items-center px-2 py-3"
    :class="{ 'bg-primary-gradient text-white shadow-lg': activeIndication }"
  >
    <div class="contact__avatar mr-3"></div>
    <div
      class="contact__container w-full flex items-center justify-between overflow-hidden"
    >
      <div class="contact__info flex flex-col truncate w-5/6">
        <vx-tooltip
          :title="CardInfo.identity"
          :text="unixTimestampConvert(CardInfo.last_call)"
        >
          <p :class="{ 'text-white': activeIndication }">
            {{ getTitle() }}
          </p>
        </vx-tooltip>
      </div>
      <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
        <vs-chip :color="activeIndication ? '#ffffff' : 'primary'">
          <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
            giveShortForm(CardInfo.given_type)
          }}</b>
        </vs-chip>
      </div>

      <div class="chat__contact__meta flex self-start flex-col items-end w-2/6">
        <!-- <div>
          <vs-chip :color="activeIndication ? '#ffffff' : 'primary'">
            <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
              giveShortForm(CardInfo.given_type)
            }}</b>
          </vs-chip>
        </div> -->
        <div v-if="showLevel">
          <vx-tooltip :text="CardInfo.iiml_level"
            ><vs-chip
              :color="activeIndication ? '#ffffff' : getColor(CardInfo.level)"
            >
              <b :style="{ color: activeIndication ? 'black' : 'white' }">{{
                getIIMLText(CardInfo.iiml_level)
              }}</b>
            </vs-chip></vx-tooltip
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndication: {
      type: Boolean,
      required: true,
    },
    CardInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLevel: false,
    };
  },
  mounted() {
    // console.log(this.CardInfo);
  },
  methods: {
    getTitle() {
      let title = "";
      if (
        this.CardInfo.given_type === "missed_calls" ||
        this.CardInfo.given_type === "untracked_calls"
      ) {
        if (this.CardInfo.contact_type === "B2C") {
          title = `${this.CardInfo.person_name}`;
          this.showLevel = true;
        } else {
          this.showLevel = false;
          title = this.CardInfo.phone_number;
        }
      } else if (this.CardInfo.given_type === "net_enquiry") {
        title = `${this.CardInfo.name}`;
        this.showLevel = false;
      } else {
        title = this.CardInfo.person_name;
        this.showLevel = true;
      }
      return title;
    },
    getColor(level) {
      console.log(level)
      return "primary";
      /* switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      } */
    },
    giveShortForm(given_type) {
      switch (given_type) {
        case "delays":
          return "D";
        case "waiting":
          return "W";
        case "today":
          return "T";
        case "net_enquiry":
          return "NE";
        case "missed_calls":
          return "M";
        case "mhps":
          return "MHP";
        case "untracked_calls":
          return "UC";
        case "untapped":
          return "UT";
        default:
          return given_type;
      }
    },
  },
};
</script>
